import React, { useState, useEffect } from "react";
import "./styles.css";
import Button from "@mui/material/Button";
import LabsBackgroundRune1 from "../svg/labsBackgroundRune1";
import LabsBackgroundRune2 from "../svg/labsBackgroundRune2";
import { useTranslation } from "react-i18next";
import "../../../utils/translator";
import { Link as LinkBrowser } from "react-router-dom";
import LabsLitepaper from "./labsLitepaper";
import AgarthaLabsLightLogo1 from "../svg/agarthaLabsLightLogo1";
import AgarthaLabsLightLogo2 from "../svg/agarthaLabsLightLogo2";
// import AgarthaLabsLightLogo from "../svg/agarthaLabsLightLogo";

function LabsHome() {
  const { t } = useTranslation();
  const [largeur, setLargeur] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <>
      <div className="labsHomeArea" id="labsHome">
        <LabsBackgroundRune2 />

        {largeur > 1024 && <LabsBackgroundRune1 />}

        <div className="labsHomeBlock">
          <div className="labsHomeBlock__description">
            <h1>
              {t("labsHomeTitle")}
              &nbsp;
              <AgarthaLabsLightLogo1 />
              &nbsp;
              <AgarthaLabsLightLogo2 />
            </h1>
            {/* <AgarthaLabsLightLogo /> */}
            <p>{t("labsHomeText1")}</p>
          </div>
          <div className="labsHomeBlock__buttons">
            <LinkBrowser to="/labs/projectAgartha">
              <Button variant="outlined" className="labsHomeButton color-11">
                <h4>{t("enter")}</h4>
              </Button>
            </LinkBrowser>
            <LabsLitepaper />
          </div>
        </div>
      </div>

      <div className="labsWhatBlock">
        <div className="labsWhatBlock__blocks">
          <h2>
            {t("labsWhatBlockLeftTitle")}
            <em className="monarchFont">Agartha Labs ?</em>
          </h2>
          <p>
            <em className="monarchFont">Agartha Labs</em>
            {t("labsWhatBlockLeftText1")}
          </p>
        </div>
        <div className="labsWhatBlock__blocks">
          <h2>{t("labsWhatBlockCenterTitle")}</h2>
          <p>{t("labsWhatBlockCenterText1")}</p>
        </div>
        <div className="labsWhatBlock__blocks lastBlock">
          <h2>{t("labsWhatBlockRightTitle")}</h2>
          <p>{t("labsWhatBlockRightText1")}</p>
        </div>
      </div>
    </>
  );
}

export default LabsHome;
