import React from "react";
import "./styles.css";

function RoadmapVerticalSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 1.333 435.861"
      className="svgRoadmapVertical"
    >
      <defs>
        <linearGradient
          id="linearGradient228"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(-.5 1635.82) scale(326.896)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#1c1d58" stopOpacity="1"></stop>
          <stop offset="0.25" stopColor="#ed1c24" stopOpacity="1"></stop>
          <stop offset="0.6" stopColor="#fbc707" stopOpacity="1"></stop>
          <stop offset="0.8" stopColor="#99dde3" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#00a9a0" stopOpacity="1"></stop>
        </linearGradient>
        <clipPath id="clipPath768-0" clipPathUnits="userSpaceOnUse">
          <path d="M0 1635.819h488V0H0z"></path>
        </clipPath>
        <linearGradient
          id="linearGradient8714"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(-.5 1635.82) scale(326.896)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
          xlinkHref="#linearGradient228"
        ></linearGradient>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 -405.816 786.65)">
        <g transform="rotate(-90 12.809 657.73)">
          <g clipPath="url(#clipPath768-0)">
            <g transform="translate(81.052 949.783)">
              <path
                fill="none"
                stroke="url(#linearGradient8714)"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="miter"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="1"
                d="M0 0h325.896"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RoadmapVerticalSvg;
