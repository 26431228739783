import React from "react";
import "./styles.css";

function AgarthaMarketLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1279.579 252.603"
      className="agarthaMarketLogo"
    >
      <defs>
        <clipPath id="clipPath91498" clipPathUnits="userSpaceOnUse">
          <path d="M2721.482 1856.436c-9.486-16.43 2.372-36.97 21.346-36.97h171.197l-106.944 185.231zm26.325-9.276l59.124 102.401c6.307 10.922 22.071 10.922 28.378 0l58.23-100.859c6.7-11.608-1.674-26.117-15.078-26.117h-116.467c-12.609 0-20.494 13.654-14.187 24.575"></path>
        </clipPath>
        <linearGradient
          id="linearGradient91510"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(188.98786 -188.98786) rotate(-50 -2.877 -20.424)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#1c1d58" stopOpacity="1"></stop>
          <stop offset="0.5" stopColor="#ed1c24" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#fbc707" stopOpacity="1"></stop>
        </linearGradient>
        <clipPath id="clipPath91542" clipPathUnits="userSpaceOnUse">
          <path d="M0 5892.481h14253.678V0H0z"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 -3624.191 2677.455)">
        <g>
          <g clipPath="url(#clipPath91498)">
            <g>
              <g>
                <path
                  fill="url(#linearGradient91510)"
                  stroke="none"
                  d="M2721.482 1856.436c-9.486-16.43 2.372-36.97 21.346-36.97h171.197l-106.944 185.231zm26.325-9.276l59.124 102.401c6.307 10.922 22.071 10.922 28.378 0l58.23-100.859c6.7-11.608-1.674-26.117-15.078-26.117h-116.467c-12.609 0-20.494 13.654-14.187 24.575"
                ></path>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g clipPath="url(#clipPath91542)">
            <g transform="translate(2977.784 1998.883)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l-1.947.967-20.68-60.1H20.68zm60.422-98.23l-.645-2.749H4.354l.487 2.749s29.402.158 25.85 10.662L21.482-61.56h-44.911l-9.051-26.172C-35.545-97.263-5.012-98.23-5.012-98.23l-.487-2.749h-41.518l.645 2.749s8.235 1.611 11.313 10.662L-4.197 2.098l-8.09 3.717h24.719l32.151-93.547c3.394-9.531 15.839-10.498 15.839-10.498"
              ></path>
            </g>
            <g transform="translate(3105.102 1899.193)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-14.221-4.197-29.889-3.065-42.333 4.525-41.834 25.528-35.533 100.98 24.889 100.98 8.078 0 15.668-3.23 20.68-7.433 9.367-5.657 11.787 10.498 11.787 10.498h1.776l8.735-40.551h-3.565s-10.011 36.355-39.413 34.901C-76.09 99.848-57.186-4.841-3.71 2.105c19.378 2.42 25.035 26.659 16.313 39.097C7.591 48.793-2.42 49.28-2.42 49.28l.644 1.94h43.623l-.645-1.94s-13.734 1.94-13.734-23.588V-2.421h-4.367s-3.223 5.013-11.143 5.013C8.722 2.592 4.525 1.46 0 0"
              ></path>
            </g>
            <g transform="translate(3296.365 1957.036)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h-21.009v45.234H.158c4.854 0 22.14-1.453 22.14-22.456C22.298 1.776 7.104 0 0 0m-128.013-17.286l20.68 60.1 1.933-.967 20.68-59.133zm155.152 4.197l-1.131 2.427C23.587-5.334 18.089-.974 12.274-.974H7.261v.329S36.506-1.94 36.506 22.778c0 25.528-29.245 24.883-35.861 24.883h-45.241l-.487-2.427h.171c5.644 0 10.985-2.585 10.985-9.853v-84.66c0-6.516-10.264-7.053-11.938-7.026-3.27.054-11.665 2.105-14.938 10.42l-32.152 93.546h-24.718l8.077-3.716-30.862-89.666c-3.065-9.051-11.301-10.662-11.301-10.662l-.658-2.75h41.532l.486 2.75s-30.546.967-27.468 10.498l9.051 26.172h44.912l9.209-26.008c3.551-10.504-25.85-10.662-25.85-10.662l-.487-2.75h91.981l.487 2.75c-5.815 0-12.445 2.907-12.445 9.366V-1.94H.158c4.525 0 8.893-1.132 10.985-6.302l19.548-50.891h26.021l.474 2.75c-13.563 0-26.324 34.73-30.047 43.294"
              ></path>
            </g>
            <g transform="translate(3449.256 2008.091)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l7.104-35.059H4.525S-3.71-5.821-38.769-5.821V-98.23c0-5.821 6.775-9.209 12.274-9.209h.158l-.486-2.749H-64.79l.487 2.749s12.603 0 12.603 9.209v92.409c-35.217 0-43.294-29.238-43.294-29.238h-2.749L-90.469 0h2.263c1.289-2.263 21.969-3.394 42.807-3.394C-2.263-3.394-2.263 0-2.263 0z"
              ></path>
            </g>
            <g transform="translate(3594.779 1939.75)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l20.68 60.1 1.947-.967L43.307 0zm67.21-28.599L35.059 64.947H10.34l8.091-3.716-30.863-89.666c-2.979-8.76-9.941-10.614-13.19-10.643-2.93-.025-12.425 1.379-12.567 6.927v85.14c0 7.111 6.472 9.696 11.958 9.696l.328-.165.645 2.427h-46.201l-.487-2.427s20.68 1.941 20.68-9.531V12.76h-53.805v40.229c0 11.472 20.68 9.531 20.68 9.531l.645 2.427h-46.201l-.487-2.427.158.165c5.657 0 12.116-2.585 12.116-9.696v-85.14c-.158-6.946-12.274-6.946-12.274-6.946l-.645-2.75h45.557l.645 2.75s-20.194-.809-20.194 9.688V10.34h53.805v-39.749c0-10.497-20.193-9.688-20.193-9.688l-.487-2.75h89.074l.487 2.75s-30.533.967-27.468 10.498L-.802-2.427h44.911l9.209-26.008c3.552-10.504-25.85-10.662-25.85-10.662l-.487-2.75h55.423l.645 2.75s-12.445.967-15.839 10.498"
              ></path>
            </g>
            <g transform="translate(3077.225 1849.616)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h-10.709v23.065H.084c2.472 0 11.286-.741 11.286-11.45S3.625 0 0 0m-65.275-8.814l10.545 30.645.989-.493 10.545-30.152zm107.153-15.16v42.099c0 5.188 6.345 4.94 6.345 4.94l.329 1.238H29.273l-.328-1.238c3.87 0 6.262-1.895 6.342-4.94v-42.099c0-4.776-5.063-4.792-6.371-4.769-6.838.124-13.202 17.754-15.074 22.069l-.577 1.237C12.027-2.72 9.227-.496 6.262-.496H3.709v.167s14.909-.66 14.909 11.944c0 13.017-14.909 12.688-18.289 12.688h-23.066l-.248-1.238h.084c2.884 0 5.601-1.318 5.601-5.024v-43.169c0-3.326-3.825-3.62-6.049-3.572-1.715.038-6.194 1.199-7.655 5.303l-16.394 47.7h-12.605l4.122-1.895-15.736-45.721c-1.506-4.437-3.535-5.502-6.098-5.509-3.16-.008-6.236 1.658-6.215 4.767v42.18c0 5.93 6.262 4.94 6.262 4.94l.329 1.238h-11.863l-23.069-47.372-19.359 47.372h-14.251l-.248-1.238c2.884 0 8.569-1.234 8.65-4.94v-42.18c0-4.779-8.731-4.779-8.731-4.779l-.248-1.318h19.114l.329 1.318c-4.119 0-8.734 2.388-8.734 4.779v42.18l19.772-48.854h1.895l23.478 48.358v-41.684c0-4.779-6.259-4.779-6.259-4.779l-.332-1.318H-56.541l.248 1.402s-15.572.493-14.006 5.353l4.615 13.345h22.901l4.695-13.261c1.811-5.357-13.181-5.437-13.181-5.437l-.248-1.402h46.905l.245 1.402c-2.965 0-6.342 1.482-6.342 4.776V-.989H.084c2.304 0 4.531-.577 5.601-3.214l9.968-25.949h32.322l.329 1.402c-3.706 0-6.426 2.059-6.426 4.776"
              ></path>
            </g>
            <g transform="translate(3208.68 1844.424)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0s-5.353-23.558-22.489-23.558h-13.18V1.731h4.199c13.593 0 13.513-11.451 13.513-11.451h1.07v23.891h-1.07s.08-11.287-13.513-11.287h-4.199v25.293h13.509c15.157 0 18.866-14.828 18.866-14.828h1.07l-3.625 17.876h-1.154s-.658-1.73-6.507-1.73h-34.187l-.248-1.318h.084c2.965 0 5.601-1.238 5.601-4.944v-43.085c0-3.286-6.642-3.742-9.138-3.679-4.977.127-12.25 11.981-16.185 20.73-2.552 5.521-8.073 9.063-14.086 9.063h-2.636l22.572 19.691c2.968 2.552 5.933 2.304 5.933 2.304l.329 1.238h-17.957l-.249-1.238c13.346 0 2.308-7.66-15.324-24.548v-.248h1.402c6.342 0 12.027-3.87 14.335-9.803 3.129-7.99 6.835-18.454 6.835-18.454l52.178-.164c8.979 0 9.803-.825 9.803-.825h1.154L1.073 0z"
              ></path>
            </g>
            <g transform="translate(3262.096 1875.65)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l3.622-17.877H2.304S-1.895-2.968-19.772-2.968v-47.12c0-2.969 3.458-4.696 6.259-4.696h.084l-.248-1.402h-19.36l.249 1.402s6.426 0 6.426 4.696v47.12c-17.961 0-22.079-14.909-22.079-14.909h-1.402L-46.134 0h1.154c.657-1.154 11.202-1.731 21.831-1.731C-1.154-1.731-1.154 0-1.154 0z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AgarthaMarketLogo;
