import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./styles.css";
import { useTranslation } from "react-i18next";
import "../../../../utils/translator/index.js";
import CloseIcon from "@mui/icons-material/Close";

export default function LegalMentionsModal({ legal }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    legal === "legal_mentions" && setOpen(true);
  }, [legal]);

  return (
    <div className="legalNoticeBoxContainer">
      <Button
        variant="text"
        onClick={() => setOpen(true)}
        style={{ textTransform: "none" }}
      >
        {t("legalMentionsTitle")}
      </Button>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          legal === "legal_mentions" && navigate("/");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        timeout={5000}
      >
        <Box className="legalNoticeBox sc2">
          <CloseIcon
            onClick={() => {
              setOpen(false);
              legal === "legal_mentions" && navigate("/");
            }}
            className="memberModalCloseIcon"
          />
          <div id="modal-modal-title">
            <h2>{t("legalMentionsTitle")}</h2>
          </div>
          <div id="modal-modal-description">
            <h3>{t("legalMentionDetailsTitle")}</h3>
            <p>{t("legalMentionDetailsText")}</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
