import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./styles.css";
import { useTranslation } from "react-i18next";
import "../../../../utils/translator/index.js";
import CloseIcon from "@mui/icons-material/Close";

export default function TermOfUseModal({ legal }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  console.log("legal", legal);

  useEffect(() => {
    legal === "term_of_use" && setOpen(true);
  }, [legal]);

  return (
    <div className="privacyBoxContainer">
      <Button
        variant="text"
        onClick={() => setOpen(true)}
        style={{ textTransform: "none" }}
      >
        {t("TERM_OF_USE_TITLE_1")}
      </Button>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          legal === "term_of_use" && navigate("/");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="privacyBox sc2">
          <CloseIcon
            onClick={() => {
              setOpen(false);
              legal === "term_of_use" && navigate("/");
            }}
            className="memberModalCloseIcon"
          />
          <div id="modal-modal-title">
            <h2>{t("TERM_OF_USE_TITLE_2")}</h2>
          </div>
          <div id="modal-modal-description">
            <h3>{t("TERM_OF_USE_TITLE_3")}</h3>
            <p>{t("TERM_OF_USE_TEXT_1")}</p>

            <h3>{t("TERM_OF_USE_TITLE_4")}</h3>
            <p>{t("TERM_OF_USE_TEXT_2")}</p>

            <h3>{t("TERM_OF_USE_TITLE_5")}</h3>
            <p>{t("TERM_OF_USE_TEXT_3")}</p>

            <h3>{t("TERM_OF_USE_TITLE_6")}</h3>
            <p>{t("TERM_OF_USE_TEXT_4")}</p>

            <h3>{t("TERM_OF_USE_TITLE_7")}</h3>
            <p>{t("TERM_OF_USE_TEXT_5")}</p>

            <h3>{t("TERM_OF_USE_TITLE_8")}</h3>
            <p>{t("TERM_OF_USE_TEXT_6")}</p>

            <h3>{t("TERM_OF_USE_TITLE_9")}</h3>
            <p>{t("TERM_OF_USE_TEXT_7")}</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
