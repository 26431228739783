import React from "react";
import "./styles.css";

function AgarthaLabsLightLogo1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 996 148.427"
      className="agarthaLabsLightLogo"
    >
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path d="M0 5892.481h14253.678V0H0z"></path>
        </clipPath>
      </defs>
      <path
        fill="#fff"
        d="M0 0l-1.947.967-20.68-60.1H20.68zm60.422-98.23l-.645-2.749H4.354l.487 2.749s29.402.158 25.85 10.662L21.482-61.56h-44.911l-9.051-26.172C-35.545-97.263-5.012-98.23-5.012-98.23l-.487-2.749h-41.518l.645 2.749s8.235 1.612 11.313 10.662L-4.197 2.098l-8.09 3.717h24.719l32.151-93.547c3.394-9.531 15.839-10.498 15.839-10.498"
        transform="matrix(1.33333 0 0 -1.33333 -3907.689 3795.228) translate(2977.784 2837.212)"
      ></path>
      <path
        fill="#fff"
        d="M0 0c-14.221-4.197-29.889-3.065-42.333 4.525-41.834 25.528-35.533 100.98 24.889 100.98 8.078 0 15.668-3.23 20.68-7.433 9.367-5.656 11.787 10.498 11.787 10.498h1.776l8.735-40.551h-3.565s-10.011 36.355-39.413 34.901C-76.09 99.848-57.186-4.841-3.71 2.105c19.378 2.42 25.035 26.659 16.313 39.097C7.591 48.793-2.42 49.28-2.42 49.28l.644 1.94h43.623l-.645-1.94s-13.734 1.94-13.734-23.588V-2.421h-4.367s-3.223 5.013-11.143 5.013C8.722 2.592 4.525 1.46 0 0"
        transform="matrix(1.33333 0 0 -1.33333 -3907.689 3795.228) translate(3105.102 2737.522)"
      ></path>
      <path
        fill="#fff"
        d="M0 0h-21.009v45.234H.158c4.854 0 22.14-1.453 22.14-22.456C22.298 1.776 7.104 0 0 0m-128.013-17.286l20.68 60.1 1.933-.967 20.68-59.133zm155.152 4.197l-1.131 2.427C23.587-5.334 18.089-.973 12.274-.973H7.261v.328S36.506-1.94 36.506 22.778c0 25.528-29.245 24.883-35.861 24.883h-45.241l-.487-2.427h.171c5.644 0 10.985-2.585 10.985-9.853V-49.28c0-6.514-10.264-7.052-11.938-7.025-3.27.054-11.665 2.105-14.938 10.42l-32.152 93.546h-24.718l8.077-3.716-30.862-89.666c-3.065-9.051-11.301-10.662-11.301-10.662l-.658-2.75h41.532l.486 2.75s-30.546.966-27.468 10.498l9.051 26.172h44.912l9.209-26.008c3.551-10.504-25.85-10.662-25.85-10.662l-.487-2.75h91.981l.487 2.75c-5.815 0-12.445 2.907-12.445 9.366V-1.94H.158c4.525 0 8.893-1.132 10.985-6.302l19.548-50.891h26.021l.474 2.75c-13.563 0-26.324 34.729-30.047 43.294"
        transform="matrix(1.33333 0 0 -1.33333 -3907.689 3795.228) translate(3296.365 2795.365)"
      ></path>
      <path
        fill="#fff"
        d="M0 0l7.104-35.059H4.525S-3.71-5.821-38.769-5.821V-98.23c0-5.821 6.775-9.209 12.274-9.209h.158l-.486-2.749H-64.79l.487 2.749s12.603 0 12.603 9.209v92.409c-35.217 0-43.294-29.238-43.294-29.238h-2.749L-90.469 0h2.263c1.289-2.263 21.969-3.394 42.807-3.394C-2.263-3.394-2.263 0-2.263 0z"
        transform="matrix(1.33333 0 0 -1.33333 -3907.689 3795.228) translate(3449.256 2846.42)"
      ></path>
      <path
        fill="#fff"
        d="M0 0l20.68 60.1 1.947-.967L43.307 0zm67.21-28.599L35.059 64.947H10.34l8.091-3.716-30.863-89.666c-2.979-8.76-9.941-10.614-13.19-10.642-2.93-.026-12.425 1.378-12.567 6.926v85.14c0 7.111 6.472 9.696 11.958 9.696l.328-.165.645 2.427h-46.201l-.487-2.427s20.68 1.94 20.68-9.531V12.76h-53.805v40.229c0 11.471 20.68 9.531 20.68 9.531l.645 2.427h-46.201l-.487-2.427.158.165c5.657 0 12.116-2.585 12.116-9.696v-85.14c-.158-6.946-12.274-6.946-12.274-6.946l-.645-2.75h45.557l.645 2.75s-20.194-.809-20.194 9.689V10.34h53.805v-39.748c0-10.498-20.193-9.689-20.193-9.689l-.487-2.75h89.074l.487 2.75s-30.533.966-27.468 10.498L-.802-2.427h44.911l9.209-26.008c3.552-10.504-25.85-10.662-25.85-10.662l-.487-2.75h55.423l.645 2.75s-12.445.966-15.839 10.498"
        transform="matrix(1.33333 0 0 -1.33333 -3907.689 3795.228) translate(3594.779 2778.08)"
      ></path>
    </svg>
  );
}

export default AgarthaLabsLightLogo1;
