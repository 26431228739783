import React from "react";

function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 35 35"
      cursor="pointer"
    >
      <path
        fill="#fff"
        d="M17.276 31.097a13.82 13.82 0 100-27.64 13.82 13.82 0 000 27.64zm0 3.455C7.735 34.552 0 26.818 0 17.276 0 7.735 7.735 0 17.276 0c9.542 0 17.276 7.735 17.276 17.276 0 9.542-7.734 17.276-17.276 17.276z"
      ></path>
      <path
        fill="#fff"
        d="M25.914 12.002a7.162 7.162 0 01-2.035.55 3.516 3.516 0 001.558-1.93c-.687.399-1.444.69-2.252.846a3.558 3.558 0 00-2.587-1.102c-1.957 0-3.543 1.563-3.543 3.49 0 .273.031.539.092.794a10.11 10.11 0 01-7.305-3.645 3.424 3.424 0 00-.48 1.753c0 1.21.627 2.28 1.577 2.905a3.586 3.586 0 01-1.606-.438v.044a3.507 3.507 0 002.843 3.42 3.642 3.642 0 01-1.601.06 3.54 3.54 0 003.31 2.423 7.18 7.18 0 01-4.402 1.493c-.285 0-.567-.018-.845-.049a10.13 10.13 0 005.432 1.57c6.52 0 10.084-5.317 10.084-9.93l-.012-.452a7.019 7.019 0 001.772-1.802z"
      ></path>
    </svg>
  );
}

export default TwitterIcon;
