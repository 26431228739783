import React from "react";
import "./styles.css";

function AgarthaAcademyLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1263.341 252.603"
      className="agarthaAcademyLogo"
    >
      <defs>
        <clipPath id="clipPath91476" clipPathUnits="userSpaceOnUse">
          <path d="M2730.322 2317.584c0-51.068 41.545-92.617 92.617-92.617 51.068 0 92.617 41.549 92.617 92.617 0 51.068-41.549 92.617-92.617 92.617-51.072 0-92.617-41.549-92.617-92.617m3.094 0c0 49.365 40.161 89.523 89.523 89.523 45.506 0 45.706-40.158 45.706-89.523 0-49.365-.2-89.523-45.706-89.523-49.362 0-89.523 40.158-89.523 89.523"></path>
        </clipPath>
        <linearGradient
          id="linearGradient91488"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(185.24574 -185.24574) rotate(-70 -1.064 -16.78)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#1c1d58" stopOpacity="1"></stop>
          <stop offset="0.5" stopColor="#5495d6" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#e6f7fd" stopOpacity="1"></stop>
        </linearGradient>
        <clipPath id="clipPath91542" clipPathUnits="userSpaceOnUse">
          <path d="M0 5892.481h14253.678V0H0z"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 -3640.43 3218.125)">
        <g>
          <g clipPath="url(#clipPath91476)">
            <g>
              <g>
                <path
                  fill="url(#linearGradient91488)"
                  stroke="none"
                  d="M2730.322 2317.584c0-51.068 41.545-92.617 92.617-92.617 51.068 0 92.617 41.549 92.617 92.617 0 51.068-41.549 92.617-92.617 92.617-51.072 0-92.617-41.549-92.617-92.617m3.094 0c0 49.365 40.161 89.523 89.523 89.523 45.506 0 45.706-40.158 45.706-89.523 0-49.365-.2-89.523-45.706-89.523-49.362 0-89.523 40.158-89.523 89.523"
                ></path>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g clipPath="url(#clipPath91542)">
            <g transform="translate(2977.784 2404.385)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l-1.947.967-20.68-60.1H20.68zm60.422-98.23l-.645-2.749H4.354l.487 2.749s29.402.158 25.85 10.662L21.482-61.56h-44.911l-9.051-26.172C-35.545-97.263-5.012-98.23-5.012-98.23l-.487-2.749h-41.518l.645 2.749s8.235 1.611 11.313 10.662L-4.197 2.098l-8.09 3.716h24.719l32.151-93.546c3.394-9.531 15.839-10.498 15.839-10.498"
              ></path>
            </g>
            <g transform="translate(3105.102 2304.695)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-14.221-4.197-29.889-3.065-42.333 4.525-41.834 25.528-35.533 100.98 24.889 100.98 8.078 0 15.668-3.23 20.68-7.433 9.367-5.656 11.787 10.498 11.787 10.498h1.776l8.735-40.551h-3.565s-10.011 36.355-39.413 34.901C-76.09 99.848-57.186-4.841-3.71 2.105c19.378 2.42 25.035 26.659 16.313 39.097C7.591 48.793-2.42 49.28-2.42 49.28l.644 1.94h43.623l-.645-1.94s-13.734 1.94-13.734-23.588V-2.42h-4.367s-3.223 5.012-11.143 5.012C8.722 2.592 4.525 1.46 0 0"
              ></path>
            </g>
            <g transform="translate(3296.365 2362.539)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h-21.009v45.234H.158c4.854 0 22.14-1.453 22.14-22.456C22.298 1.776 7.104 0 0 0m-128.013-17.286l20.68 60.1 1.933-.967 20.68-59.133zm155.152 4.197l-1.131 2.427C23.587-5.334 18.089-.973 12.274-.973H7.261v.328S36.506-1.94 36.506 22.778c0 25.528-29.245 24.883-35.861 24.883h-45.241l-.487-2.427h.171c5.644 0 10.985-2.585 10.985-9.853v-84.66c0-6.515-10.264-7.053-11.938-7.025-3.27.054-11.665 2.104-14.938 10.419l-32.152 93.546h-24.718l8.077-3.716-30.862-89.666c-3.065-9.051-11.301-10.662-11.301-10.662l-.658-2.75h41.532l.486 2.75s-30.546.967-27.468 10.498l9.051 26.172h44.912l9.209-26.008c3.551-10.504-25.85-10.662-25.85-10.662l-.487-2.75h91.981l.487 2.75c-5.815 0-12.445 2.907-12.445 9.366V-1.94H.158c4.525 0 8.893-1.132 10.985-6.302l19.548-50.891h26.021l.474 2.75c-13.563 0-26.324 34.73-30.047 43.294"
              ></path>
            </g>
            <g transform="translate(3449.256 2413.594)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l7.104-35.059H4.525S-3.71-5.821-38.769-5.821V-98.23c0-5.821 6.775-9.209 12.274-9.209h.158l-.486-2.749H-64.79l.487 2.749s12.603 0 12.603 9.209v92.409c-35.217 0-43.294-29.238-43.294-29.238h-2.749L-90.469 0h2.263c1.289-2.263 21.969-3.394 42.807-3.394C-2.263-3.394-2.263 0-2.263 0z"
              ></path>
            </g>
            <g transform="translate(3594.779 2345.253)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l20.68 60.1 1.947-.967L43.307 0zm67.21-28.599L35.059 64.947H10.34l8.091-3.716-30.863-89.666c-2.979-8.76-9.941-10.614-13.19-10.642-2.93-.026-12.425 1.378-12.567 6.926v85.14c0 7.111 6.472 9.696 11.958 9.696l.328-.165.645 2.427h-46.201l-.487-2.427s20.68 1.941 20.68-9.531V12.76h-53.805v40.229c0 11.472 20.68 9.531 20.68 9.531l.645 2.427h-46.201l-.487-2.427.158.165c5.657 0 12.116-2.585 12.116-9.696v-85.14c-.158-6.946-12.274-6.946-12.274-6.946l-.645-2.75h45.557l.645 2.75s-20.194-.809-20.194 9.689V10.34h53.805v-39.748c0-10.498-20.193-9.689-20.193-9.689l-.487-2.75h89.074l.487 2.75s-30.533.967-27.468 10.498L-.802-2.427h44.911l9.209-26.008c3.552-10.504-25.85-10.662-25.85-10.662l-.487-2.75h55.423l.645 2.75s-12.445.967-15.839 10.498"
              ></path>
            </g>
            <g transform="translate(2954.741 2276.457)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l-.99.493-10.544-30.645h22.079zm30.813-50.088l-.332-1.402H2.224l.248 1.402s14.992.08 13.181 5.437L10.957-31.39h-22.901l-4.615-13.345c-1.566-4.86 14.007-5.353 14.007-5.353l-.249-1.402h-21.173l.332 1.402s4.199.822 5.765 5.437L-2.14 1.07l-4.122 1.895H6.346l16.391-47.7c1.73-4.86 8.076-5.353 8.076-5.353"
              ></path>
            </g>
            <g transform="translate(3030.146 2249.105)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h.577C0-4.531-2.884-24.716-23.81-24.716c-30.149 0-34.928 55.197 1.65 55.197 4.448 0 7.661-1.318 10.545-3.542 4.86-2.884 5.85 5.108 5.85 5.108h1.234L.577 7.332H-.661S-7.083 29.163-22.16 29.163c-22.82 0-22.82-44.9.658-48.77 0 0 17.632-4.119 21.502 19.607"
              ></path>
            </g>
            <g transform="translate(3105.16 2226.285)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h-7.496v51.899H0s20.104.168 20.104-26.03S0 0 0 0m-62.062 20.02l10.545 30.645.99-.493 10.544-30.152zM0 53.137h-19.523l-.329-1.238h.164c2.885 0 5.521-1.318 5.521-5.024V3.706C-14.225.175-18.526.088-19.758.101c-1.057.012-6.468.941-8.033 5.336l-16.394 47.7h-12.604l4.122-1.895-15.737-45.721C-69.97.906-74.17.084-74.17.084l-.328-1.402h21.17l.248 1.402s-15.572.493-14.006 5.353l4.615 13.345h22.901l4.695-13.261C-33.063.164-48.056.084-48.056.084l-.248-1.402H0s27.764-.493 27.764 27.187C27.764 53.55 0 53.137 0 53.137"
              ></path>
            </g>
            <g transform="translate(3184.745 2249.927)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h1.073l-4.366-25.785h-1.154s-.825.825-9.804.825h-34.023l.329 1.402s5.685 0 5.685 3.706v43.085c0 3.706-2.636 4.944-5.601 4.944h-.084l.248 1.318h34.187c5.85 0 6.507 1.73 6.507 1.73h1.154l3.625-17.876h-1.069s-3.71 14.828-18.867 14.828h-13.509V2.884h4.199c13.593 0 13.513 11.286 13.513 11.286h1.07V-9.72h-1.07s.08 11.45-13.513 11.45h-4.199v-25.288h13.181C-5.353-23.558 0 0 0 0"
              ></path>
            </g>
            <g transform="translate(3292.585 2279.422)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l-.248-1.238s12.027 0 8.65-4.94L-5.521-27.432-18.044-6.178c-3.294 4.94 8.814 4.94 8.814 4.94L-8.982 0h-36.779L-68.83-47.371-88.189 0h-14.251l-.248-1.238c2.884 0 8.569-1.234 8.649-4.94v-42.179c0-4.78-8.73-4.78-8.73-4.78l-.248-1.318h19.114l.329 1.318c-4.119 0-8.734 2.388-8.734 4.78v42.179l19.772-48.854h1.895l23.477 48.358v-41.683c0-4.78-6.258-4.78-6.258-4.78l-.332-1.318h19.278l.329 1.318c-3.458 0-6.342 2.143-6.342 4.78v42.179c0 4.855 5.142 4.994 6.688 4.935 1.329-.052 4.843-.642 7.848-4.935l14.335-22.985v-19.114c0-4.776-6.342-4.776-6.342-4.776l-.249-1.402H1.07l.329 1.402h-.165c-2.8 0-6.178 1.727-6.178 4.776v19.114L9.8-6.178c3.381 4.94 5.769 4.94 5.85 4.94L15.898 0z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AgarthaAcademyLogo;
