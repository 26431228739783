import React from "react";
import "./styles.css";

function LabsBackgroundRune1() {
  return (
    <div className="labsSvgContainer1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 854 1549"
        className="labsSvgRune1"
      >
        <g clipPath="url(#clip0_251_41)">
          <path
            fill="url(#paint0_linear_251_41)"
            d="M678.642-29.024H-67.902v6.613h5.8c12.18-1.124 24.292-2.927 36.537-3.092a7.558 7.558 0 012.95.546 62.433 62.433 0 0116.15-2.117h685.107c28.098 0 50.906 21.874 50.379 48.542-.527 26.073-23.632 46.707-51.236 46.707H81.489c-27.884 0-52.604 21.361-52.604 47.715v422.328c.765.167 1.463.56 2.003 1.129s.899 1.287 1.03 2.062a4.118 4.118 0 011.186-.364V115.94c0-25.214 21.687-45.715 48.352-45.715h597.186c28.955 0 52.505-22.254 52.505-49.6s-23.55-49.65-52.505-49.65z M730.191 1306.67H100.754c-37.41 0-67.832-25.66-67.832-57.2V543.111v-2.628c-.84.33-2.406-13.343-3.46-13.954a.245.245 0 00-.35.126.249.249 0 00-.013.138c.214 1.422 0 16.153 0 17.162l.89 705.515c0 33.06 31.707 62.23 70.864 62.23h629.338c37.014 0 65.706 22.61 66.332 53.7.643 31.99-29.581 58.19-67.568 58.19h-757.19c-45.946 0-83.207-31.41-83.207-70.16V565.481a21.228 21.228 0 01-2.983-2.067v862.756h844.616c39.041 0 70.864-26.78 70.864-59.72 0-32.93-31.823-59.78-70.864-59.78z"
          ></path>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_251_41"
            x1="138.105"
            x2="421.515"
            y1="-148.07"
            y2="342.194"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1B1464"></stop>
            <stop offset="0.5" stopColor="#009974"></stop>
            <stop offset="1" stopColor="#CFE5AE"></stop>
          </linearGradient>
          <linearGradient
            id="paint1_linear_251_41"
            x1="111936"
            x2="459716"
            y1="1365460"
            y2="1108020"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1B1464"></stop>
            <stop offset="0.5" stopColor="#009974"></stop>
            <stop offset="1" stopColor="#CFE5AE"></stop>
          </linearGradient>
          <clipPath id="clip0_251_41">
            <path fill="#fff" d="M0 0H854V1549H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default LabsBackgroundRune1;
