import React, { Fragment, useState, useEffect } from "react";
import "./styles.css";
import { TeamLineMembers } from "./teamMembers";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import MemberModalDetails from "./memberModalDetails";

const Team = () => {
  const { t } = useTranslation();
  const [largeur, setLargeur] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  // ----------------------------------

  return (
    <div className="teamArea" id="team">
      <div className="teamGlobalContainer">
        <h2 className="teamTitle">{t("TEAM")}</h2>
        <div className="multipleTeamPhotos" id="teamPhotos">
          <div className="teamContainer" id="teamContainer">
            {largeur >= 550 && (
              <Swiper
                slidesPerView={1}
                modules={[Grid, Navigation, Pagination, Mousewheel, Keyboard]}
                navigation={true}
                grid={{
                  rows: 1,
                }}
                pagination={false}
                mousewheel={false}
                keyboard={true}
                spaceBetween={30}
                className="swiper"
                breakpoints={{
                  550: {
                    // width: 550,
                    slidesPerView: 2,
                    grid: {
                      rows: 2,
                    },
                  },
                  767: {
                    // width: 767,
                    slidesPerView: 3,
                    grid: {
                      rows: 2,
                    },
                  },
                  1000: {
                    // width: 1000,
                    slidesPerView: 4,
                    grid: {
                      rows: 2,
                    },
                  },
                }}
              >
                {TeamLineMembers.map((item) => (
                  <Fragment key={item.id}>
                    <SwiperSlide
                      className="profilOverview disable-select"
                      key={item.id}
                    >
                      <img
                        src={item.img}
                        alt="teamMembersPicture"
                        className="teamAvatar disable-select"
                        id={item.id}
                      />
                      <div className="labsProjectTeamDescriptionBlock">
                        <h3 className="profilName">{item.name}</h3>
                        <h4 className="profilName">{item.job}</h4>
                        {/* <p className="descriptionProfil">{item.skills}</p> */}
                      </div>
                      <MemberModalDetails item={item} />
                    </SwiperSlide>
                  </Fragment>
                ))}
              </Swiper>
            )}

            {largeur < 550 && (
              <div className="swiper">
                {TeamLineMembers.map((item) => (
                  <Fragment key={item.id}>
                    <div
                      className="profilOverview disable-select"
                      key={item.id}
                    >
                      <img
                        src={item.img}
                        alt="teamMembersPicture"
                        className="teamAvatar disable-select"
                        id={item.id}
                      />
                      <div className="labsProjectTeamDescriptionBlock">
                        <h3 className="profilName">{item.name}</h3>
                        <h4 className="profilName">{item.job}</h4>
                        {/* <p className="descriptionProfil">{item.skills}</p> */}
                      </div>
                      <MemberModalDetails item={item} />
                    </div>
                  </Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
