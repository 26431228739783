import React from "react";
import "./styles.css";
import AgarthaAcademyLogo from "../../svg/agarthaAcademyLogo";
import AgarthaMarketLogo from "../../svg/agarthaMarketLogo";
import AgarthaLabsLogo from "../../svg/agarthaLabsLogo";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { Link as LinkBrowser } from "react-router-dom";

function Services() {
  const { t } = useTranslation();

  return (
    <div className="servicesArea">
      <h2 className="mainPageServicesTitle">Services</h2>

      <div className="servicesAllAgarthaBlocks">
        <div>
          <AgarthaLabsLogo />
          <p className="servicesAgarthaDescription">
            <span className="monarchFont">Agartha Labs</span>
            {t("servicesLabsDescription")}
          </p>
          {/*<LinkBrowser to="/labs">*/}
            <Button
              disabled={true}
              to="/labs"
              variant="text"
              className="homeButton color-11 rdvButton servicesButton"
            >
              <h4>{t("labsEnter")}</h4>
            </Button>
          {/*</LinkBrowser>*/}
        </div>
        <div>
          <AgarthaAcademyLogo />
          <p className="servicesAgarthaDescription">
            <span className="monarchFont">Agartha Academy</span>
            {t("servicesAcademyDescription")}
          </p>
          <Button
            variant="text"
            className="homeButton color-11 rdvButton servicesButton"
            href=""
            disabled
          >
            <h4>Coming soon</h4>
          </Button>
        </div>
        <div>
          <AgarthaMarketLogo />
          <p className="servicesAgarthaDescription">
            <span className="monarchFont">Agartha Market</span>
            {t("servicesMarketDescription")}
          </p>
          <Button
            variant="text"
            className="homeButton color-11 rdvButton servicesButton"
            disabled
          >
            <h4>Coming soon</h4>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Services;
