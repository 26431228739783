import React from "react";
import LabsBackgroundRune4 from "../svg/labsBackgroundRune4";
import "./styles.css";
import { useTranslation } from "react-i18next";
import "../../../utils/translator";

function LabsAbout() {
  const { t } = useTranslation();

  return (
    <div className="labsAboutArea" id="labsAbout">
      <LabsBackgroundRune4 />

      <div className="labsAboutBlock">
        <h2>{t("labsAboutTitle")}</h2>
        <p>
          {t("labsAboutText1")}
          <em className="monarchFont"> Agartha Labs</em>
          {t("labsAboutText2")}
        </p>
        <p>
          {t("labsAboutText3")}
          <em className="monarchFont"> Agartha Labs.</em>
        </p>
      </div>
    </div>
  );
}

export default LabsAbout;
