import React from "react";
import "./styles.css";

function LabsBackgroundRune10() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 809 633"
      className="labsSvgRune10"
    >
      <g clipPath="url(#clip0_707_27)">
        <path
          fill="url(#paint0_linear_707_27)"
          d="M-93 47.253V741.09h863.853a60.902 60.902 0 0042.973-17.727 60.4 60.4 0 0017.8-42.797 60.4 60.4 0 00-17.8-42.797 60.9 60.9 0 00-42.973-17.726l-691.057-.001a56.147 56.147 0 01-39.549-16.345 55.69 55.69 0 01-16.413-39.386V47.178A58.057 58.057 0 006.725 6.04 58.54 58.54 0 00-34.583-11 58.54 58.54 0 00-75.89 6.04 58.056 58.056 0 00-93 47.178v.075zm2.506 623.106V48.151a55.285 55.285 0 014.355-21.518A55.473 55.473 0 01-73.745 8.48 55.736 55.736 0 01-55.252-3.464 55.915 55.915 0 01-33.555-7.33c30.399.524 52.628 24.085 52.628 54.484v517.234c0 32.121 28.62 58.177 60.873 58.177h689.93a58.522 58.522 0 0122.354 4.235 58.336 58.336 0 0119.025 12.432 58.063 58.063 0 0112.8 18.736 57.894 57.894 0 014.627 22.188 57.867 57.867 0 01-4.253 22.263 58.044 58.044 0 01-12.483 18.947 58.326 58.326 0 01-18.813 12.748 58.54 58.54 0 01-22.28 4.607H-21.902c-18.205 0-35.664-7.203-48.537-20.023a68.217 68.217 0 01-20.105-48.338h.05z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_707_27"
          x1="89.845"
          x2="433.235"
          y1="846.315"
          y2="249.09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B1464"></stop>
          <stop offset="0.5" stopColor="#009974"></stop>
          <stop offset="1" stopColor="#CFE5AE"></stop>
        </linearGradient>
        <clipPath id="clip0_707_27">
          <path
            fill="#fff"
            d="M0 0H633V809H0z"
            transform="matrix(0 -1 -1 0 809 633)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LabsBackgroundRune10;
