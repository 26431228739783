import React from "react";
import BackgroundRune4 from "../../svg/backgroundRune4";
import "./styles.css";
import GenericFooter from "../footer/genericFooter";

function Footer({legal}) {
  return (
    <div className="footerArea" id="contact">
      <div className="svgContainer8">
        <BackgroundRune4 />
      </div>

      <GenericFooter legal={legal} />
    </div>
  );
}

export default Footer;
