import React from "react";
import "./styles.css";

function LabsBackgroundRune8() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 620 131"
      className="labsBackgroundRune8"
    >
      <g clipPath="url(#clip0_248_18)">
        <path
          fill="url(#paint0_linear_248_18)"
          d="M65.493 130.869h780.758v-959.066c0-17.894-7.175-35.056-19.948-47.709-12.772-12.653-30.095-19.762-48.158-19.762-18.062 0-35.385 7.109-48.158 19.762-12.772 12.653-19.947 29.815-19.947 47.709v767.225c-.023 16.47-6.637 32.26-18.393 43.908-11.757 11.646-27.695 18.2-44.321 18.221H65.409c-17.363 0-34.014 6.833-46.291 18.996C6.84 32.316-.057 48.813-.057 66.013c0 17.201 6.897 33.697 19.175 45.86 12.277 12.163 28.928 18.996 46.29 18.996h.085zm701.166-2.782H66.504a62.944 62.944 0 01-24.214-4.835 62.482 62.482 0 01-20.426-13.76 61.799 61.799 0 01-13.44-20.531 61.34 61.34 0 01-4.352-24.089c.59-33.75 27.102-58.428 61.309-58.428H647.41c36.146 0 65.466-31.774 65.466-67.583v-765.973a64.217 64.217 0 014.765-24.818 64.706 64.706 0 0113.99-21.122 65.386 65.386 0 0121.083-14.211 65.908 65.908 0 0150.019-.415 65.402 65.402 0 0121.321 13.859 64.723 64.723 0 0114.345 20.887 64.245 64.245 0 015.184 24.735V51.934c0 20.212-8.105 39.596-22.531 53.888-14.426 14.291-33.992 22.32-54.393 22.32v-.055z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_248_18"
          x1="964.657"
          x2="297.144"
          y1="-72.129"
          y2="-461.138"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B1464"></stop>
          <stop offset="0.5" stopColor="#009974"></stop>
          <stop offset="1" stopColor="#CFE5AE"></stop>
        </linearGradient>
        <clipPath id="clip0_248_18">
          <path
            fill="#fff"
            d="M0 0H623V131H0z"
            transform="matrix(-1 0 0 1 623 0)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LabsBackgroundRune8;
