import React, { useEffect, useState } from "react";
import "./styles.css";
import BackgroundRune3 from "../../svg/backgroundRune3";
import { useTranslation } from "react-i18next";
import RoadmapSvg from "../../svg/roadmapDesign/roadmapSvg";
import RoadmapVerticalSvg from "../../svg/roadmapDesign/roadmapVerticalSvg";
import RoadmapDetailAgartha from "./roadmapDetailAgartha";
import RoadmapDetailsVertical from "./roadmapDetailsVertical";
import RoadmapDetailAgarthaLabs from "./roadmapDetailAgarthaLabs";
import RoadmapDetailAgarthaMarket from "./roadmapDetailAgarthaMarket";
import RoadmapDetailAgarthaAcademy from "./roadmapDetailAgarthaAcademy";
import RoadmapDetailAgarthaLabsVertical from "./roadmapDetailAgarthaLabsVertical";

function RoadMap() {
  const { t } = useTranslation();
  const [largeur, setLargeur] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
      console.log(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div className="roadmapArea" id="roadmap">
      <h2 className="roadmapTitle">{t("ROADMAP_TITLE")}</h2>

      {largeur >= 1050 && (
        <div className="roadMapContainer">
          <div className="topContainer">
            <div className="whiteVerticalSeparationBar"></div>
            <div className="agarthaRoadmapDetails">
              <RoadmapDetailAgartha />
            </div>
            <div className="whiteVerticalSeparationBar"></div>
            <h2 className="agarthaRoadmapDetails monarchFont">
              <RoadmapDetailAgarthaAcademy />
            </h2>
            <div className="whiteVerticalSeparationBar"></div>
          </div>

          <RoadmapSvg />

          <div className="botContainer">
            <div className="whiteVerticalSeparationBar"></div>
            <h2 className="agarthaRoadmapDetails monarchFont">
              <RoadmapDetailAgarthaLabs />
            </h2>
            <div className="whiteVerticalSeparationBar"></div>
            <h2 className="agarthaRoadmapDetails monarchFont">
              <RoadmapDetailAgarthaMarket />
            </h2>
            <div className="whiteVerticalSeparationBar"></div>
          </div>
        </div>
      )}

      {largeur < 1050 && (
        <div className="roadMapContainer">
          <div className="leftContainer">
            <div className="whiteHorizontalSeparationBar"></div>
            <div className="agarthaRoadmapDetails">
              <RoadmapDetailsVertical />
            </div>
            <div className="whiteHorizontalSeparationBar"></div>
            <h2 className="agarthaRoadmapDetails monarchFont">
              <RoadmapDetailAgarthaAcademy />
            </h2>
            <div className="whiteHorizontalSeparationBar"></div>
          </div>

          <RoadmapVerticalSvg />

          <div className="rightContainer">
            <div className="whiteHorizontalSeparationBar"></div>
            <h2 className="agarthaRoadmapDetails monarchFont">
              <RoadmapDetailAgarthaLabsVertical />
            </h2>
            <div className="whiteHorizontalSeparationBar"></div>
            <div className="agarthaRoadmapDetails">
              <h2 className="monarchFont">
                <RoadmapDetailAgarthaMarket />
              </h2>
            </div>
            <div className="whiteHorizontalSeparationBar"></div>
          </div>
        </div>
      )}

      <div className="svgContainer7">
        <BackgroundRune3 />
      </div>
    </div>
  );
}

export default RoadMap;
