import React, { useState, useEffect } from "react";
import "./styles.css";
import Button from "@mui/material/Button";
import { Link } from "react-scroll";
import { Link as LinkBrowser } from "react-router-dom";
import AgarthaAcademyLogo from "../../svg/agarthaAcademyLogo";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import "../../../utils/translator";
import WorldIcon from "../../labs/svg/worldIcon";
import SelectAutoWidth from "../../labs/labsHeader/languageSelector";
import LabsHeaderLaunchpadButton from "../../labs/labsHeader/labsHeaderLaunchpadButton";
import AcademyHeaderBarRessources from "./academyHeaderBarRessources";

function AcademyHeaderBar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [largeur, setLargeur] = useState(window.innerWidth);
  const { t } = useTranslation();

  toggleMenu
    ? largeur <= 1024
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset")
    : (document.body.style.overflow = "unset");

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);

      if (window.innerWidth > 1024) {
        setToggleMenu(false);
      }
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div className="labsHeader" id="/">
      {largeur <= 1024 && (
        <div className="labsSmallScreenTopHeader">
          <Link
            to="academyHome"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <AgarthaAcademyLogo />
            {/* <img
              src={AgarthaAcademyLogo}
              alt="logo agartha academy"
              className="academyLogo noselect"
            /> */}
          </Link>
          {!toggleMenu && (
            <DensityMediumIcon
              onClick={() => setToggleMenu(!toggleMenu)}
              className="burgerMenuIcon"
              sx={{ fontSize: 36.5 }}
            />
          )}
          {toggleMenu && (
            <CloseIcon
              onClick={() => setToggleMenu(!toggleMenu)}
              className="burgerMenuIcon cross"
              sx={{ fontSize: 36.5 }}
            />
          )}
        </div>
      )}

      {(toggleMenu || largeur > 1024) && (
        <div className="labsHeaderSection">
          {largeur > 1024 && (
            <div className="logoHeaderBar noselect">
              <Link
                to="academyHome"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                className="agarthaAcademyLogoContainer"
              >
                <AgarthaAcademyLogo />
                {/* <img
                  src={AgarthaAcademyLogo}
                  alt="logo agartha academy"
                  className="academyLogo noselect "
                /> */}
              </Link>
            </div>
          )}

          <div className="academyHeaderLinks">
            <Link
              to="academyHome"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={() => setToggleMenu(!toggleMenu)}
              className="labsHeaderBar__link"
            >
              <Button className="headerBar__button">
                <h4>{t("HOME")}</h4>
              </Button>
            </Link>
            <Link
              to="academyAbout"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => setToggleMenu(!toggleMenu)}
              className="labsHeaderBar__link"
            >
              <Button className="headerBar__button">
                <h4>{t("ABOUT2")}</h4>
              </Button>
            </Link>
            <Link
              to="academyCoursesOverview"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => setToggleMenu(!toggleMenu)}
              className="labsHeaderBar__link"
            >
              <Button className="headerBar__button">
                <h4>{t("courses")}</h4>
              </Button>
            </Link>

            <div className="launchpadAspectContainer">
              <LabsHeaderLaunchpadButton />
            </div>

            <Button
              className="headerBar__button"
              href="https://discord.gg/e5FwK4rZQu"
              target="blank"
            >
              <h4>{t("Discord")}</h4>
            </Button>

            <div className="launchpadAspectContainer">
              <AcademyHeaderBarRessources />
            </div>
          </div>

          <div className="labsHeaderRightBlock">
            <div className="labsLanguageSelectorBlock">
              <WorldIcon />
              <SelectAutoWidth />
            </div>
            <LinkBrowser to="/labs/projectAgartha" className="enterButton">
              <h4>{t("StepbystepWhitelistText2")}</h4>
            </LinkBrowser>
          </div>
        </div>
      )}
    </div>
  );
}

export default AcademyHeaderBar;
