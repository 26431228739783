import React from "react";
import "./styles.css";
import LogosArea from "../../../mainPage/footer/logosArea";
import LegalMentions from "../../../mainPage/footer/legalMentions";
import PolicyPrivacy from "../../../mainPage/footer/privacyPolicy";
import TermOfUseModal from "../../../mainPage/footer/termOfUse";
import "../../../../utils/translator/index.js";
import { Trans, useTranslation } from "react-i18next";
import { Link as LinkBrowser } from "react-router-dom";
import Button from "@mui/material/Button";

function GenericFooter({ legal }) {
  const { t } = useTranslation();
  return (
    <div className="labsProjectFooterContents">
      <div className="footerMainContent">
        <div className="footerDescription">
          <h2 className="sectionTitle">{t("FOOTER_DESCRIPTION_TITLE")}</h2>
          <p className="footerDescriptionText">
            <span className="monarchFont">Agartha</span>
            {t("FOOTER_DESCRIPTION-1")}
            <span className="monarchFont">Agartha Labs, Agartha Academy</span>
            {t("FOOTER_DESCRIPTION-2")}
            <span className="monarchFont">Agartha Market. </span>
            <span className="monarchFont"> Agartha Labs</span>
            {t("FOOTER_DESCRIPTION-3")}
            <span className="monarchFont">Agartha Academy</span>
            {t("FOOTER_DESCRIPTION-4")}
            <span className="monarchFont">Agartha Market</span>
            {t("FOOTER_DESCRIPTION-5")}
            <span className="monarchFont">Agartha</span>
            {t("FOOTER_DESCRIPTION-6")}
          </p>

          <p className="footerDescriptionText">
            <Trans
              i18nKey="LEGAL_INFORMATION"
              components={{
                link1: <a href="https://www.infomaniak.com" target="_blank" className="generalLink" title="infomaniak" />,
                link2: <a href="mailto:admin@agartha.ch" target="_blank" className="generalLink" title="admin@agartha.ch" />,
              }}
            />
          </p>

        </div>

        <div className="multipleLinksSection">
          <div className="linksSection">
            <h2 className="sectionTitle">{t("LINKS_TITLE")}</h2>
            <a
              href="https://forms.gle/HhBjK8WZLYnssYm7A "
              className="linkApparence"
            >
              {t("CONTACT_LINK")}
            </a>
            <a
              href="https://forms.gle/FF16j7hYDC6P6odx9"
              className="linkApparence"
            >
              {t("RECRUITMENT_LINK")}
            </a>
            {/*<LinkBrowser to="/labs" className="linkApparence">
              {t("LABS_LINK")}
            </LinkBrowser>*/}
          </div>

          <div className="footerInternalLinks">
            <h2 className="sectionTitle">{t("services")}</h2>
            {/* <LinkBrowser to="/labs" className="headerBar__link"> */}
              <Button className="headerBarButton labsAccessButton">
                <h4 className="internalLinkTitle monarchFont">Agartha Labs</h4>
              </Button>
            {/* </LinkBrowser> */}
            {/* <LinkBrowser to="/" className="headerBar__link"> */}
            <Button className="headerBarButton disabledAccessButton">
              <h4 className="internalLinkTitle monarchFont">Agartha Academy</h4>
            </Button>
            {/* </LinkBrowser> */}
            {/* <LinkBrowser to="/" className="headerBar__link"> */}
            <Button className="headerBarButton disabledAccessButton">
              <h4 className="internalLinkTitle monarchFont">Agartha Market</h4>
            </Button>
            {/* </LinkBrowser> */}
          </div>

          <div className="legalSection">
            <h2 className="sectionTitle">{t("LEGAL_LINK")}</h2>
            <PolicyPrivacy legal={legal} />
            <LegalMentions legal={legal} />
            <TermOfUseModal legal={legal} />
          </div>
        </div>
      </div>

      <div className="moreLegalSection">
        <div className="rightsBlock">
          <p>&copy; 2022 Agartha. All rights reserved.</p>
          <a className="mailto" href="mailto:admin@agartha.ch">
            admin@agartha.ch
          </a>
        </div>
        <LogosArea />
      </div>
    </div>
  );
}

export default GenericFooter;
