import React from "react";
import "./styles.css";

function LinkedinLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 53"
      className="linkedinLogo"
    >
      <path
        fill="#fff"
        d="M27.587 47.59c5.854 0 11.467-2.229 15.606-6.195 4.14-3.967 6.465-9.347 6.465-14.956 0-5.61-2.326-10.99-6.465-14.956-4.139-3.967-9.752-6.195-15.605-6.195-5.854 0-11.467 2.228-15.606 6.195-4.14 3.966-6.464 9.346-6.464 14.956 0 5.61 2.325 10.99 6.464 14.956 4.139 3.966 9.752 6.195 15.605 6.195zm0 5.288C12.351 52.878 0 41.04 0 26.438 0 11.838 12.35 0 27.587 0s27.588 11.837 27.588 26.439-12.35 26.439-27.587 26.439z"
      ></path>
      <path
        fill="#fff"
        d="M18.874 17.921h.033c1.814 0 2.943-1.316 2.943-2.96C21.816 13.28 20.72 12 18.942 12 17.162 12 16 13.28 16 14.96c0 1.645 1.128 2.961 2.874 2.961zM16 19.895h5.85V37H16V19.895zm26 6.25c0-3.452-2.764-6.25-6.175-6.25-1.985 0-3.745.951-4.875 2.423v-2.423H25.1V37h5.85v-9.868c0-1.454 1.164-2.632 2.6-2.632 1.436 0 2.6 1.178 2.6 2.632V37H42V26.145z"
      ></path>
    </svg>
  );
}

export default LinkedinLogo;
