import React from "react";
import "./styles.css";
import BackRune1 from "../../svg/backgroundRune1";
import { useTranslation } from "react-i18next";
import "../../../utils/translator/index.js";

function Home() {
  const { t } = useTranslation();

  return (
    <div className="homeArea" id="home">
      <div className="svgContainer">
        <BackRune1 />
      </div>

      <h1 className="textCatch">{t("TextCatch")}</h1>
    </div>
  );
}

export default Home;
