import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./styles.css";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import AgarthaLitepaperFr from "../../../pdf/agarthaLitepaperFr.pdf";
import AgarthaLitepaperEn from "../../../pdf/agarthaLitepaperEn.pdf";
import i18n from "../../../../utils/translator";
import CloseIcon from "@mui/icons-material/Close";

function LabsLitepaper() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const LanguageI18next = i18n.language;

  if (LanguageI18next === "fr") {
    return (
      <div className="labsLitepaperContainer">
        <Button
          onClick={handleOpen}
          variant="outlined"
          className="labsHomeButton"
        >
          <h4>LITEPAPER</h4>
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="labsLittlepaperModalContainer"
        >
          <Box className="labsLitepaperModal sc2">
            <CloseIcon onClick={handleClose} className="memberModalCloseIcon" />
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
              <Viewer
                fileUrl={AgarthaLitepaperFr}
                defaultScale={SpecialZoomLevel.PageFit}
              />
            </Worker>
          </Box>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Button
          onClick={handleOpen}
          variant="outlined"
          className="labsHomeButton"
        >
          <h4>LITEPAPER</h4>
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="labsLittlepaperModalContainer"
        >
          <Box className="labsLitepaperModal sc2">
            <CloseIcon onClick={handleClose} className="memberModalCloseIcon" />
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
              <Viewer
                fileUrl={AgarthaLitepaperEn}
                defaultScale={SpecialZoomLevel.PageFit}
              />
            </Worker>
          </Box>
        </Modal>
      </div>
    );
  }
}

export default LabsLitepaper;
