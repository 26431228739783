import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./styles.css";
import { useTranslation } from "react-i18next";
import "../../../../utils/translator/index.js";
import CloseIcon from "@mui/icons-material/Close";

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    // Change the mouse cursor to "wait"
    document.body.style.cursor = "wait";

    // Get a reference to the button element
    const buttonElement = document.querySelector(".rdvButton");

    // Add the "hover-cursor" class to the button element
    buttonElement.classList.add("hover-cursor");

    // Open the modal after 2 seconds
    setTimeout(() => {
      setOpen(true);
      // Change the mouse cursor back to the default
      document.body.style.cursor = "default";
      // Remove the "hover-cursor" class from the button element
      buttonElement.classList.remove("hover-cursor");
    }, 200);
  };

  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button
        variant="text"
        onClick={handleOpen}
        className="homeButton color-11 rdvButton"
      >
        <h4>{t("rdvButton")}</h4>
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="calendarBox sc2">
          <div id="modal-modal-title" className="calendarBoxContainer">
            <iframe
              src="https://calendly.com/agartha-ch/30min?primary_color=b1b3b5"
              frameBorder="0"
              className="calendarWindow"
              title="calendor Popup"
            ></iframe>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
