import React from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import "./styles.css";

export default function Course1(props) {
  return (
    <Box className="courseModalDetails sc2">
      <CloseIcon onClick={props.handleClose} className="memberModalCloseIcon" />

      <h2>Formation financière de base</h2>

      <div>
        <Accordion
          expanded={props.expanded === "panel1"}
          onChange={props.handleChange("panel1")}
          className="courseOverviewAccordions"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            Sécurité
          </AccordionSummary>
          <AccordionDetails>
            <ul className="formation-details-list">
              <li>
                <h3>VPN</h3>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Fugiat molestiae magni non, facilis perferendis iste ipsam!
                  Esse alias veniam sapiente est omnis odio molestias vero, amet
                  quam, voluptatibus rem mollitia.
                </p>
              </li>
              <li>
                <h3>Outils</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Aperiam soluta optio velit blanditiis expedita natus placeat
                  voluptates et magnam maiores, temporibus veritatis voluptatum
                  possimus repellendus provident nemo fuga ea deserunt!
                </p>
              </li>
              <li>
                <h3>Comptes</h3>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi
                  consectetur optio sunt, odit nisi laudantium quos, ullam
                  ipsam, voluptas sit maiores porro vitae iste quidem
                  repudiandae? Beatae odio necessitatibus vero.
                </p>
              </li>
              <li>
                <h3>Mots de passe</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Dicta nostrum explicabo hic aliquid minima adipisci at error,
                  dolore, dolor unde asperiores molestias id amet magni? Vel non
                  corporis similique in.
                </p>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={props.expanded === "panel2"}
          onChange={props.handleChange("panel2")}
          className="courseOverviewAccordions"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            Forex
          </AccordionSummary>
          <AccordionDetails>
            <ul className="formation-details-list">
              <li>
                <h3>Définition Forex</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Accusamus reiciendis sit, animi perferendis illum vitae
                  repellat inventore eaque magnam, harum unde quis? Repellendus
                  maiores quibusdam, modi sit eaque dolorem eligendi.
                </p>
              </li>
              <li>
                <h3>Comment le trader</h3>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Molestiae est voluptatum vel. Maiores dolorem labore vitae
                  architecto, aut veniam explicabo porro aperiam, obcaecati
                  voluptate veritatis earum adipisci facilis fuga eligendi.
                </p>
              </li>
              <li>
                <h3>Quand le trader</h3>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Molestiae est voluptatum vel. Maiores dolorem labore vitae
                  architecto, aut veniam explicabo porro aperiam, obcaecati
                  voluptate veritatis earum adipisci facilis fuga eligendi.
                </p>
              </li>
              <li>
                <h3>Qui le trade</h3>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Molestiae est voluptatum vel. Maiores dolorem labore vitae
                  architecto, aut veniam explicabo porro aperiam, obcaecati
                  voluptate veritatis earum adipisci facilis fuga eligendi.
                </p>
              </li>
              <li>
                <h3>Pourquoi</h3>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Molestiae est voluptatum vel. Maiores dolorem labore vitae
                  architecto, aut veniam explicabo porro aperiam, obcaecati
                  voluptate veritatis earum adipisci facilis fuga eligendi.
                </p>
              </li>
              <li>
                <h3>Trading sur marge</h3>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Molestiae est voluptatum vel. Maiores dolorem labore vitae
                  architecto, aut veniam explicabo porro aperiam, obcaecati
                  voluptate veritatis earum adipisci facilis fuga eligendi.
                </p>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={props.expanded === "panel3"}
          onChange={props.handleChange("panel3")}
          className="courseOverviewAccordions"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            Types de Broker, graphique et analyse
          </AccordionSummary>
          <AccordionDetails>
            <ul className="formation-details-list">
              <li>
                <h3>Courtier Forex</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nam
                  dolores atque ipsam ullam aut facere sed fuga consequatur,
                  necessitatibus nostrum fugit, perspiciatis neque quia,
                  incidunt ipsum tempora animi rem iure!
                </p>
              </li>
              <li>
                <h3>Types d’analyse</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nam
                  dolores atque ipsam ullam aut facere sed fuga consequatur,
                  necessitatibus nostrum fugit, perspiciatis neque quia,
                  incidunt ipsum tempora animi rem iure!
                </p>
              </li>
              <li>
                <h3>Types de graphique</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nam
                  dolores atque ipsam ullam aut facere sed fuga consequatur,
                  necessitatibus nostrum fugit, perspiciatis neque quia,
                  incidunt ipsum tempora animi rem iure!
                </p>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={props.expanded === "panel4"}
          onChange={props.handleChange("panel4")}
          className="courseOverviewAccordions"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            Introduction Technique
          </AccordionSummary>
          <AccordionDetails>
            <ul className="formation-details-list">
              <li>
                <h3>Supports et résistances</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
                  quis ipsam voluptates vel quidem, laboriosam rem. Iste illo
                  beatae temporibus asperiores perferendis dolores hic, animi
                  maxime suscipit deleniti officia ad.
                </p>
              </li>
              <li>
                <h3>Chandeliers japonais</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
                  quis ipsam voluptates vel quidem, laboriosam rem. Iste illo
                  beatae temporibus asperiores perferendis dolores hic, animi
                  maxime suscipit deleniti officia ad.
                </p>
              </li>
              <li>
                <h3>Fibonacci</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
                  quis ipsam voluptates vel quidem, laboriosam rem. Iste illo
                  beatae temporibus asperiores perferendis dolores hic, animi
                  maxime suscipit deleniti officia ad.
                </p>
              </li>
              <li>
                <h3>Moyennes mobiles</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
                  quis ipsam voluptates vel quidem, laboriosam rem. Iste illo
                  beatae temporibus asperiores perferendis dolores hic, animi
                  maxime suscipit deleniti officia ad.
                </p>
              </li>
              <li>
                <h3>Indicateurs graphiques populaires</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
                  quis ipsam voluptates vel quidem, laboriosam rem. Iste illo
                  beatae temporibus asperiores perferendis dolores hic, animi
                  maxime suscipit deleniti officia ad.
                </p>
              </li>
              <li>
                <h3>Oscillateurs et indicateurs de Momentum</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
                  quis ipsam voluptates vel quidem, laboriosam rem. Iste illo
                  beatae temporibus asperiores perferendis dolores hic, animi
                  maxime suscipit deleniti officia ad.
                </p>
              </li>
              <li>
                <h3>Modèles graphiques importants</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
                  quis ipsam voluptates vel quidem, laboriosam rem. Iste illo
                  beatae temporibus asperiores perferendis dolores hic, animi
                  maxime suscipit deleniti officia ad.
                </p>
              </li>
              <li>
                <h3>Points pivots</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
                  quis ipsam voluptates vel quidem, laboriosam rem. Iste illo
                  beatae temporibus asperiores perferendis dolores hic, animi
                  maxime suscipit deleniti officia ad.
                </p>
              </li>
              <li>
                <h3>Heikin Ashi</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
                  quis ipsam voluptates vel quidem, laboriosam rem. Iste illo
                  beatae temporibus asperiores perferendis dolores hic, animi
                  maxime suscipit deleniti officia ad.
                </p>
              </li>
              <li>
                <h3>Vagues d’Elliott</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
                  quis ipsam voluptates vel quidem, laboriosam rem. Iste illo
                  beatae temporibus asperiores perferendis dolores hic, animi
                  maxime suscipit deleniti officia ad.
                </p>
              </li>
              <li>
                <h3>Modèles de prix harmoniques</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
                  quis ipsam voluptates vel quidem, laboriosam rem. Iste illo
                  beatae temporibus asperiores perferendis dolores hic, animi
                  maxime suscipit deleniti officia ad.
                </p>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={props.expanded === "panel5"}
          onChange={props.handleChange("panel5")}
          className="courseOverviewAccordions"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            Introduction Fondamentale
          </AccordionSummary>
          <AccordionDetails>
            <ul className="formation-details-list">
              <li>
                <h3>Divergences</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
              <li>
                <h3>Environnement de marché</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
              <li>
                <h3>Cassures</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
              <li>
                <h3>Analyse fondamentale</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
              <li>
                <h3>Croisements de devises</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
              <li>
                <h3>Périodes multiples</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
              <li>
                <h3>Sentiment du marché</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
              <li>
                <h3>Négocier l’actualité</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
              <li>
                <h3>Portée du commerce</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
              <li>
                <h3>Indice du dollar américain</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
              <li>
                <h3>Corrélations intermarchés</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
              <li>
                <h3>Utiliser des actions</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
              <li>
                <h3>Profils de pays</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia, obcaecati sit natus adipisci iste perferendis ipsa.
                  Blanditiis tempore harum quam mollitia nostrum, voluptatibus
                  distinctio debitis voluptate accusamus numquam. Atque,
                  sapiente.
                </p>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={props.expanded === "panel6"}
          onChange={props.handleChange("panel6")}
          className="courseOverviewAccordions"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            Les choses à faire et à ne pas faire
          </AccordionSummary>
          <AccordionDetails>
            <ul className="formation-details-list">
              <li>
                <h3>Bon à savoir</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
              <li>
                <h3>Types de trader</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
              <li>
                <h3>Créer votre propre système</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
              <li>
                <h3>Tenir un journal</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
              <li>
                <h3>Gestion des risques</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
              <li>
                <h3>Attention</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
              <li>
                <h3>Taille des positions</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
              <li>
                <h3>Définir des Stop Loss</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
              <li>
                <h3>Mise à l’échelle</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
              <li>
                <h3>Corrélations de devises</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
              <li>
                <h3>Les erreurs</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
              <li>
                <h3>Escroqueries</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
              <li>
                <h3>Personnalité</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et
                  excepturi magni quam aut necessitatibus recusandae inventore.
                  Ipsum explicabo quasi molestias, vel asperiores libero. Illo
                  quisquam natus animi eligendi assumenda iure!
                </p>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>

      <Button
        variant="outlined"
        className="agarthaAcademyButton modalEnterCourseButton"
      >
        <h4>ENTER</h4>
      </Button>
    </Box>
  );
}
