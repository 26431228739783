import React from "react";
import "./styles.css";

function EventsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 139 139"
      className="eventsIcon"
    >
      <circle cx="69.5" cy="69.5" r="69.5" fill="#D9D9D9"></circle>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.303"
        d="M35 62.008a7.203 7.203 0 017.203-7.203h57.624a7.203 7.203 0 017.204 7.203v25.21a7.203 7.203 0 01-7.204 7.203H85.898a7.203 7.203 0 01-6.176-3.497l-5.618-9.363c-1.4-2.332-4.778-2.332-6.177 0l-5.618 9.363a7.203 7.203 0 01-6.177 3.497H42.203A7.203 7.203 0 0135 87.218v-25.21z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="11.303"
        d="M41.528 50.415A14.406 14.406 0 0153.514 44h35.002c4.817 0 9.315 2.407 11.987 6.415l2.926 4.39H38.601l2.927-4.39z"
      ></path>
    </svg>
  );
}

export default EventsIcon;
