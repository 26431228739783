import PopupPrivacy from "./pupupPrivacy";
import "./App.css";
import Header from "./header";
import Home from "./home";
import Team from "./team";
import TextAbout from "./textAbout";
import RoadMap from "./roadMap";
import Footer from "./footer";
import InterestWeb3 from "./interestWeb3";
import Services from "./services";
import OurPartners from "./ourPartners";
import { Helmet } from "react-helmet";

const App = ({ legal }) => {
  return (
    <div className="App">
      <PopupPrivacy />
      <Helmet>
        <link rel="canonical" href="https://agartha.ch/" />
      </Helmet>

      <Header />
      <Home />
      <TextAbout />
      <Services />
      <RoadMap />
      <Team />
      <InterestWeb3 />
      <OurPartners />
      <Footer legal={legal}/>
    </div>
  );
};

export default App;
