import * as React from "react";
import "./styles.css";

function DollarSvgIcon(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.88 122.88"
      className="dollarSvgIcon"
      {...props}
    >
      <defs>
        <style>{".cls-1{fill:#fecb00}"}</style>
      </defs>
      <path
        className="cls-1"
        d="M61.44 0A61.46 61.46 0 1118 18 61.21 61.21 0 0161.44 0z"
      />
      <path
        d="M61.44 8.74a52.69 52.69 0 0152.7 52.7v.92a50.86 50.86 0 10-51.77 51.77h-.92a52.7 52.7 0 010-105.4z"
        fillRule="evenodd"
        fill="#d08b00"
      />
      <path
        d="M63.28 12.41a50.87 50.87 0 11-50.87 50.87 50.87 50.87 0 0150.87-50.87z"
        fill="#db9300"
        fillRule="evenodd"
      />
      <path
        d="M83.83 47.06l-14.09 2.27a15.14 15.14 0 00-1.91-3.77A9.11 9.11 0 0065 43.44v11.15q11.48 3.13 15.34 6.59A15.4 15.4 0 0185.45 73a16.26 16.26 0 01-1.83 7.7A18.89 18.89 0 0172.88 90 29.13 29.13 0 0165 91.26v7.26h-5.47v-7.26a34.38 34.38 0 01-9-1.82 18 18 0 01-6-3.67 17.77 17.77 0 01-3.87-5 23.65 23.65 0 01-2-6.54l15.25-1.81a14.58 14.58 0 001.84 5.48 9.6 9.6 0 003.81 3V67.21a68.18 68.18 0 01-10.85-3.74 14.4 14.4 0 01-5.54-5.08 14.92 14.92 0 01-2.34-8.5 15.4 15.4 0 014.7-11.47c3.15-3.08 7.82-4.8 14-5.12v-3.79H65v3.79q8.48.53 13 4.08a16.42 16.42 0 015.85 9.68zm-24.3-3.81a7.1 7.1 0 00-3.38 2 4.33 4.33 0 00-1 2.81 4.64 4.64 0 001 2.94 7.08 7.08 0 003.37 2.1v-9.85zm5.49 38a8.73 8.73 0 004.7-2.42A5.58 5.58 0 0071.21 75 5.16 5.16 0 0070 71.69c-.84-1-2.49-2-4.93-2.83v12.37z"
        fill="#b17600"
      />
      <path
        className="cls-1"
        d="M81.19 45L67.1 47.22a14.53 14.53 0 00-1.91-3.68 9.19 9.19 0 00-2.81-2.08v10.92q11.48 3.07 15.34 6.47a14.92 14.92 0 015.09 11.61A15.77 15.77 0 0181 78a18.66 18.66 0 01-4.68 5.75 18.87 18.87 0 01-6.07 3.35 30 30 0 01-7.85 1.22v7.12h-5.5v-7.12a34.54 34.54 0 01-9-1.78 18.16 18.16 0 01-6-3.6A17.75 17.75 0 0138 78a23.24 23.24 0 01-2-6.41l15.24-1.77a13.92 13.92 0 001.85 5.37 9.55 9.55 0 003.8 2.93V64.75A69.82 69.82 0 0146 61.09a14.48 14.48 0 01-7.89-13.31 15 15 0 014.71-11.25q4.72-4.53 14-5V27.8h5.5v3.71q8.48.52 13 4A16.06 16.06 0 0181.19 45zm-24.31-3.74a7.19 7.19 0 00-3.38 2 4.24 4.24 0 00-1 2.76 4.47 4.47 0 001 2.87 7.1 7.1 0 003.36 2.07v-9.7zm5.5 37.23a8.76 8.76 0 004.69-2.37 5.37 5.37 0 001.5-3.69 5.08 5.08 0 00-1.26-3.29 11.58 11.58 0 00-4.93-2.77v12.12z"
      />
    </svg>
  );
}

export default DollarSvgIcon;
