import React from "react";
import "./styles.css";

function BackRune1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 452.98 376.741"
      className="svgRune1"
    >
      <defs>
        <clipPath id="clipPath626" patternUnits="userSpaceOnUse">
          <path d="M451.097 1635.818v-143.634c0-26.755-21.69-48.445-48.445-48.445h-23.051l-.004 192.079h-.969l.004-192.079H343.25a30.502 30.502 0 01-26.941-16.202l-21.283-40.102-22.402 24.643a30.494 30.494 0 01-22.568 9.985H112.331v-28.591c0-22.207 18.002-40.21 40.209-40.21h125.447l17.249 32.501 29.544-32.501h54.825l-.004 88.053h72.465v194.503zm-155.378-249.145l21.174 39.895c5.291 9.969 15.654 14.747 26.94 14.747h34.799l.003-87.083h-53.426zm-182.419 8.122v26.299h136.327a30.497 30.497 0 0022.569-9.985l22.347-24.583-16.345-30.84-124.681-.023h-.009c-22.204.001-40.208 16.926-40.208 39.132"></path>
        </clipPath>
        <linearGradient
          id="linearGradient642"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="rotate(50 -1317.035 854.834) scale(468.92846)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#1c1d58" stopOpacity="1"></stop>
          <stop offset="0.25" stopColor="#ed1c24" stopOpacity="1"></stop>
          <stop offset="0.6" stopColor="#fbc707" stopOpacity="1"></stop>
          <stop offset="0.8" stopColor="#99dde3" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#00a9a0" stopOpacity="1"></stop>
        </linearGradient>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 -149.775 2181.09)">
        <g>
          <g clipPath="url(#clipPath626)">
            <g>
              <g>
                <path
                  fill="url(#linearGradient642)"
                  stroke="none"
                  d="M451.097 1635.818v-143.634c0-26.755-21.69-48.445-48.445-48.445h-23.051l-.004 192.079h-.969l.004-192.079H343.25a30.502 30.502 0 01-26.941-16.202l-21.283-40.102-22.402 24.643a30.494 30.494 0 01-22.568 9.985H112.331v-28.591c0-22.207 18.002-40.21 40.209-40.21h125.447l17.249 32.501 29.544-32.501h54.825l-.004 88.053h72.465v194.503zm-155.378-249.145l21.174 39.895c5.291 9.969 15.654 14.747 26.94 14.747h34.799l.003-87.083h-53.426zm-182.419 8.122v26.299h136.327a30.497 30.497 0 0022.569-9.985l22.347-24.583-16.345-30.84-124.681-.023h-.009c-22.204.001-40.208 16.926-40.208 39.132"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BackRune1;
