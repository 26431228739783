import React, { useState, useEffect } from "react";
import SolPrice from "../../../api/cryptoCompare/solPrice";

function LabsProjectPriceCalculator({ currency }) {
  const [price, setPrice] = useState();

  useEffect(() => {
    if (currency === "SOL") {
      setPrice(<span>16 SOL</span>);
    }
    if (currency === "ETH") {
      setPrice(
        <span id="solana">
          <SolPrice currency={currency} />
        </span>
      );
      console.log("You choosed the currency etherum");
    }
    if (currency === "$") {
      setPrice(
        <span id="solana2">
          <SolPrice currency={currency} />
        </span>
      );
      console.log("You choosed the currency dollar");
    }
  }, [currency]);

  return price;
}

export default LabsProjectPriceCalculator;
