import * as React from "react";
import "./styles.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import AgarthaLabsLogoImage from "../../../images/agarthaLabsLogo.png";
import { Link as LinkBrowser } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

export default function LabsUpcomingGetStone() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button
        onClick={handleOpen}
        className="labsHomeButton labsProjectWhitelistAgartha"
      >
        {t("getWhitelisted")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="getTheStoneBox">
          <CloseIcon onClick={handleClose} className="memberModalCloseIcon" />
          <img
            src={AgarthaLabsLogoImage}
            alt="logo agartha labs"
            className="labsLogo"
          />
          <h3>{t("whitelistAccess")}</h3>
          <p>{t("whitelistAccessText1")}</p>
          <p>{t("whitelistAccessText2")}</p>
          <LinkBrowser
            to="/labs/projectAgartha"
            variant="outlined"
            className="labsHomeButton labsProjectWhitelistAgartha"
          >
            <h4 className="ces2023LinkApparence">{t("getTheStone")}</h4>
          </LinkBrowser>
        </Box>
      </Modal>
    </div>
  );
}
