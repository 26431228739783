import React from "react";
import "./styles.css";

function BackgroundRune4() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 541.132 393.944"
      className="svgRune4"
    >
      <defs>
        <clipPath id="clipPath678" clipPathUnits="userSpaceOnUse">
          <path d="M102.46 236.023c-38.327-38.327-59.435-89.286-59.435-143.489V28.931h149.498L200.594 0h1.507l-8.072 28.931h129.487v167.118H145.899l46.219-165.666h-85.49c-34.326 0-61.784 27.826-61.784 62.151 0 53.815 22.04 104.768 60.094 142.82 38.054 38.054 87.197 58.285 141.012 58.285 53.816 0 104.41-20.957 142.463-59.01 38.054-38.053 59.01-88.28 59.01-142.095V29.992L412.448 0h2.229l34.197 29.324v63.21c0 54.203-21.108 105.162-59.434 143.489-38.328 38.328-89.287 59.435-143.49 59.435s-105.162-21.107-143.49-59.435m45.351-41.426h100.603c40.676 0 73.651-32.974 73.651-73.65V30.383H193.624z"></path>
        </clipPath>
        <linearGradient
          id="linearGradient694"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(430.56591 -430.56591) rotate(-70 .404 -.143)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#1c1d58" stopOpacity="1"></stop>
          <stop offset="0.25" stopColor="#ed1c24" stopOpacity="1"></stop>
          <stop offset="0.6" stopColor="#fbc707" stopOpacity="1"></stop>
          <stop offset="0.8" stopColor="#99dde3" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#00a9a0" stopOpacity="1"></stop>
        </linearGradient>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 -57.367 393.944)">
        <g>
          <g clipPath="url(#clipPath678)">
            <g>
              <g>
                <path
                  fill="url(#linearGradient694)"
                  stroke="none"
                  d="M102.46 236.023c-38.327-38.327-59.435-89.286-59.435-143.489V28.931h149.498L200.594 0h1.507l-8.072 28.931h129.487v167.118H145.899l46.219-165.666h-85.49c-34.326 0-61.784 27.826-61.784 62.151 0 53.815 22.04 104.768 60.094 142.82 38.054 38.054 87.197 58.285 141.012 58.285 53.816 0 104.41-20.957 142.463-59.01 38.054-38.053 59.01-88.28 59.01-142.095V29.992L412.448 0h2.229l34.197 29.324v63.21c0 54.203-21.108 105.162-59.434 143.489-38.328 38.328-89.287 59.435-143.49 59.435s-105.162-21.107-143.49-59.435m45.351-41.426h100.603c40.676 0 73.651-32.974 73.651-73.65V30.383H193.624z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BackgroundRune4;
