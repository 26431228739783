import DeltaOneCapitalLogo from "../../labs/svg/trustedByLogos/deltaOneCapitalLogo";
import DaiicoLogo from "../../images/projects/projectDaiico.png";
import DaoEvents from "../../images/projects/projectDao.png";
import SwissLanguageAcademyLogo from "../../labs/svg/trustedByLogos/swissLanguageAcademyLogo";
import CryptoConseilLogo from "../../images/Logos/cryptoConseilLogo.png";
import InfomaniakLogo from "../../images/Logos/infomaniakLogo.png";
import SwisscomLogo from "../../images/Logos/swisscomLogo.png";
import AlpineFootageLogo from "../../images/Logos/alpineFootageLogo.png";
import CryptoHoldingBrockerLogo from "../../images/Logos/cryptoHoldingBrokerLogo.png";
import SwisstechLogo from "../../labs/svg/trustedByLogos/swisstechLogo";
import RadioCiteGeneveLogo from "../../images/Logos/radioCiteGeneveLogo.png";
import LeMatinLogo from "../../images/Logos/leMatinLogo.png";
import OnefmLogo from "../../images/Logos/onefmLogo.png";
import MarketWatchLogo from "../../images/Logos/marketWatchLogo.png";
import MetaversePortalsLogo from "../../labs/svg/metaversePortalsLogo";
import DigitalJournalLogo from "../../images/Logos/digitalJournalLogo.png";
import RadioLacLogo from "../../images/Logos/radioLacLogo.png";
import RtsLogo from "../../images/Logos/rtsLogo.png";
import AglaeLogo from "../../images/Logos/aglaéLogo.png";
import bullzLogo from "../../images/Logos/bullzLogo.png";
import cesLogo from "../../images/Logos/ces2023Logo.png";
import MetafabLogo from "../../images/Logos/metafabLogo.png";
import PierwalletLogo from "../../labs/svg/trustedByLogos/pierwalletLogo";
import Web3Toolslogo from "../../images/Logos/web3ToolsLogo.png";
import Womlogo from "../../images/Logos/womLogo.png";
import BlockseeLogo from "../../images/Logos/blockseeLogo.png";
import SwissBitcoinPayLogo from "../../images/Logos/swissBitcoinPayLogo.png";
import LaHugeLetter from "../../images/Logos/laHugeLetter.png";
import GECLogo from "../../images/Logos/GEClogo.png";

export const TrustedByList = [
  {
    id: 1,
    img: DaiicoLogo,
    title: "Daiico",
  },

  {
    id: 2,
    img: DaoEvents,
    title: "Dao Events",
  },

  {
    id: 3,
    svg: <SwissLanguageAcademyLogo />,
    title: "Swiss Language Academy",
    website: "https://swiss-language-academy.ch/",
  },

  {
    id: 4,
    img: Web3Toolslogo,
    title: "Web3 Tools",
    website: "https://www.web3supply.io/",
  },
];

export const PartnersList = [
  {
    id: 1,
    img: AglaeLogo,
    title: "Aglaé",
    website: "https://www.design-aglae.com/",
  },

  {
    id: 2,
    img: AlpineFootageLogo,
    title: "Alpine Footage",
    website: "https://www.alpinefootage.com/",
  },

  {
    id: 3,
    img: bullzLogo,
    title: "Bullz",
    website: "https://www.bullz.com/",
  },

  {
    id: 4,
    img: cesLogo,
    title: "CES",
    website: "https://www.ces.tech/",
  },

  {
    id: 5,
    img: CryptoConseilLogo,
    title: "Crypto Conseil",
    website: "https://www.cryptoconseil.ch/en",
  },

  {
    id: 9,
    svg: (
      <img src={CryptoHoldingBrockerLogo} alt="az" className="swisstechLogo" />
    ),
    title: "Crypto Holding Brocker",
  },

  {
    id: 10,
    img: DaoEvents,
    title: "Dao Events",
  },

  {
    id: 11,
    img: InfomaniakLogo,
    title: "Infomaniak",
    website: "https://www.infomaniak.com/en",
  },

  {
    id: 12,
    svg: <DeltaOneCapitalLogo />,
    title: "Delta One Capital",
  },

  {
    id: 13,
    img: MetafabLogo,
    title: "MetaFab",
    website: "https://www.trymetafab.com/",
  },

  {
    id: 14,
    svg: <PierwalletLogo />,
    title: "Pierwallet",
    website: "https://www.pierwallet.com/",
  },

  {
    id: 15,
    img: SwisscomLogo,
    title: "Swisscom",
    website: "https://www.swisscom.ch/en/residential.html",
  },

  {
    id: 16,
    svg: <SwisstechLogo />,
    title: "Swisstech",
    website: "https://www.swiss.tech/",
  },

  {
    id: 17,
    img: Web3Toolslogo,
    title: "Web3 Tools",
    website: "https://www.web3supply.io/",
  },

  {
    id: 18,
    img: Womlogo,
    title: "Wom",
    website: "https://www.womprotocol.io/",
  },

  {
    id: 19,
    img: BlockseeLogo,
    title: "Blocksee",
    website: "https://www.blocksee.co/",
  },

  {
    id: 20,
    img: SwissBitcoinPayLogo,
    title: "Swiss Bitcoin Pay",
    website: "https://swiss-bitcoin-pay.ch/",
  },

  {
    id: 22,
    img: GECLogo,
    title: "Geneva Event Crypto",
    website: "https://gec-swiss.ch/",
  },
];

export const TalkAboutUsList = [
  {
    id: 1,
    img: RadioCiteGeneveLogo,
    title: "Radio Cité Geneve",
  },

  {
    id: 2,
    img: LeMatinLogo,
    title: "Le Matin",
    website:
      "https://www.lematin.ch/story/une-ecole-de-langues-suisse-tente-le-metavers-modeste-982634953802",
  },

  {
    id: 3,
    img: OnefmLogo,
    title: "One FM",
    website:
      "https://www.onefm.ch/emissions/happy-hour/le-mag/une-ecole-genevoise-donne-des-cours-dans-le-metavers/",
  },

  {
    id: 4,
    img: DigitalJournalLogo,
    title: "Digital Journal",
    website:
      "https://www.digitaljournal.com/pr/news/bullz-and-agartha-partner-to-boost-adoption-for-web3-projects-through-the-power-of-user-generated-content",
  },

  {
    id: 5,
    img: MarketWatchLogo,
    title: "MarketWatch",
    website:
      "https://www.marketwatch.com/press-release/bullz-and-agartha-partner-to-boost-adoption-for-web3-projects-through-the-power-of-user-generated-content-2023-02-14?mod=search_headline",
  },

  {
    id: 6,
    img: RadioLacLogo,
    title: "Radio Lac",
    website:
      "https://www.radiolac.ch/actualite/geneve/une-ecole-genevoise-donne-des-cours-dans-le-metavers/",
  },

  {
    id: 7,
    img: RtsLogo,
    title: "RTS",
    website:
      "https://www.rts.ch/play/embed?urn=urn:rts:video:13970117&startTime=3180&subdivisions=true%22",
  },

  {
    id: 8,
    img: LaHugeLetter,
    title: "La Huge Letter",
    website:
      "http://www.hugeletter.fr/blog/qui-est-agartha-la-societe-qui-a-represente-la-suisse-au-ces2023-de-las-vegas",
  },
];

export const MetaverseStudioList = [
  {
    id: 1,
    svg: <MetaversePortalsLogo />,
    title: "Portals",
    website: "https://theportal.to/",
  },
];
