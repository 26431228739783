import React, { useState, useEffect } from "react";
import "./styles.css";
import Button from "@mui/material/Button";
import WorldIcon from "../svg/worldIcon";
import SelectAutoWidth from "../labsHeader/languageSelector";
import { useTranslation } from "react-i18next";
import "../../../utils/translator";
import { Link as LinkBrowser } from "react-router-dom";
import GenericFooter from "../../mainPage/footer/genericFooter";
import AgarthaLabsLogoImage from "../../images/agarthaLabsLogo.png";
import LabsSvgRune12 from "../svg/labsBackgroundRune12";
import LabsSvgRune11 from "../svg/labsBackgroundRune11";
import LabsSvgRune9 from "../svg/labsBackgroundRune9";
import LabsSvgRune10 from "../svg/labsBackgroundRune10";
import LabsHeaderLaunchpadButton from "../labsHeader/labsHeaderLaunchpadButton";
import ProjectDao from "../../images/projects/projectDao.png";
import ProjectDaiico from "../../images/projects/projectDaiico.png";
import ProjectAgarthaAcademy from "../../images/projects/projectAgarthaAcademy.png";
import LabsUpcomingGetStone from "./labsUpcomingGetStone";
import { Helmet } from "react-helmet";

function LabsUpcoming(props) {
  const { t } = useTranslation();
  const [largeur, setLargeur] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
    };

    props.toggleMenu
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div className="labsUpcomingArea">
      <Helmet>
        <link rel="canonical" href="https://agartha.ch/labs/upcoming" />
      </Helmet>

      <div className="labsProjectHeader">
        <img
          src={AgarthaLabsLogoImage}
          alt="logo agartha labs"
          className="labsLogo"
        />
        <div className="labsProjectHeaderButtons">
          <LinkBrowser to="/labs" className="labsProjectLinkApparence">
            <Button className="headerBar__button labsProjectReturnButton">
              <h4>{t("StepbystepReturnButton")}</h4>
            </Button>
          </LinkBrowser>
          <LabsHeaderLaunchpadButton />
          <div className="labsLanguageSelectorBlock labsProjectLanguageButton">
            <WorldIcon />
            <SelectAutoWidth />
          </div>
        </div>
      </div>

      <div className="labsUpcomingProjectsContainer">
        <h1>{t("labsUpcomingTitle")}</h1>

        {largeur >= 750 && (
          <div className="svgContainerLabsProjectLeft">
            {largeur > 1025 && <LabsSvgRune10 />};
            {largeur <= 1025 && <LabsSvgRune11 />}
          </div>
        )}
        {largeur >= 750 && (
          <div className="svgContainerLabsProjectRight">
            {largeur > 1025 && <LabsSvgRune9 />};
            {largeur <= 1025 && <LabsSvgRune12 />}
          </div>
        )}

        <div className="labsUpcomingProjectBlock">
          <h4 className="labsUpcomingProjectState">
            {t("labsUpcomingStateComingSoon")}
          </h4>
          <img
            src={ProjectDaiico}
            alt="Daiico logo"
            className="labsUpcomingProjectImage"
          />
          <h2 className="labsUpcomingProjectTitle">Daiico</h2>
          <div className="labsUpcomingProjectDescriptionBlock">
            <p className="labsUpcomingProjectDescription">
              {t("labsUpcomingDescriptionDaiico")}
            </p>
            <LabsUpcomingGetStone />
          </div>
        </div>

        <div className="labsUpcomingProjectBlock">
          <h4 className="labsUpcomingProjectState">
            {t("labsUpcomingStateComingSoon")}
          </h4>
          <img
            src={ProjectAgarthaAcademy}
            alt="Agartha Academy logo"
            className="labsUpcomingProjectImage"
          />
          <h2 className="labsUpcomingProjectTitle monarchFont">
            Agartha Academy
          </h2>
          <div className="labsUpcomingProjectDescriptionBlock">
            <p className="labsUpcomingProjectDescription">
              <span className="monarchFont">Agartha Academy</span>
              {t("labsUpcomingDescriptionAgarthaAcademy")}
            </p>
            <LabsUpcomingGetStone />
          </div>
        </div>

        <div className="labsUpcomingProjectBlock">
          <h4 className="labsUpcomingProjectState">
            {t("labsUpcomingStateComingSoon")}
          </h4>
          <img
            src={ProjectDao}
            alt="DAO logo"
            className="labsUpcomingProjectImage"
          />
          <h2 className="labsUpcomingProjectTitle">DAO</h2>
          <div className="labsUpcomingProjectDescriptionBlock">
            <p className="labsUpcomingProjectDescription">
              {t("labsUpcomingDescriptionDAO")}
            </p>
            <LabsUpcomingGetStone />
          </div>
        </div>
      </div>

      <GenericFooter />
    </div>
  );
}

export default LabsUpcoming;
