import React from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";

export default function Course4(props) {
  return (
    <Box className="courseModalDetails sc2">
      <CloseIcon onClick={props.handleClose} className="memberModalCloseIcon" />

      <h2>Fondamentaux de la blockchain 44</h2>
      <p>
        Ce module fournira les bases technologiques, conceptuelles et
        philosophiques pour comprendre ce qu’est la blockchain et ses nombreux
        champs d’application.
      </p>
      <h3>Thèmes Abordés</h3>
      <ul>
        <li>Qu’est-ce qu’est la blockchain?</li>
        <li>
          Comprendre les protocoles essentiels ainsi que leur développement
          historique: Bitcoin & Ethereum.
        </li>
        <li>
          Qu’est ce que la monnaie à travers le temps et les différences avec
          divers crypto monnaies
        </li>
        <li>
          Les différents types de blockchain ainsi que leur utilité (layer 1,
          layer 2…)
        </li>
        <li>Les mécanismes de consensus (Proof of Work vs Proof of Stake)</li>
        <li>Les applications de la blockchain dans divers secteurs</li>
      </ul>

      <div>
        <Accordion
          expanded={props.expanded === "panel1"}
          onChange={props.handleChange("panel1")}
          className="courseOverviewAccordions"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            Tokenomics - Exemple with stacked accordions
          </AccordionSummary>
          <AccordionDetails>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores
            magni quam molestiae. Veritatis laudantium quas enim tenetur dolor
            ea. Reiciendis illo, est pariatur consectetur praesentium harum
            eveniet saepe officiis tempore.
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={props.expanded === "panel2"}
          onChange={props.handleChange("panel2")}
          className="courseOverviewAccordions"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            Finance décentralisée
          </AccordionSummary>
          <AccordionDetails>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti
            distinctio dolore explicabo unde soluta vitae, mollitia dolores,
            fugit harum doloribus odit adipisci quis, perspiciatis corporis
            earum. Corporis nam veniam soluta!
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={props.expanded === "panel3"}
          onChange={props.handleChange("panel3")}
          className="courseOverviewAccordions"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            disabled
          >
            Analyse on-chain - Exemple for blocked accordion
          </AccordionSummary>
          <AccordionDetails>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi
            voluptate sequi, quia esse sed eveniet perferendis amet maiores
            consequatur explicabo. Animi vero deleniti nobis harum eius neque
            pariatur molestias nisi? Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Aut temporibus odit dolorem consectetur ea ullam,
            inventore veniam, voluptates delectus minus quod atque omnis
            deserunt sit. Ex amet corrupti autem laudantium.
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={props.expanded === "panel4"}
          onChange={props.handleChange("panel4")}
          className="courseOverviewAccordions"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            Réglementation et stratégie
          </AccordionSummary>
          <AccordionDetails>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis
            enim sunt autem odio corrupti tempore magni ea voluptatem corporis
            fugiat! Distinctio ex debitis maiores doloribus a obcaecati rem
            quibusdam consequuntur.
          </AccordionDetails>
        </Accordion>
      </div>

      <Button
        variant="outlined"
        className="agarthaAcademyButton modalEnterCourseButton"
      >
        <h4>ENTER</h4>
      </Button>
    </Box>
  );
}
