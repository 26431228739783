import React from "react";
import "./styles.css";

function RoadmapSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 435.861 1.333"
      className="svgRoadmap"
    >
      <defs>
        <clipPath id="clipPath768" clipPathUnits="userSpaceOnUse">
          <path d="M0 1635.819h488V0H0z"></path>
        </clipPath>
        <linearGradient
          id="linearGradient782"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(-.5 1635.82) scale(326.896)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#1c1d58" stopOpacity="1"></stop>
          <stop offset="0.25" stopColor="#ed1c24" stopOpacity="1"></stop>
          <stop offset="0.6" stopColor="#fbc707" stopOpacity="1"></stop>
          <stop offset="0.8" stopColor="#99dde3" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#00a9a0" stopOpacity="1"></stop>
        </linearGradient>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 -107.403 1267.044)">
        <g>
          <g clipPath="url(#clipPath768)">
            <g transform="translate(81.052 949.783)">
              <path
                fill="none"
                stroke="url(#linearGradient782)"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="miter"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="1"
                d="M0 0h325.896"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RoadmapSvg;
