import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import SolanaSvgIcon from "../../svg/solanaSvgIcon/index.js";
import EtherumSvgIcon from "../../svg/etherumSvgIcon";
import DollarSvgIcon from "../../svg/dollarSvgIcon";
import "./styles.css";
import { useTranslation } from "react-i18next";

export default function LabsProjectCurrentyChoice({ setCurrency }) {
  const { t } = useTranslation();

  return (
    <PopupState variant="popover" popupId="demo-popup-popover" className="op">
      {(popupState) => (
        <div className="changeCurrentyContainerButton">
          <Button
            variant="contained"
            {...bindTrigger(popupState)}
            className="changeCurrentyButton"
          >
            <p className="changeCurrentyButtonText">{t("priceCheck")}</p>
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography sx={{ p: 2 }} className="currentiesContainer">
              <i
                className="currentiesChangeLink"
                onClick={() => {
                  setCurrency("SOL");
                  popupState.close();
                }}
              >
                <SolanaSvgIcon />
              </i>
              <i
                className="currentiesChangeLink"
                onClick={() => {
                  setCurrency("ETH");
                  popupState.close();
                }}
              >
                <EtherumSvgIcon />
              </i>
              <i
                className="currentiesChangeLink"
                onClick={() => {
                  setCurrency("$");
                  popupState.close();
                }}
              >
                <DollarSvgIcon />
              </i>
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
