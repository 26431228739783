import React from "react";
import "./styles.css";

function DeltaOneCapitalLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 150 85"
      className="deltaOneCapitalLogo"
    >
      <path
        fill="url(#paint0_linear_687_38)"
        d="M.777 60.644s19.288-11.827 26.384-14.556c7.097-2.73 11.282-4.185 18.196-9.462 7.097-5.459 16.013-16.558 19.106-18.924 3.093-2.184 3.093-1.456 8.916-3.457C80.657 11.697 90.665-3.587 94.486.78c2.184 2.73 8.006 19.47 10.554 12.555 1.092-2.911 3.457-7.642 5.822-5.823 2.548 2.002 2.002 1.638 4.549 10.918 2.548 9.28 3.64 8.734 6.915 12.01 3.457 3.092.91 1.637 4.549 8.915 3.821 7.279 11.281 15.467 14.011 19.834 1.819 3.275 3.275 5.822 3.821 6.732-8.006-2.547-23.109-6.914-40.577-9.826-1.638-3.093-1.82-8.188-2.184-10.007-1.455-7.279-10.917-5.095-7.642-11.1 1.092-2.365 16.195-16.194 11.1-15.466-3.094.546-8.552 1.637-9.644 2.183-15.467 6.55 3.64-15.284-12.01-8.552-1.455.728-5.64 0-8.188 2.73-2.365 2.729-5.64-.546-8.552 2.729-2.911 3.275-1.091 4.73-5.822 8.552-4.55 3.821-4.367 4.003-8.37 6.732-3.822 2.73-4.367 6.55-7.825 7.825-3.275 1.273-9.644 4.367-11.827 4.367-2.365 0-11.463 5.458-12.555 6.55C19.7 53.912.777 60.644.777 60.644z"
      ></path>
      <path
        fill="url(#paint1_linear_687_38)"
        d="M7.146 64.83S45.72 54.457 81.02 57.004c35.118 2.548 68.963 14.739 68.963 14.739L136.883 75.2c-28.386-10.735-57.681-14.01-57.681-14.01s28.021 8.915 44.216 17.65l-22.017 5.64s-22.745-13.647-38.03-18.014C48.27 62.1 7.146 64.83 7.146 64.83z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_687_38"
          x1="0.777"
          x2="144.707"
          y1="0"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161"></stop>
          <stop offset="0.5" stopColor="#E6E7E8"></stop>
          <stop offset="1" stopColor="#616161"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_687_38"
          x1="7.146"
          x2="149.984"
          y1="56.602"
          y2="56.602"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616161"></stop>
          <stop offset="0.5" stopColor="#E6E7E8"></stop>
          <stop offset="1" stopColor="#616161"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default DeltaOneCapitalLogo;
