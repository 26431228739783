import React, { useState, useEffect } from "react";
import "./styles.css";
import CourseExemple1 from "../../images/formations/courseExemple1.png";
import CourseExemple2 from "../../images/formations/courseExemple2.png";
import CourseExemple3 from "../../images/formations/courseExemple3.png";
import CourseExemple4 from "../../images/formations/courseExemple4.png";
import Button from "@mui/material/Button";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ModalCourseExemple from "./modalCourseExemple";

function AcademyCoursesOverview() {
  const [largeur, setLargeur] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div className="academyCoursesOverviewArea" id="academyCoursesOverview">
      <ImageList
        className="academyCoursesImageList"
        cols={largeur >= 750 ? 4 : largeur >= 500 ? 2 : 1}
      >
        <ImageListItem className="academyCoursesOverviewBlock">
          <div className="academyCoursesOverviewDescription">
            <p>
              The Academy is an educational structure that combines training on
              the mastery and understanding of the markets with a complete and
              detailed presentation of the new opportunities offered by the
              Web3.
            </p>
            <ModalCourseExemple courseChoice={1} />
            {/* <Button variant="outlined" className="labsHomeButton color-11">
              <h4>ENTER</h4>
            </Button> */}
          </div>
          <img
            src={CourseExemple1}
            alt="Course Exemple 1"
            className="academyCoursesOverviewImage"
          />
        </ImageListItem>

        <ImageListItem className="academyCoursesOverviewBlock">
          <div className="academyCoursesOverviewDescription">
            <p>
              The Academy is an educational structure that combines training on
              the mastery and understanding of the markets with a complete and
              detailed presentation of the new opportunities offered by the
              Web3.
            </p>
            <ModalCourseExemple courseChoice={2} />
            {/* <Button variant="outlined" className="labsHomeButton color-11">
              <h4>ENTER</h4>
            </Button> */}
          </div>
          <img
            src={CourseExemple2}
            alt="Course Exemple 2"
            className="academyCoursesOverviewImage"
          />
        </ImageListItem>

        <ImageListItem className="academyCoursesOverviewBlock">
          <div className="academyCoursesOverviewDescription">
            <p>
              The Academy is an educational structure that combines training on
              the mastery and understanding of the markets with a complete and
              detailed presentation of the new opportunities offered by the
              Web3.
            </p>
            <ModalCourseExemple courseChoice={3} />
            {/* <Button variant="outlined" className="labsHomeButton color-11">
              <h4>ENTER</h4>
            </Button> */}
          </div>
          <img
            src={CourseExemple3}
            alt="Course Exemple 3"
            className="academyCoursesOverviewImage"
          />
        </ImageListItem>

        <ImageListItem className="academyCoursesOverviewBlock">
          <div className="academyCoursesOverviewDescription">
            <p>
              The Academy is an educational structure that combines training on
              the mastery and understanding of the markets with a complete and
              detailed presentation of the new opportunities offered by the
              Web3.
            </p>
            <ModalCourseExemple courseChoice={4} />
            {/* <Button variant="outlined" className="labsHomeButton color-11">
              <h4>ENTER</h4>
            </Button> */}
          </div>
          <img
            src={CourseExemple4}
            alt="Course Exemple 4"
            className="academyCoursesOverviewImage"
          />
        </ImageListItem>
      </ImageList>
    </div>
  );
}

export default AcademyCoursesOverview;
