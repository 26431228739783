import React from "react";
import "./styles.css";

function EtherumSvgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 784.37 1277.39"
      className="etherumSvgIcon"
    >
      <g>
        <g fillRule="nonzero">
          <path
            fill="#343434"
            d="M392.07 0L383.5 29.11 383.5 873.74 392.07 882.29 784.13 650.54z"
          ></path>
          <path
            fill="#8C8C8C"
            d="M392.07 0L0 650.54 392.07 882.29 392.07 472.33z"
          ></path>
          <path
            fill="#3C3C3B"
            d="M392.07 956.52L387.24 962.41 387.24 1263.28 392.07 1277.38 784.37 724.89z"
          ></path>
          <path
            fill="#8C8C8C"
            d="M392.07 1277.38L392.07 956.52 0 724.89z"
          ></path>
          <path
            fill="#141414"
            d="M392.07 882.29L784.13 650.54 392.07 472.33z"
          ></path>
          <path
            fill="#393939"
            d="M0 650.54L392.07 882.29 392.07 472.33z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default EtherumSvgIcon;
