import React, { useState, useEffect } from "react";
import "./styles.css";
import Button from "@mui/material/Button";
import { Link } from "react-scroll";
import AgarthaLogo from "../../svg/agarthaLogo";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import "../../../utils/translator";
import { Link as LinkBrowser } from "react-router-dom";
import WorldIcon from "../../labs/svg/worldIcon";
import SelectAutoWidth from "../../labs/labsHeader/languageSelector";
import HeaderBarBanner from "../../labs/labsCes2023/headerBarBanner";

function Header() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [largeur, setLargeur] = useState(window.innerWidth);
  const { t } = useTranslation();
  toggleMenu
    ? largeur <= 1024
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset")
    : (document.body.style.overflow = "unset");

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);

      if (window.innerWidth > 1024) {
        setToggleMenu(false);
      }
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div className="header" id="/">
      {largeur <= 1024 && (
        <div className="smallScreenTopHeader">
          <Link to="home" spy={true} smooth={true} offset={0} duration={500}>
            <AgarthaLogo />
          </Link>
          {!toggleMenu && (
            <DensityMediumIcon
              onClick={() => setToggleMenu(!toggleMenu)}
              className="burgerMenuIcon"
              sx={{ fontSize: 36.5 }}
            />
          )}
          {toggleMenu && (
            <CloseIcon
              onClick={() => setToggleMenu(!toggleMenu)}
              className="burgerMenuIcon cross"
              sx={{ fontSize: 36.5 }}
            />
          )}
        </div>
      )}

      {(toggleMenu || largeur > 1024) && (
        <div className="headerSection">
          {largeur > 1024 && (
            <div className="logoHeaderBar">
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                <AgarthaLogo />
              </Link>
            </div>
          )}

          <div className="headerLinks">
            {largeur > 1024 && (
              <div className="languageSelectorBlock">
                <WorldIcon />
                <SelectAutoWidth />
              </div>
            )}

            {largeur <= 1024 && (
              <LinkBrowser
                to="/labs/ces2023"
                className="headerBar__link headerBarButton"
              >
                <Button className="headerBarButton">
                  <h4>CES LAS VEGAS 2023</h4>
                </Button>
              </LinkBrowser>
            )}

            <Link
              to="home"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => setToggleMenu(!toggleMenu)}
            >
              <Button className="headerBarButton">
                <h4>{t("HOME")}</h4>
              </Button>
            </Link>

            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={130}
              duration={500}
              onClick={() => setToggleMenu(!toggleMenu)}
            >
              <Button className="headerBarButton">
                <h4>{t("ABOUT")}</h4>
              </Button>
            </Link>

            <Link
              to="roadmap"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => setToggleMenu(!toggleMenu)}
            >
              <Button className="headerBarButton">
                <h4>{t("ROADMAP")}</h4>
              </Button>
            </Link>

            <Link
              to="team"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => setToggleMenu(!toggleMenu)}
            >
              <Button className="headerBarButton">
                <h4>{t("TEAM")}</h4>
              </Button>
            </Link>

            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => setToggleMenu(!toggleMenu)}
            >
              <Button className="headerBarButton">
                <h4>{t("CONTACT")}</h4>
              </Button>
            </Link>
            {/*<LinkBrowser to="/labs" className="headerBar__link">
              <Button className="headerBarButton labsAccessButton">
                <h4 className="monarchFont">AGARTHA LABS</h4>
              </Button>
            </LinkBrowser>*/}

            {largeur <= 1024 && (
              <div className="languageSelectorBlock">
                <WorldIcon />
                <SelectAutoWidth />
              </div>
            )}
          </div>
        </div>
      )}

      <div
        style={{
          display: largeur >= 1025 ? "block" : !toggleMenu ? "block" : "none",
        }}
      >
        <HeaderBarBanner />
      </div>
    </div>
  );
}

export default Header;
