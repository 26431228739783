import React from "react";
import "./styles.css";

function AcademySvg2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 418 418"
      className="academySvg2"
    >
      <path
        fill="url(#paint0_linear_77_32)"
        d="M418 418H0V209A208.998 208.998 0 01249.783 4.017a209.005 209.005 0 01107.012 57.206A207.643 207.643 0 01418 209v209zM1.734 416.301H209A207.303 207.303 0 00416.301 209 207.297 207.297 0 00288.33 17.484 207.303 207.303 0 0062.42 62.42 205.97 205.97 0 001.733 209v207.301z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_77_32"
          x1="144.986"
          x2="308.125"
          y1="470.779"
          y2="22.558"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B1464"></stop>
          <stop offset="0.5" stopColor="#4495D1"></stop>
          <stop offset="1" stopColor="#E1F4FD"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AcademySvg2;
