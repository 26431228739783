import React, { useState, useEffect } from "react";
import "./styles.css";
import Button from "@mui/material/Button";
import { Link } from "react-scroll";
import { Link as LinkBrowser } from "react-router-dom";
import AgarthaLabsLogoImage from "../../images/agarthaLabsLogo.png";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import "../../../utils/translator";
import WorldIcon from "../svg/worldIcon";
import SelectAutoWidth from "./languageSelector";
import HeaderBarBanner from "../labsCes2023/headerBarBanner";
import LabsHeaderLaunchpadButton from "./labsHeaderLaunchpadButton";

function LabsHeader() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [largeur, setLargeur] = useState(window.innerWidth);
  const { t } = useTranslation();

  toggleMenu
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "unset");

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
    };

    if (window.innerWidth > 1024) {
      setToggleMenu(false);
    }

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div className="labsHeader" id="/">
      {largeur <= 1024 && (
        <div className="labsSmallScreenTopHeader">
          <Link
            to="labsHome"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <img
              src={AgarthaLabsLogoImage}
              alt="logo agartha labs"
              className="labsLogo noselect"
            />
          </Link>
          {!toggleMenu && (
            <DensityMediumIcon
              onClick={() => {
                setToggleMenu(true);
              }}
              className="burgerMenuIcon"
              sx={{ fontSize: 36.5 }}
            />
          )}
          {toggleMenu && (
            <CloseIcon
              onClick={() => {
                setToggleMenu(false);
              }}
              className="burgerMenuIcon cross"
              sx={{ fontSize: 36.5 }}
            />
          )}
        </div>
      )}

      {(toggleMenu || largeur > 1024) && (
        <div className="labsHeaderSection">
          {largeur > 1024 && (
            <div className="logoHeaderBar noselect">
              <Link
                to="labsHome"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                <img
                  src={AgarthaLabsLogoImage}
                  alt="logo agartha labs"
                  className="labsLogo noselect "
                />
              </Link>
            </div>
          )}

          <div className="labsHeaderLinks">
            <LinkBrowser to="../" className="labsHeaderBar__link">
              <Button className="headerBar__button agarthaAccessButton">
                <h4 className="monarchFont">Agartha</h4>
              </Button>
            </LinkBrowser>
            {largeur <= 1024 && (
              <LinkBrowser to="/labs/ces2023" className="labsHeaderBar__link">
                <Button className="headerBarButton">
                  <h4>CES LAS VEGAS 2023</h4>
                </Button>
              </LinkBrowser>
            )}
            <Link
              to="labsHome"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={() => {
                largeur <= 1024 && setToggleMenu(!toggleMenu);
              }}
              className="labsHeaderBar__link"
            >
              <Button className="headerBar__button">
                <h4>{t("HOME")}</h4>
              </Button>
            </Link>
            <Link
              to="labsServices"
              spy={true}
              smooth={true}
              offset={-20}
              duration={500}
              onClick={() => {
                largeur <= 1024 && setToggleMenu(!toggleMenu);
              }}
              className="labsHeaderBar__link"
            >
              <Button className="headerBar__button">
                <h4>{t("SERVICES")}</h4>
              </Button>
            </Link>
            <Link
              to="labsAbout"
              spy={true}
              smooth={true}
              offset={-60}
              duration={500}
              onClick={() => {
                largeur <= 1024 && setToggleMenu(!toggleMenu);
              }}
              className="labsHeaderBar__link"
            >
              <Button className="headerBar__button">
                <h4>{t("ABOUT")}</h4>
              </Button>
            </Link>
            <div className="launchpadAspectContainer">
              <LabsHeaderLaunchpadButton
                toggleMenu={toggleMenu}
                setToggleMenu={setToggleMenu}
              />
            </div>
          </div>

          <div className="labsHeaderRightBlock">
            <div className="labsLanguageSelectorBlock">
              <WorldIcon />
              <SelectAutoWidth />
            </div>
            <LinkBrowser to="/labs/projectAgartha" className="enterButton">
              <h4>{t("enter")}</h4>
            </LinkBrowser>
          </div>
        </div>
      )}

      <div
        style={{
          display: largeur >= 1025 ? "block" : !toggleMenu ? "block" : "none",
        }}
        className="labsBannerces2023"
      >
        <HeaderBarBanner />
      </div>
    </div>
  );
}

export default LabsHeader;
