import React from "react";
import "./styles.css";
import AcademySvg1 from "../academySvg/academySvg1";
import Button from "@mui/material/Button";
import { Link as LinkBrowser } from "react-router-dom";

function AcademyHome() {
  return (
    <div className="academyHomeArea" id="academyHome">
      <div className="academyHomeContentContainer">
        <h1>Transform your future with Agartha Academy</h1>
        <p>
          The Academy is an educational structure that combines training on the
          mastery and understanding of the markets with a complete and detailed
          presentation of the new opportunities offered by the Web3.
        </p>
        <LinkBrowser to="#">
          <Button variant="outlined" className="agarthaAcademyButton color-11">
            <h4>ENTER</h4>
          </Button>
        </LinkBrowser>
      </div>

      <div className="svgContainer12">
        <AcademySvg1 />
      </div>
    </div>
  );
}

export default AcademyHome;
