import React from "react";
import "./styles.css";

function AgarthaLabsLightLogo2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 497.645 147.74"
      className="agarthaLabsLightLogo"
    >
      <g
        fill="#fff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1.951"
        transform="matrix(1.33333 0 0 -1.33333 -4989.98 3795.181)"
      >
        <path d="M3754.704 2746.238v84.552c0 6.107-4.503 10.124-12.219 10.124l.484 2.094h37.61l-.64-2.094s-12.375 0-12.375-10.124v-91.141h17.362c33.592 0 43.56 45.808 43.56 45.808h2.249l-8.357-49.828h-2.572s-1.288 1.126-18.651 1.126h-58.186l.642 2.893s11.093 0 11.093 6.59"></path>
        <path d="M3982.583 2739.49h-18.488v53.042h18.004c13.18 0 26.203-6.747 26.203-26.525 0-19.927-15.916-26.516-25.72-26.516m-18.487 101.103h15.432c4.824 0 23.467-2.245 23.467-22.985 0-20.737-16.236-22.661-23.63-22.661h-15.269zm-109.845-62.204l20.573 59.795 1.932-.962 20.576-58.833zm135.722 15.268v.804s27.007-.962 27.007 23.147c0 25.399-28.93 25.399-35.686 25.399h-40.829l-.478-2.414h.32c5.465 0 10.767-2.571 10.767-9.804v-84.232c-.025-6.773-11.124-7.02-13.99-7.058-2.492-.03-12.648 1.126-15.963 10.435l-31.982 93.073h-24.601l8.043-3.698-30.707-89.21c-3.055-9.006-11.248-10.61-11.248-10.61l-.648-2.735h41.313l.486 2.736s-30.386.962-27.329 10.444l9.005 26.04h44.684l9.161-25.876c3.534-10.45-25.719-10.608-25.719-10.608l-.483-2.736h111.29c13.181 0 30.865 8.52 30.865 29.253 0 26.844-33.278 27.65-33.278 27.65"></path>
        <path d="M4079.079 2735.63c-13.665.483-20.092 5.143-27.487 5.143-4.66 0-6.433-2.408-6.433-5.144h-3.534l-8.677 47.257h3.05s16.08-44.52 48.388-44.52c19.608 0 39.705 31.34-1.126 43.878l-26.687 8.843c-32.788 10.928-25.235 54.173 13.985 54.173 8.52 0 15.432-2.894 15.432-2.894 8.84-3.375 7.559 4.02 7.559 4.02h3.05l8.042-40.353h-2.251s-18.652 35.208-40.51 35.208c-19.771 0-32.31-27.649 8.678-40.351l24.915-8.194c35.043-12.054 21.379-58.834-16.394-57.067"></path>
      </g>
    </svg>
  );
}

export default AgarthaLabsLightLogo2;
