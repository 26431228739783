import React from "react";
import "./styles.css";

function LabsBackgroundRune9() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 861 644"
      className="labsSvgRune9"
    >
      <g clipPath="url(#clip0_659_36)">
        <path
          fill="url(#paint0_linear_659_36)"
          d="M901.626 585.936v-693.838H37.773A60.902 60.902 0 00-5.2-90.175 60.398 60.398 0 00-23-47.378 60.398 60.398 0 00-5.2-4.582a60.9 60.9 0 0042.973 17.727H728.83a56.153 56.153 0 0139.549 16.346 55.69 55.69 0 0116.413 39.386v517.134a58.055 58.055 0 0017.11 41.138 58.54 58.54 0 0041.307 17.04 58.54 58.54 0 0041.307-17.04 58.06 58.06 0 0017.11-41.138v-.075zM899.12-37.17v622.208a55.282 55.282 0 01-4.355 21.518 55.459 55.459 0 01-12.394 18.152 55.718 55.718 0 01-18.493 11.945 55.915 55.915 0 01-21.697 3.867c-30.399-.524-52.628-24.085-52.628-54.484V68.802c0-32.12-28.619-58.177-60.873-58.177H38.75A58.53 58.53 0 0116.396 6.39 58.341 58.341 0 01-2.629-6.042a58.07 58.07 0 01-12.8-18.736 57.874 57.874 0 01-.374-44.45A58.058 58.058 0 01-3.32-88.177a58.336 58.336 0 0118.813-12.748 58.54 58.54 0 0122.28-4.607h792.755c18.205 0 35.665 7.203 48.537 20.023A68.218 68.218 0 01899.17-37.17h-.05z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_659_36"
          x1="718.781"
          x2="375.391"
          y1="-213.126"
          y2="384.099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B1464"></stop>
          <stop offset="0.5" stopColor="#009974"></stop>
          <stop offset="1" stopColor="#CFE5AE"></stop>
        </linearGradient>
        <clipPath id="clip0_659_36">
          <path
            fill="#fff"
            d="M0 0H644V861H0z"
            transform="matrix(0 1 1 0 0 0)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LabsBackgroundRune9;
