import React from "react";
import "./styles.css";

function ResearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 132 132"
      className="researchIcon"
    >
      <path
        fill="#fff"
        d="M46.664 24.948c-6.372.248-12.126 2.521-15.204 5.6a3.66 3.66 0 11-5.178-5.176c4.75-4.75 12.479-7.442 20.097-7.739 7.669-.298 16.04 1.801 21.904 7.666a3.66 3.66 0 01-5.178 5.176c-3.993-3.995-10.12-5.773-16.441-5.527z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M78.277 83.956a47.396 47.396 0 01-30.69 11.218C21.305 95.174 0 73.868 0 47.587 0 21.305 21.305 0 47.587 0c26.281 0 47.587 21.305 47.587 47.587a47.396 47.396 0 01-11.218 30.69l8.085-1.406 37.643 37.644a7.15 7.15 0 010 10.112l-5.057 5.057a7.15 7.15 0 01-10.112 0L76.871 92.041l1.406-8.085zm9.575-36.37c0 22.239-18.027 40.266-40.265 40.266S7.32 69.825 7.32 47.587 25.349 7.32 47.587 7.32s40.265 18.028 40.265 40.266zm26.534 61.984L89.551 84.735l-4.103.713-.713 4.103 24.908 24.909 4.743-4.89zm.435 10.067l4.75 4.75 4.816-4.816-4.823-4.823-4.743 4.889z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ResearchIcon;
