import React from "react";

function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 35 35"
      cursor="pointer"
    >
      <path
        fill="#fff"
        d="M24.57 12.107a3.016 3.016 0 00-1.729-1.728 5.036 5.036 0 00-1.689-.313c-.96-.043-1.247-.054-3.677-.054-2.43 0-2.718.01-3.677.054a5.034 5.034 0 00-1.691.313 3.017 3.017 0 00-1.728 1.73 5.039 5.039 0 00-.313 1.689c-.043.96-.054 1.247-.054 3.677 0 2.43.01 2.718.054 3.677.007.578.113 1.15.313 1.691a3.017 3.017 0 001.73 1.728c.54.2 1.112.306 1.689.313.96.043 1.247.052 3.677.052 2.43 0 2.718-.009 3.677-.054a5.036 5.036 0 001.691-.311 3.016 3.016 0 001.728-1.73c.2-.54.306-1.112.313-1.689.043-.96.052-1.247.052-3.677 0-2.43-.009-2.718-.054-3.677a5.038 5.038 0 00-.311-1.691zM17.476 22.15a4.675 4.675 0 110-9.35 4.675 4.675 0 010 9.35zm4.858-8.441a1.092 1.092 0 110-2.185 1.092 1.092 0 010 2.185zm-1.823 3.766a3.035 3.035 0 11-6.07 0 3.035 3.035 0 016.07 0zM17.474 0a17.475 17.475 0 100 34.95 17.475 17.475 0 000-34.95zm9.047 21.227a6.683 6.683 0 01-.424 2.21 4.652 4.652 0 01-2.662 2.66 6.68 6.68 0 01-2.207.425c-.973.044-1.282.055-3.754.055s-2.781-.011-3.754-.055a6.68 6.68 0 01-2.207-.424 4.653 4.653 0 01-2.662-2.662 6.68 6.68 0 01-.424-2.207c-.044-.973-.055-1.282-.055-3.754s.011-2.781.055-3.754a6.68 6.68 0 01.422-2.207 4.653 4.653 0 012.664-2.664 6.68 6.68 0 012.208-.422c.972-.044 1.281-.055 3.753-.055 2.472 0 2.781.011 3.753.055a6.68 6.68 0 012.208.422 4.653 4.653 0 012.662 2.664c.265.706.408 1.453.424 2.208.044.972.055 1.281.055 3.753 0 2.472-.011 2.781-.055 3.753v-.001z"
      ></path>
    </svg>
  );
}

export default InstagramIcon;
