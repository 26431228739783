import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./styles.css";
import { useTranslation } from "react-i18next";
import "../../../../utils/translator/index.js";
import CloseIcon from "@mui/icons-material/Close";

export default function BasicModal({ legal }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    legal === "privacy_policy" && setOpen(true);
  }, [legal]);

  return (
    <div className="privacyBoxContainer">
      <Button
        variant="text"
        onClick={() => setOpen(true)}
        style={{ textTransform: "none" }}
      >
        {t("privacyPolicyLink")}
      </Button>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          legal === "privacy_policy" && navigate("/");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="privacyBox sc2">
          <CloseIcon
            onClick={() => {
              setOpen(false);
              legal === "privacy_policy" && navigate("/");
            }}
            className="memberModalCloseIcon"
          />
          <div id="modal-modal-title">
            <h2>{t("privacyPolicyTitle")}</h2>
          </div>
          <div id="modal-modal-description">
            <h3>{t("privacyPolicyDataTitle")}</h3>
            <p>{t("privacyPolicyText")}</p>

            <h3>{t("privacyPolicyDataTitle_2")}</h3>
            <p>{t("privacyPolicyDataText")}</p>

            <h3>{t("privacyPolicyRightsTitle")}</h3>
            <p>{t("privacyPolicyRightsText")}</p>

            <h3>{t("privacyPolicyWebLinksTitle")}</h3>
            <p>{t("privacyPolicyWebLinksText")}</p>

            <h3>{t("privacyPolicySecurityTitle")}</h3>
            <p>{t("privacyPolicySecurityText")}</p>
            <ul>
              <li>{t("privacyPolicySecurityText_1")}</li>
              <li>{t("privacyPolicySecurityText_2")}</li> 
              <li>{t("privacyPolicySecurityText_3")}</li>
              <li>{t("privacyPolicySecurityText_4")}</li>
              <li>{t("privacyPolicySecurityText_5")}</li>
              <li>{t("privacyPolicySecurityText_6")}</li>
              <li>{t("privacyPolicySecurityText_7")}</li>
            </ul>
            <br /><p>{t("privacyPolicySecurityText_8")}</p>
            <ul>
              <li>{t("privacyPolicySecurityText_9")}</li>
              <li>{t("privacyPolicySecurityText_10")}</li>
              <li>{t("privacyPolicySecurityText_11")}</li>
              <li>{t("privacyPolicySecurityText_12")}</li>
              <li>{t("privacyPolicySecurityText_13")}</li>
            </ul>
            

            <h3>{t("privacyPolicyDivulgationTitle")}</h3>
            <p>{t("privacyPolicyDivulgationText")}</p>
            <ul>
              <li>{t("privacyPolicyDivulgationText_1")}</li>
              <li>{t("privacyPolicyDivulgationText_2")}</li>
            </ul>
            <br /><p>{t("privacyPolicyDivulgationText_3")}</p>
            <ul>
              <li>{t("privacyPolicyDivulgationText_4")}</li>
              <li>{t("privacyPolicyDivulgationText_5")}</li>
              <li>{t("privacyPolicyDivulgationText_6")}</li>
              <li>Twitter</li>
              <li>Discord</li>
              <li>{t("privacyPolicyDivulgationText_7")}</li>
            </ul>
            

            <h3>{t("privacyPolicyContactTitle")}</h3>
            <p>{t("privacyPolicyContactText")}</p>

            <h3>{t("privacyPolicyContactTitle_2")}</h3>
            <p>{t("privacyPolicyContactText_2")}</p>

            <h3>{t("privacyPolicyContactTitle_3")}</h3>
            <p>{t("privacyPolicyContactText_3")}</p>

            <h3>{t("privacyPolicyContactTitle_4")}</h3>
            <p>{t("privacyPolicyContactText_4")}</p>

            <h3>{t("privacyPolicyContactTitle_5")}</h3>
            <p>{t("privacyPolicyContactText_5")}
            </p>

            <h3>{t("privacyPolicyContactTitle_6")}</h3>
            <p>{t("privacyPolicyContactText_6")}</p> 
            <ul>
              <li>{t("privacyPolicyContactText_6_2")}</li> 
              <li>{t("privacyPolicyContactText_6_3")}</li> 
              <li>{t("privacyPolicyContactText_6_4")}</li>
              <li>{t("privacyPolicyContactText_6_5")}</li>
            </ul>  
            <br /><p>{t("privacyPolicyContactText_6_6")}</p>
            

            <h3>{t("privacyPolicyContactTitle_7")}</h3>
            <p>{t("privacyPolicyContactText_7")}</p>

            <h3>{t("privacyPolicyContactTitle_8")}</h3>
            <p>{t("privacyPolicyContactText_8")}</p>

            <h3>{t("privacyPolicyContactTitle_9")}</h3>
            <p>{t("privacyPolicyContactText_9")}</p>

            <h3>{t("privacyPolicyContactTitle_10")}</h3>
            <p>{t("privacyPolicyContactText_10")}</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
