import React from "react";
import "./styles.css";

function LabsBackgroundRune4() {
  return (
    <div className="svgContainer4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 623 131"
        className="labsSvgRune4"
      >
        <g clipPath="url(#clip0_248_24)">
          <path
            fill="url(#paint0_linear_248_24)"
            d="M558.073 130.937h-771.781v-969.175c0-18.083 7.093-35.426 19.719-48.212 12.625-12.787 29.749-19.971 47.604-19.971s34.979 7.184 47.604 19.971c12.626 12.786 19.719 30.129 19.719 48.212v775.312c.022 16.645 6.56 32.601 18.18 44.37C-49.26-6.785-33.504-.163-17.07-.141h575.226c17.163 0 33.623 6.905 45.759 19.196 12.136 12.291 18.954 28.962 18.954 46.344s-6.818 34.052-18.954 46.343c-12.136 12.291-28.596 19.196-45.759 19.196h-.083zm-693.103-2.811h692.103a61.062 61.062 0 0023.936-4.886 61.705 61.705 0 0020.191-13.905 62.59 62.59 0 0013.286-20.748 63.22 63.22 0 004.302-24.342C618.205 30.14 591.997 5.2 558.184 5.2H-17.153c-35.73 0-64.714-32.109-64.714-68.295v-774.047a66.16 66.16 0 00-4.71-25.079 65.505 65.505 0 00-13.828-21.345 64.59 64.59 0 00-20.841-14.361 63.916 63.916 0 00-24.681-5.19 63.878 63.878 0 00-24.763 4.771 64.523 64.523 0 00-21.076 14.005 65.475 65.475 0 00-14.18 21.107 66.147 66.147 0 00-5.124 24.996V51.171c0 20.424 8.011 40.012 22.271 54.455 14.261 14.442 33.602 22.556 53.769 22.556v-.056z"
          ></path>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_248_24"
            x1="-330.752"
            x2="336.382"
            y1="-74.201"
            y2="-454.509"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1B1464"></stop>
            <stop offset="0.5" stopColor="#009974"></stop>
            <stop offset="1" stopColor="#CFE5AE"></stop>
          </linearGradient>
          <clipPath id="clip0_248_24">
            <path fill="#fff" d="M0 0H623V131H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default LabsBackgroundRune4;
