import React from "react";
import BackgroundRune3 from "../../svg/backgroundRune5";
import "./styles.css";
import CalendarModal from "./calendarModal";
import { useTranslation } from "react-i18next";
import "../../../utils/translator/index.js";

function InterestWeb3() {
  const { t } = useTranslation();

  return (
    <div className="interestWeb3Area">
      <div className="svgContainer11">
        <BackgroundRune3 />
      </div>

      <div className="interestWeb3Informations">
        <h3>{t("interestWeb3Title")}</h3>
        <p>{t("interestWeb3Text1")}</p>
        <CalendarModal />
      </div>
    </div>
  );
}

export default InterestWeb3;
