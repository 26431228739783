import React, { useRef } from "react";
import "./styles.css";
import TwitterIcon from "../svg/twitterIcon";
import InstagramIcon from "../svg/instagramIcon";
// import DiscordIcon from "../svg/discordIcon";
// import MediumIcon from "../svg/mediumIcon";
import LabsBackgroundRune5 from "../svg/labsBackgroundRune5";
import AgarthaLabsLogoImage from "../../images/agarthaLabsLogo.png";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import "../../../utils/translator";
import LinkedinIcon from "../svg/linkedinLogo";

const LabsFooter = () => {
  const form = useRef();
  const { t } = useTranslation();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ly0zhgr",
        "template_i7o6sko",
        form.current,
        "hVxXp-YHJKp8kphiS"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Your message has been send.");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="labsFooterArea">
      <div className="svgContainer5">
        <LabsBackgroundRune5 />
      </div>

      <div className="joinFormContainer">
        <div className="leftBlockForm">
          <img
            src={AgarthaLabsLogoImage}
            alt="logo agartha labs"
            className="labsLogo "
          />
          <h2>{t("labsFooterTitle")}</h2>
        </div>

        <div className="rightBlockForm">
          <form ref={form} onSubmit={sendEmail}>
            <div className="infoForm">
              <div>
                <label className="oneLine">
                  <h3 className="redPoint">*&ensp;</h3>
                  <h3>{t("labsFooterFormName")}</h3>
                </label>
                <input type="text" name="name" className="textField" required />
              </div>

              <div>
                <label className="oneLine">
                  <h3 className="redPoint">*&ensp;</h3>
                  <h3>{t("labsFooterFormMail")}</h3>
                </label>
                <input
                  type="email"
                  name="email"
                  className="textField"
                  required
                />
              </div>
            </div>

            <div className="toSentForm">
              <label className="oneLine">
                <h3 className="redPoint">*&ensp;</h3>
                <h3>{t("labsFooterFormMessage")}</h3>
              </label>
              <textarea name="message" rows="4" required />
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                type="submit"
                value="send"
                className="labsContactFormSendButton"
              >
                {t("sendButton")}
              </Button>
              <p>{t("labsFooterMention")}</p>
            </div>
          </form>
        </div>
      </div>

      <div className="labsLogosArea">
        <a href="https://mobile.twitter.com/agartha_ch/" target="blank">
          <TwitterIcon />
        </a>
        <a href="https://www.instagram.com/agartha.ch/" target="blank">
          <InstagramIcon />
        </a>
        {/* <a href="https://discord.gg/PhxNfRS7pV" target="blank"><DiscordIcon /></a> */}
        {/* <MediumIcon /> */}
        <a href="https://www.linkedin.com/company/agartha-ch/" target="blank">
          <LinkedinIcon />
        </a>
      </div>
    </div>
  );
};

export default LabsFooter;
