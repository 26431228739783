import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import englishDoc from "./english.json";
import frenchDoc from "./french.json";
import "./styles.css";

i18n
  // Language detector use local storage
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        ...englishDoc,
      },
      fr: {
        ...frenchDoc,
      },
    },

    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: true,
      prefix: "{{",
      suffix: "}}",
      useRawValueToEscape: true,
    },
    detection: {
      order: [
        "htmlTag",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "path",
        "subdomain",
      ],
      // To reactivate when we will need cookies for other parts of the site
      // caches: ["cookie"],
    },
  });

export default i18n;
