import React from "react";
import "./styles.css";

function AcademySvg1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 639 556"
      className="academySvg1"
    >
      <path
        fill="url(#paint0_linear_77_47)"
        d="M358.043 556H0V398.115a139.84 139.84 0 0140.895-98.829 139.578 139.578 0 0198.734-40.944h214.207V139.773a139.873 139.873 0 0110.624-53.487 139.765 139.765 0 0130.267-45.346 139.618 139.618 0 0145.301-30.3A139.513 139.513 0 01493.465 0H639v260.043H358.043V556zM1.717 554.299h212.49a139.562 139.562 0 0098.733-40.934 139.822 139.822 0 0040.896-98.822v-154.5H141.311a139.56 139.56 0 00-98.71 40.946 139.819 139.819 0 00-40.884 98.81v154.5zm356.326-295.957h279.275v-116.85a139.838 139.838 0 00-40.896-98.829 139.581 139.581 0 00-98.733-40.945 139.581 139.581 0 00-98.739 40.933 139.835 139.835 0 00-40.907 98.823v116.868z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_77_47"
          x1="205.28"
          x2="458.97"
          y1="630.784"
          y2="-65.58"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B1464"></stop>
          <stop offset="0.5" stopColor="#4495D1"></stop>
          <stop offset="1" stopColor="#E1F4FD"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AcademySvg1;
