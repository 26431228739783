import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./styles.css";
import RoadmapVerticalSvg from "../../../svg/roadmapDesign/roadmapVerticalSvg";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

export default function RoadmapDetailsVertical() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  return (
    <div className="agarthaButtonRoadmap">
      <Button onClick={handleOpen} className="agarthaButtonRoadmap">
        <h2 className="monarchFont">AGARTHA</h2>
        <div className="roundGreen"></div>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="roadmapDetailedModalVertical">
          <CloseIcon onClick={handleClose} className="memberModalCloseIcon" />
          <div className="roadmapDetailedContainerVertical">
            <div className="leftContainerDetailsVertical">
              <div className="whiteHorizontalSeparationBar littleVerticalSize"></div>

              <p className="agarthaRoadmapDetailsVertical">
                <span className="roadmapTitleFocus">
                  {t("roadmapAgarthaDetailText1-1")}
                </span>
                {t("roadmapAgarthaDetailText1-2")}
              </p>

              <div className="whiteHorizontalSeparationBar littleVerticalSize"></div>

              <p className="agarthaRoadmapDetailsVertical">
                <span className="roadmapTitleFocus">
                  {t("roadmapAgarthaDetailText2-1")}
                </span>
                {t("roadmapAgarthaDetailText2-2")}
              </p>

              <div className="whiteHorizontalSeparationBar littleVerticalSize"></div>

              <p className="agarthaRoadmapDetailsVertical">
                <span className="roadmapTitleFocus">
                  {t("roadmapAgarthaDetailText3-1")}
                </span>
                {t("roadmapAgarthaDetailText3-2")}
              </p>

              <div className="whiteHorizontalSeparationBar littleVerticalSize"></div>

              <p className="agarthaRoadmapDetailsVertical">
                <span className="roadmapTitleFocus">
                  {t("roadmapAgarthaDetailText4-1")}
                </span>
                {t("roadmapAgarthaDetailText4-2")}
              </p>

              <div className="whiteHorizontalSeparationBar littleVerticalSize"></div>
            </div>

            <RoadmapVerticalSvg />

            <div className="rightContainerDetailsVertical">
              <div className="whiteHorizontalSeparationBar littleVerticalSize"></div>

              <p className="agarthaRoadmapDetailsVertical">
                <span className="roadmapTitleFocus">
                  {t("roadmapAgarthaDetailText5-1")}
                </span>
                {t("roadmapAgarthaDetailText5-2")}
              </p>

              <div className="whiteHorizontalSeparationBar littleVerticalSize"></div>

              <p className="agarthaRoadmapDetailsVertical">
                <span className="roadmapTitleFocus">
                  {t("roadmapAgarthaDetailText6-1")}
                </span>
                {t("roadmapAgarthaDetailText6-2")}
              </p>

              <div className="whiteHorizontalSeparationBar littleVerticalSize"></div>

              <p className="agarthaRoadmapDetailsVertical">
                <span className="roadmapTitleFocus">
                  {t("roadmapAgarthaDetailText7-1")}
                </span>
                {t("roadmapAgarthaDetailText7-2")}
              </p>

              <div className="whiteHorizontalSeparationBar littleVerticalSize"></div>

              <p className="agarthaRoadmapDetailsVertical">
                <span className="roadmapTitleFocus">
                  {t("roadmapAgarthaDetailText8-1")}
                </span>
                {t("roadmapAgarthaDetailText8-2")}
              </p>

              <div className="whiteHorizontalSeparationBar littleVerticalSize"></div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
