import React from "react";
import "./styles.css";

function BackRune2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 602.275 263.376"
      className="svgRune2"
    >
      <defs>
        <clipPath id="clipPath600" clipPathUnits="userSpaceOnUse">
          <path d="M36.294 1220.444v-55.492h69.632l-35.491-55.332-.257-.4h61.073v-8.733c0-16.452 13.385-29.837 29.837-29.837H488v.519H161.866c-16.166 0-29.318 13.152-29.318 29.318v8.733H488v.519H132.548v30.4c0 13.823-11.907 25.095-25.675 25.328l16.171 25.21a55.03 55.03 0 018.726 29.767c0 26.323-21.415 47.738-47.738 47.738-26.322 0-47.738-21.415-47.738-47.738m.52-.408c0 25.958 21.236 47.615 47.195 47.627 26.048.013 47.242-21.174 47.242-47.219a54.507 54.507 0 00-8.644-29.487l-16.348-25.487h-14.88c-30.136 0-54.565 24.43-54.565 54.566m69.729-55.084c13.634-.056 24.708-11.166 24.708-24.813v-30.4H71.128z"></path>
        </clipPath>
        <linearGradient
          id="linearGradient616"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(182.42977 -182.42977) rotate(-65 -3.837 -3.815)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#1c1d58" stopOpacity="1"></stop>
          <stop offset="0.25" stopColor="#ed1c24" stopOpacity="1"></stop>
          <stop offset="0.6" stopColor="#fbc707" stopOpacity="1"></stop>
          <stop offset="0.8" stopColor="#99dde3" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#00a9a0" stopOpacity="1"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient8136"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(182.42977 -182.42977) rotate(-65 -3.837 -3.815)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
          xlinkHref="#linearGradient616"
        ></linearGradient>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 -48.392 1690.91)">
        <g>
          <g clipPath="url(#clipPath600)">
            <g>
              <g>
                <path
                  fill="url(#linearGradient8136)"
                  stroke="none"
                  d="M36.294 1220.444v-55.492h69.632l-35.491-55.332-.257-.4h61.073v-8.733c0-16.452 13.385-29.837 29.837-29.837H488v.519H161.866c-16.166 0-29.318 13.152-29.318 29.318v8.733H488v.519H132.548v30.4c0 13.823-11.907 25.095-25.675 25.328l16.171 25.21a55.03 55.03 0 018.726 29.767c0 26.323-21.415 47.738-47.738 47.738-26.322 0-47.738-21.415-47.738-47.738m.52-.408c0 25.958 21.236 47.615 47.195 47.627 26.048.013 47.242-21.174 47.242-47.219a54.507 54.507 0 00-8.644-29.487l-16.348-25.487h-14.88c-30.136 0-54.565 24.43-54.565 54.566m69.729-55.084c13.634-.056 24.708-11.166 24.708-24.813v-30.4H71.128z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BackRune2;
