import React from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import "./styles.css";

export default function Course3(props) {
  return (
    <Box className="courseModalDetails courseModalDetails--white-mod sc2">
      <CloseIcon onClick={props.handleClose} className="memberModalCloseIcon" />

      <h2>Formation Web3 de base</h2>

      <div className="whitemodkoe">
        <Accordion
          expanded={props.expanded === "panel1"}
          onChange={props.handleChange("panel1")}
          className="courseOverviewAccordions whitemod"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="whitemodaccordeon"
          >
            <h3 className="titremodwhite">Blockchains</h3>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="whitemodlist">
              <li>
                <h4>Introduction et définitions</h4>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Earum doloribus rerum quas omnis atque repellat tempore! Neque
                  quaerat hic soluta rem nam rerum laboriosam itaque quia,
                  perferendis, odio numquam eligendi.
                </p>
              </li>
              <li>
                <h4>Consensus</h4>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Earum doloribus rerum quas omnis atque repellat tempore! Neque
                  quaerat hic soluta rem nam rerum laboriosam itaque quia,
                  perferendis, odio numquam eligendi.
                </p>
              </li>
              <li>
                <h4>Layers</h4>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Earum doloribus rerum quas omnis atque repellat tempore! Neque
                  quaerat hic soluta rem nam rerum laboriosam itaque quia,
                  perferendis, odio numquam eligendi.
                </p>
              </li>
              <li>
                <h4>Tokens</h4>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Earum doloribus rerum quas omnis atque repellat tempore! Neque
                  quaerat hic soluta rem nam rerum laboriosam itaque quia,
                  perferendis, odio numquam eligendi.
                </p>
              </li>
              <li>
                <h4>Tokenisation</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Labore magni expedita itaque omnis quae. Quam, modi,
                  perspiciatis, cupiditate rem voluptas facilis impedit sunt aut
                  sapiente a error laboriosam provident est.
                </p>
              </li>
              <li>
                <h4>Tokenomics</h4>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Earum doloribus rerum quas omnis atque repellat tempore! Neque
                  quaerat hic soluta rem nam rerum laboriosam itaque quia,
                  perferendis, odio numquam eligendi.
                </p>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={props.expanded === "panel2"}
          onChange={props.handleChange("panel2")}
          className="courseOverviewAccordions whitemod"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="whitemodaccordeon"
          >
            <h3 className="titremodwhite">Spot Cryptos</h3>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="whitemodlist">
              <li>
                <h4>Comment acheter</h4>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Repellat eligendi at fuga, blanditiis obcaecati suscipit,
                  ipsum rerum, aliquam voluptate reprehenderit et quae velit sit
                  ea cumque veniam aut commodi assumenda.
                </p>
              </li>
              <li>
                <h4>CEXs</h4>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Repellat eligendi at fuga, blanditiis obcaecati suscipit,
                  ipsum rerum, aliquam voluptate reprehenderit et quae velit sit
                  ea cumque veniam aut commodi assumenda.
                </p>
              </li>
              <li>
                <h4>Trades</h4>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Repellat eligendi at fuga, blanditiis obcaecati suscipit,
                  ipsum rerum, aliquam voluptate reprehenderit et quae velit sit
                  ea cumque veniam aut commodi assumenda.
                </p>
              </li>
              <li>
                <h4>Arbitrages</h4>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Repellat eligendi at fuga, blanditiis obcaecati suscipit,
                  ipsum rerum, aliquam voluptate reprehenderit et quae velit sit
                  ea cumque veniam aut commodi assumenda.
                </p>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={props.expanded === "panel3"}
          onChange={props.handleChange("panel3")}
          className="courseOverviewAccordions whitemod"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="whitemodaccordeon"
          >
            <h3 className="titremodwhite">DEFI infrastructure</h3>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="whitemodlist">
              <li>
                <h4>Smart Contrats</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maiores sed perspiciatis earum labore aperiam voluptatibus
                  vero, ad ipsa aspernatur quia molestiae inventore tempora
                  quisquam consectetur possimus est qui eos provident!
                </p>
              </li>
              <li>
                <h4>RPC</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maiores sed perspiciatis earum labore aperiam voluptatibus
                  vero, ad ipsa aspernatur quia molestiae inventore tempora
                  quisquam consectetur possimus est qui eos provident!
                </p>
              </li>
              <li>
                <h4>Gas</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maiores sed perspiciatis earum labore aperiam voluptatibus
                  vero, ad ipsa aspernatur quia molestiae inventore tempora
                  quisquam consectetur possimus est qui eos provident!
                </p>
              </li>
              <li>
                <h4>Wallets</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maiores sed perspiciatis earum labore aperiam voluptatibus
                  vero, ad ipsa aspernatur quia molestiae inventore tempora
                  quisquam consectetur possimus est qui eos provident!
                </p>
              </li>
              <li>
                <h4>Bridges</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maiores sed perspiciatis earum labore aperiam voluptatibus
                  vero, ad ipsa aspernatur quia molestiae inventore tempora
                  quisquam consectetur possimus est qui eos provident!
                </p>
              </li>
              <li>
                <h4>MEV</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maiores sed perspiciatis earum labore aperiam voluptatibus
                  vero, ad ipsa aspernatur quia molestiae inventore tempora
                  quisquam consectetur possimus est qui eos provident!
                </p>
              </li>
              <li>
                <h4>Oracles</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maiores sed perspiciatis earum labore aperiam voluptatibus
                  vero, ad ipsa aspernatur quia molestiae inventore tempora
                  quisquam consectetur possimus est qui eos provident!
                </p>
              </li>
              <li>
                <h4>Condifentialités</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maiores sed perspiciatis earum labore aperiam voluptatibus
                  vero, ad ipsa aspernatur quia molestiae inventore tempora
                  quisquam consectetur possimus est qui eos provident!
                </p>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>

      <Button
        variant="outlined"
        className="modalEnterCourseButton whitemodbutton"
      >
        <h4>ENTER</h4>
      </Button>
    </Box>
  );
}
