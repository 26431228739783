import React from "react";
import "./styles.css";

function BackgroundRune3() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 535.545 286.519"
      className="svgRune3"
    >
      <defs>
        <clipPath id="clipPath652" clipPathUnits="userSpaceOnUse">
          <path d="M0 831.945v-.833h221.891l22.444-38.309a97.268 97.268 0 01-19.835-20.83l-51.702-73.577c-6.687-9.516.12-22.618 11.751-22.618h104.252c11.096 0 18 12.046 12.391 21.62l-55.769 95.193c16.67 12.779 37.294 19.967 58.8 19.967 25.803 0 49.231-9.633 67.477-27.88 18.247-18.245 28.879-42.921 28.879-68.725v-1.46c0-25.804-9.632-49.648-27.879-67.894-18.246-18.246-42.674-27.463-68.477-27.463H0v-2.08h304.223c26.026 0 50.495 10.135 68.898 28.538s28.538 42.873 28.538 68.899v1.46c0 26.026-10.135 50.495-28.538 68.898-18.404 18.404-42.872 28.539-68.898 28.539-21.655 0-42.426-7.228-59.224-20.075l-22.632 38.63zm174.399-132.717l50.782 72.266a96.36 96.36 0 0019.578 20.584l54.981-93.847c5.609-9.574-1.295-21.622-12.391-21.622H186.15c-11.63 0-18.437 13.103-11.751 22.619"></path>
        </clipPath>
        <linearGradient
          id="linearGradient668"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(267.45407 -267.45407) rotate(-80 -1.07 -1.344)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#1c1d58" stopOpacity="1"></stop>
          <stop offset="0.25" stopColor="#ed1c24" stopOpacity="1"></stop>
          <stop offset="0.6" stopColor="#fbc707" stopOpacity="1"></stop>
          <stop offset="0.8" stopColor="#99dde3" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#00a9a0" stopOpacity="1"></stop>
        </linearGradient>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 0 1109.26)">
        <g>
          <g clipPath="url(#clipPath652)">
            <g>
              <g>
                <path
                  fill="url(#linearGradient668)"
                  stroke="none"
                  d="M0 831.945v-.833h221.891l22.444-38.309a97.268 97.268 0 01-19.835-20.83l-51.702-73.577c-6.687-9.516.12-22.618 11.751-22.618h104.252c11.096 0 18 12.046 12.391 21.62l-55.769 95.193c16.67 12.779 37.294 19.967 58.8 19.967 25.803 0 49.231-9.633 67.477-27.88 18.247-18.245 28.879-42.921 28.879-68.725v-1.46c0-25.804-9.632-49.648-27.879-67.894-18.246-18.246-42.674-27.463-68.477-27.463H0v-2.08h304.223c26.026 0 50.495 10.135 68.898 28.538s28.538 42.873 28.538 68.899v1.46c0 26.026-10.135 50.495-28.538 68.898-18.404 18.404-42.872 28.539-68.898 28.539-21.655 0-42.426-7.228-59.224-20.075l-22.632 38.63zm174.399-132.717l50.782 72.266a96.36 96.36 0 0019.578 20.584l54.981-93.847c5.609-9.574-1.295-21.622-12.391-21.622H186.15c-11.63 0-18.437 13.103-11.751 22.619"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BackgroundRune3;
