import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/mainPage/App";
import "./fonts/avenir/Avenir-Black.ttf";
import "./fonts/avenir/Avenir-Light.ttf";
import "./fonts/avenir/AvenirLTStd-Roman.otf";
import "./utils/translator/index.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Labs from "./components/labs";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import LabsEnter from "./components/labs/labsEnter";
import LabsProject from "./components/labs/labsProject";
import LabsCes2023 from "./components/labs/labsCes2023";
import LabsUpcoming from "./components/labs/labsUpcoming";
import i18n from "./utils/translator";
import ScrollToTop from "./utils/scrollToTop";
import LabsProjectCurrentyChoice from "./components/labs/labsProject/labsProjectCurrentyChoice";
import Academy from "./components/academy";
// import AcademyEnter from "./components/academy/academyEnter";

const root = ReactDOM.createRoot(document.getElementById("root"));
// const domNode = document.getElementById("root");

// if (window.location.protocol == "http:") {
//   console.log(
//     "you are accessing us via " +
//       "an insecure protocol (HTTP). " +
//       "Redirecting you to HTTPS."
//   );

//   window.location.href = window.location.href.replace("http:", "https:");
// }

root.render(
  <Router i18n={i18n}>
    <Helmet>
      <title>{t("agarthaSeoTitle")}</title>
      <meta name="description" content={t("agarthaMetaSeoDescription")} />
      <meta
        name="keywords"
        content="web3, blockchain, decentralized, cryptocurrency, smart contracts, dApps, Ethereum, Solidity, IPFS, NFTs, DAOs, digital assets, tokenization, DeFi, peer-to-peer, decentralized finance"
      />
    </Helmet>

    <ScrollToTop>
      <Routes>
        {/* Section for the main website */}
        <Route path="/" element={<App />} />
        {/* Section for the labs */}
        <Route path="/labs" element={<Labs />} />
        <Route path="/labs/enter" element={<LabsEnter />} />
        <Route
          path="/labs/projectAgartha"
          element={
            <LabsProject>
              <LabsProjectCurrentyChoice />
            </LabsProject>
          }
        />
        <Route path="/labs/ces2023" element={<LabsCes2023 />} />
        <Route path="/labs/upcoming" element={<LabsUpcoming />} />
        {/* Section for the academy */}
        <Route path="/academy" element={<Academy />} />
        {/* <Route path="/academy/enter" element={<AcademyEnter />} /> */}

        <Route path="/legalmentions" element={<App legal={"legal_mentions"} />} />
        <Route path="/privacypolicy" element={<App legal={"privacy_policy"} />} />
        <Route path="/termsofuse" element={<App legal={"term_of_use"} />} />
      </Routes>
    </ScrollToTop>
  </Router>
);
