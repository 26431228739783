import React from "react";
import "./styles.css";

function LabsBackgroundRune3() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 754 1046"
      className="labsSvgRune3"
    >
      <g clipPath="url(#clip0_248_22)">
        <path
          fill="url(#paint0_linear_248_22)"
          d="M67.117 1045.66h794.279V58.287A69.55 69.55 0 00841.103 9.17a69.194 69.194 0 00-48.992-20.345A69.197 69.197 0 00743.119 9.17a69.555 69.555 0 00-20.293 49.117v789.87a64.131 64.131 0 01-18.712 45.203 63.8 63.8 0 01-45.088 18.76H67.031a66.511 66.511 0 00-47.092 19.556A66.857 66.857 0 00.432 978.89a66.846 66.846 0 0019.507 47.21 66.482 66.482 0 0047.092 19.56h.086zm713.308-2.86H68.145c-8.46 0-16.834-1.69-24.633-4.98a63.593 63.593 0 01-20.78-14.17 63.783 63.783 0 01-18.1-45.934c.6-34.746 27.571-60.154 62.37-60.154h592.11c36.771 0 66.6-32.712 66.6-69.577V59.405a66.803 66.803 0 014.847-25.551 66.682 66.682 0 0114.232-21.746 66.483 66.483 0 0121.448-14.63 66.347 66.347 0 0150.886-.427 66.471 66.471 0 0121.69 14.267 66.677 66.677 0 0114.593 21.504 66.798 66.798 0 015.274 25.465v906.109a78.545 78.545 0 01-22.921 55.474 78.13 78.13 0 01-55.336 22.98v-.05z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_248_22"
          x1="981.852"
          x2="298.7"
          y1="836.67"
          y2="443.264"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B1464"></stop>
          <stop offset="0.5" stopColor="#009974"></stop>
          <stop offset="1" stopColor="#CFE5AE"></stop>
        </linearGradient>
        <clipPath id="clip0_248_22">
          <path
            fill="#fff"
            d="M0 0H754V1046H0z"
            transform="matrix(-1 0 0 1 754 0)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LabsBackgroundRune3;
