import React from "react";
import "./styles.css";

function StudioMetaverseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 120 120"
      className="studioMetaverseIcon"
    >
      <circle cx="60" cy="60" r="60" fill="#D9D9D9"></circle>
      <path
        fill="#000"
        d="M61 18.333a41.667 41.667 0 100 83.334 41.667 41.667 0 000-83.334zm-29.167 57.5a35.088 35.088 0 008.334 1.125c4.706.13 9.318-1.339 13.083-4.166 7.125-5.584 7.125-12.75 7.125-18.5a19.833 19.833 0 011.542-9.75 11.918 11.918 0 014.666-3.792 40.62 40.62 0 003.834-2.542 18.96 18.96 0 005.833-7.791 33.333 33.333 0 0113.917 13.416c-5.959.834-14.417 2.792-16.084 10.542A29.16 29.16 0 0073.5 60a12.208 12.208 0 01-1.208 6.125l-.417.708c-2.708 4.5-5.75 9.625-1.625 16.667.5.875 1.042 1.708 1.583 2.542a9.541 9.541 0 012.167 4.5 32.876 32.876 0 01-13 2.791 33.333 33.333 0 01-29.167-17.5z"
      ></path>
    </svg>
  );
}

export default StudioMetaverseIcon;
