import React from "react";
import "./styles.css";

function labsBackgroundRune6() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 758 1054"
      className="labsSvgRune6"
    >
      <g clipPath="url(#clip0_474_22)" filter="url(#filter0_d_474_22)">
        <path
          fill="url(#paint0_linear_474_22)"
          d="M686.883 1045.66h-794.279V58.287A69.55 69.55 0 01-87.103 9.17a69.195 69.195 0 0148.992-20.345A69.195 69.195 0 0110.88 9.17a69.553 69.553 0 0120.293 49.117v789.87a64.13 64.13 0 0018.712 45.203 63.802 63.802 0 0045.087 18.76H686.97a66.513 66.513 0 0147.092 19.556 66.857 66.857 0 0119.507 47.214 66.846 66.846 0 01-19.507 47.21 66.48 66.48 0 01-47.092 19.56h-.086zm-713.309-2.86h712.28a63.37 63.37 0 0024.634-4.98 63.605 63.605 0 0034.453-35.31 63.779 63.779 0 004.427-24.794c-.6-34.746-27.571-60.154-62.371-60.154H94.887c-36.77 0-66.599-32.712-66.599-69.577V59.405a66.81 66.81 0 00-4.847-25.551A66.675 66.675 0 009.209 12.108a66.48 66.48 0 00-21.448-14.63 66.342 66.342 0 00-50.886-.427 66.473 66.473 0 00-21.69 14.267 66.667 66.667 0 00-14.593 21.504 66.796 66.796 0 00-5.274 25.465v906.109a78.545 78.545 0 0022.92 55.474 78.129 78.129 0 0055.337 22.98v-.05z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_474_22"
          width="762"
          height="1054"
          x="-4"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_474_22"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_474_22"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_474_22"
          x1="-227.852"
          x2="455.3"
          y1="836.67"
          y2="443.264"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B1464"></stop>
          <stop offset="0.5" stopColor="#009974"></stop>
          <stop offset="1" stopColor="#CFE5AE"></stop>
        </linearGradient>
        <clipPath id="clip0_474_22">
          <path fill="#fff" d="M0 0H754V1046H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default labsBackgroundRune6;
