const FormatMontantSol = (m) => {
  var intlN = new Intl.NumberFormat();
  return intlN.format(m);
};

const SolPrice = () => {
  /* Appel AJAX vers cryptocompare.com */
  var ajax = new XMLHttpRequest();
  /* Détection de la fin de l'appel */
  ajax.onload = function () {
    console.log("  status : " + this.status);
    console.log("  response : " + this.response);
    if (this.status === 200) {
      /* Le service a bien répondu */
      try {
        var json = JSON.parse(this.response); // Convertir le retour JSON
      } catch (err) {
        console.log("Retour JSON incorrect");
        return false;
      }
      /* Vérifier que le JSON de retour contient bien la propriété USD ou autre */
      if (json.USD) {
        var usd = FormatMontantSol(json.USD * 16);
        // var dt=new Date();
        document.getElementById("solana2").innerHTML = usd + " &dollar;";
        // Pour avoir le montant en équivalent dollar
        // document.getElementById("cours2").innerHTML="Maj "+dt.toLocaleString();
      }
      if (json.ETH) {
        var eth = FormatMontantSol(json.ETH * 16);
        // var dt=new Date();
        document.getElementById("solana").innerHTML = eth + " ETH";
        // Pour avoir le montant en équivalent eth
        // document.getElementById("cours2").innerHTML="Maj "+dt.toLocaleString();
      } else {
        console.log("Retour du cours incorrect");
      }
    }
  };
  /* Détection du timeout */
  ajax.ontimeout = function () {
    console.log("Le service n'a pas répondu à temps : nouvel essai dans 5 sec");
    /* Relancer l'appel 5 secondes plus tard */
    setTimeout(SolPrice(), 5000);
  };

  /* Préparation de la requête et envoi */
  var url =
    "https://min-api.cryptocompare.com/data/price?fsym=SOL&tsyms=USD,ETH,EUR&api_key={4a28a7aca9f9413be850b20f38d9f1dc41f08f9ebd209994ddc148ff5c663e97}";
  ajax.open("GET", url, true);
  ajax.timeout = 1000; /* Délai d'expiration à 1 seconde */
  ajax.send();
};

setInterval(function () {
  // run function every 10000 ms
  SolPrice();
}, 10000);

/* Démarrage de l'appel */
// window.onload=function() {
//     BtcPrice();
//     setInterval(BtcPrice, 1000);
// }

export default SolPrice;
