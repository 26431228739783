import React from "react";
import GenericFooter from "../mainPage/footer/genericFooter";
import AcademyAbout from "./academyAbout";
import AcademyCoursesOverview from "./academyCoursesOverview";
import AcademyHeaderBar from "./academyHeaderBar";
import AcademyHome from "./academyHome";
import "./styles.css";
import { Helmet } from "react-helmet";

function Academy() {
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://agartha.ch/academy" />
      </Helmet>

      <AcademyHeaderBar />
      <AcademyHome />
      <AcademyAbout />
      <AcademyCoursesOverview />
      <GenericFooter />
    </div>
  );
}

export default Academy;
