import React from "react";
import GhostIcon from "../svg/ghostIcon";
import "./styles.css";
import Button from "@mui/material/Button";
import LabsbackgroundRune6 from "../svg/labsBackgroundRune6";
import LabsbackgroundRune7 from "../svg/labsBackgroundRune7";
import WorldIcon from "../svg/worldIcon";
import SelectAutoWidth from "../labsHeader/languageSelector";
import { useTranslation } from "react-i18next";
import "../../../utils/translator";
import { Link as LinkBrowser, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Helmet } from "react-helmet";

function LabsEnter() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="labsEnterArea">
      <Helmet>
        <link rel="canonical" href="https://agartha.ch/labs/enter" />
      </Helmet>

      <div className="svgContainerLabsEnterLeft">
        <LabsbackgroundRune6 />
      </div>
      <div className="svgContainerLabsEnterRight">
        <LabsbackgroundRune7 />
      </div>

      <div className="labsEnterMainBlock">
        <h1 className="labsEnterTitle">{t("StepbystepTitle")}</h1>
        <div className="labsEnterSubtitles">
          <LinkBrowser to="/labs" className="labsEnterLinkApparence">
            <Button
              className="headerBar__button backButton"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
              <p className="returnBackText">{t("return")}</p>
            </Button>
          </LinkBrowser>
          <div className="languageSelectorBlock labsEnterLanguageButton">
            <WorldIcon />
            <SelectAutoWidth />
          </div>
        </div>

        <div className="stepsBlock">
          <div className="stepBlock">
            <h3>{t("StepbystepBuyTitle")}</h3>
            <p className="stepBlockText">{t("StepbystepBuyText1")}</p>
            <p className="stepBlockText">{t("StepbystepBuyText2")}</p>
            <p className="stepBlockText">{t("StepbystepBuyText3")}</p>
            <a
              href="https://phantom.app/download"
              alt="Ghosticon link"
              target="blank"
            >
              <GhostIcon />
            </a>
          </div>

          <div className="stepBlock">
            <h3>{t("StepbystepWhitelistTitle")}</h3>
            <p className="stepBlockText justifiedText">
              {t("StepbystepWhitelistText1")}
            </p>
            <ol className="stepBlockText justifiedText">
              <li>{t("StepbystepWhitelistText2")}</li>
              <li>{t("StepbystepWhitelistText3")}</li>
              <li>{t("StepbystepWhitelistText4")}</li>
            </ol>
          </div>

          <div className="stepBlock">
            <Button
              variant="outlined"
              className="labsHomeButton labsEnterSignInButton"
              href={t("labsEnterLitePaperLink")}
              target="_blank"
            >
              {t("StepbystepConnectButton")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabsEnter;
