import React from "react";
import "./styles.css";

function LabsBackgroundRune11() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 350 431"
      className="labsSvgRune11"
    >
      <g clipPath="url(#clip0_705_25)">
        <path
          fill="url(#paint0_linear_705_25)"
          d="M316.622-54H-54v460.405a32.423 32.423 0 009.47 22.904 32.3 32.3 0 0022.86 9.486 32.3 32.3 0 0022.86-9.486 32.423 32.423 0 009.469-22.904V38.095a29.894 29.894 0 018.73-21.078 29.781 29.781 0 0121.04-8.748h276.233c8.242 0 16.146-3.28 21.974-9.119a31.164 31.164 0 009.102-22.015 31.163 31.163 0 00-9.102-22.016A31.046 31.046 0 00316.662-54h-.04zm-332.84 1.336h332.36c3.947-.001 7.855.788 11.494 2.321a29.634 29.634 0 019.696 6.606 29.73 29.73 0 018.446 21.42c-.28 16.2-12.865 28.048-29.103 28.048H40.389c-17.158 0-31.077 15.254-31.077 32.444v367.71a31.135 31.135 0 01-2.262 11.914 31.08 31.08 0 01-6.64 10.139 31.002 31.002 0 01-21.86 9.288 30.984 30.984 0 01-22.013-8.919 31.083 31.083 0 01-6.81-10.027 31.135 31.135 0 01-2.46-11.875V-16.107a36.619 36.619 0 0110.695-25.869 36.481 36.481 0 0125.82-10.715v.027z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_705_25"
          x1="-110.206"
          x2="208.453"
          y1="43.451"
          y2="227.083"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B1464"></stop>
          <stop offset="0.5" stopColor="#009974"></stop>
          <stop offset="1" stopColor="#CFE5AE"></stop>
        </linearGradient>
        <clipPath id="clip0_705_25">
          <path
            fill="#fff"
            d="M0 0H350V431H0z"
            transform="matrix(-1 0 0 1 350 0)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LabsBackgroundRune11;
