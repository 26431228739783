import React from "react";
import "./styles.css";

function LabsBackgroundRune7() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 805 1065"
      className="labsSvgRune7"
    >
      <g clipPath="url(#clip0_518_22)" filter="url(#filter0_d_518_22)">
        <path
          fill="url(#paint0_linear_518_22)"
          d="M71.117.34h794.279v987.373a69.547 69.547 0 01-20.293 49.117 69.185 69.185 0 01-48.992 20.35 69.189 69.189 0 01-48.992-20.35 69.552 69.552 0 01-20.293-49.117v-789.87a64.131 64.131 0 00-18.712-45.203 63.8 63.8 0 00-45.088-18.76H71.031a66.511 66.511 0 01-47.092-19.556A66.858 66.858 0 014.432 67.11a66.857 66.857 0 0119.507-47.213A66.513 66.513 0 0171.03.34h.086zm713.308 2.864H72.145a63.375 63.375 0 00-24.633 4.978 63.504 63.504 0 00-20.78 14.166A63.692 63.692 0 0013.06 43.485a63.821 63.821 0 00-4.427 24.8c.6 34.745 27.571 60.153 62.37 60.153h592.11c36.771 0 66.6 32.712 66.6 69.577v788.58a66.84 66.84 0 004.847 25.555 66.696 66.696 0 0014.232 21.74 66.542 66.542 0 0021.448 14.63 66.241 66.241 0 0025.401 5.29 66.44 66.44 0 0025.485-4.86 66.408 66.408 0 0021.69-14.27 66.675 66.675 0 0014.593-21.5 66.81 66.81 0 005.274-25.467V81.604a78.559 78.559 0 00-22.921-55.477 78.156 78.156 0 00-55.336-22.98v.057z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_518_22"
          width="813"
          height="1065"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_518_22"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_518_22"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_518_22"
          x1="985.852"
          x2="302.7"
          y1="209.33"
          y2="602.736"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B1464"></stop>
          <stop offset="0.5" stopColor="#009974"></stop>
          <stop offset="1" stopColor="#CFE5AE"></stop>
        </linearGradient>
        <clipPath id="clip0_518_22">
          <path
            fill="#fff"
            d="M0 0H805V1057H0z"
            transform="rotate(-180 404.5 528.5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LabsBackgroundRune7;
