import React from "react";
import "./styles.css";

function WorldIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      className="worldIcon"
    >
      <path
        fill="#fff"
        d="M18 9A9 9 0 100 9a9 9 0 0018 0zm-1.863-.9H13.5a11.62 11.62 0 00-2.097-5.886A7.2 7.2 0 0116.137 8.1zM6.372 9.9H11.7A10.296 10.296 0 019 15.849 9.9 9.9 0 016.372 9.9zm0-1.8A10.26 10.26 0 019 2.16a10.071 10.071 0 012.7 5.94H6.372zm.324-5.913A11.862 11.862 0 004.563 8.1h-2.7a7.2 7.2 0 014.833-5.913zM1.863 9.9h2.7c.148 2.125.88 4.168 2.115 5.904A7.2 7.2 0 011.863 9.9zm9.495 5.895A11.826 11.826 0 0013.5 9.9h2.655a7.2 7.2 0 01-4.797 5.895z"
      ></path>
    </svg>
  );
}

export default WorldIcon;
