import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Link as LinkBrowser } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./styles.css";

export default function LabsHeaderLaunchpadButton() {
  const { t } = useTranslation();

  return (
    <PopupState variant="popper" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Button
            variant="contained"
            {...bindTrigger(popupState)}
            className="labsHeaderLaunchpadButton"
          >
            <h4>{t("launchpad")}</h4>
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            className="labsHeaderLaunchpadButtonPopoverContainer"
          >
            <Typography
              sx={{ p: 2 }}
              className="labsHeaderLaunchpadButtonPopover"
            >
              <h4>
                <Button
                  component={LinkBrowser}
                  variant="text"
                  to="/labs/projectAgartha"
                  className="headerBar__button"
                >
                  {t("live")}
                </Button>
              </h4>
              <h4>
                <Button
                  component={LinkBrowser}
                  to="/labs/upcoming"
                  className="headerBar__button"
                >
                  {t("UPCOMING")}
                </Button>
              </h4>
              <h4>
                <Button
                  disabled
                  component={LinkBrowser}
                  className="headerBar__button disabledButton"
                >
                  {t("PAST")}
                </Button>
              </h4>
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
