import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./styles.css";
import RoadmapSvg from "../../../svg/roadmapDesign/roadmapSvg";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  width: "80vw",
};

export default function RoadmapDetailAgarthaLabs() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  return (
    <div className="agarthaButtonRoadmap">
      <Button onClick={handleOpen} className="agarthaButtonRoadmap">
        <h2 className="monarchFont">AGARTHA LABS</h2>
        <div className="roundGreen"></div>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon onClick={handleClose} className="memberModalCloseIcon" />
          <div className="roadmapDetailedContainer">
            <div className="topContainerLabsDetails">
              <div className="whiteVerticalSeparationBar littleHorizontalSize"></div>

              <p className="agarthaRoadmapModalDetails">
                <span className="roadmapTitleFocus">
                  {t("ROADMAP_DETAILS_STEP1-0")}
                </span>
                {t("ROADMAP_DETAILS_STEP1-1")}
              </p>

              <div className="whiteVerticalSeparationBar littleHorizontalSize"></div>

              <p className="agarthaRoadmapModalDetails">
                <span className="roadmapTitleFocus">
                  {t("ROADMAP_DETAILS_STEP3-0")}
                </span>
                {t("ROADMAP_DETAILS_STEP5-1")}
              </p>

              <div className="whiteVerticalSeparationBar littleHorizontalSize"></div>

              <p className="agarthaRoadmapModalDetails">
                <span className="roadmapTitleFocus">
                  {t("ROADMAP_DETAILS_STEP5-0")}
                </span>
                {t("ROADMAP_DETAILS_STEP3-1")}
              </p>
              <div className="whiteVerticalSeparationBar littleHorizontalSize"></div>

              <p className="agarthaRoadmapModalDetails">
                <span className="roadmapTitleFocus">
                  {t("ROADMAP_DETAILS_STEP7-0")}
                </span>
                {t("ROADMAP_DETAILS_STEP7-1")}
              </p>

              <div className="whiteVerticalSeparationBar littleHorizontalSize"></div>

              <p className="agarthaRoadmapModalDetails">
                <span className="roadmapTitleFocus">
                  {t("ROADMAP_DETAILS_STEP9-0")}
                </span>
                {t("ROADMAP_DETAILS_STEP9-1")}
              </p>

              <div className="whiteVerticalSeparationBar littleHorizontalSize"></div>
            </div>

            <RoadmapSvg />

            <div className="botContainerLabsDetails">
              <div className="whiteVerticalSeparationBar littleHorizontalSize"></div>

              <p className="agarthaRoadmapModalDetails">
                <span className="roadmapTitleFocus">
                  {t("ROADMAP_DETAILS_STEP2-0")}
                </span>
                <div className="roadmapTextContainer">
                  {t("ROADMAP_DETAILS_STEP2-1")}
                  <span className="monarchFont">Agartha Labs</span>
                </div>
              </p>

              <div className="whiteVerticalSeparationBar littleHorizontalSize"></div>

              <p className="agarthaRoadmapModalDetails">
                <span className="roadmapTitleFocus">
                  {t("ROADMAP_DETAILS_STEP4-0")}
                </span>
                {t("ROADMAP_DETAILS_STEP4-1")}
              </p>

              <div className="whiteVerticalSeparationBar littleHorizontalSize"></div>

              <p className="agarthaRoadmapModalDetails">
                <span className="roadmapTitleFocus">
                  {t("ROADMAP_DETAILS_STEP6-0")}
                </span>
                {t("ROADMAP_DETAILS_STEP6-1")}
              </p>

              <div className="whiteVerticalSeparationBar littleHorizontalSize"></div>

              <p className="agarthaRoadmapModalDetails">
                <span className="roadmapTitleFocus">
                  {t("ROADMAP_DETAILS_STEP8-0")}
                </span>
                <div className="roadmapTextContainer">
                  {t("ROADMAP_DETAILS_STEP8-1")}
                  <span className="monarchFont">Agartha Labs</span>
                </div>
              </p>

              <div className="whiteVerticalSeparationBar littleHorizontalSize"></div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
