import React from "react";
import "./styles.css";
import TwitterIcon from "../../../images/twitter-icon.png";
import InstagramIcon from "../../../images/instagram-icon.png";
// import DiscordIcon from "../../../images/discord-icon.png";
// import MediumIcon from "../../../images/medium-icon.png";
import LinkedinIcon from "../../../images/linkedin-icon.png";

function LogosArea() {
  return (
    <div className="logosArea">
      <a href="https://mobile.twitter.com/agartha_ch/" target="blank">
        <img src={TwitterIcon} alt="Twitter Logo" className="logosStyle" />
      </a>
      <a href="https://www.instagram.com/agartha.ch/" target="blank">
        <img src={InstagramIcon} alt="Instagram Logo" className="logosStyle" />
      </a>
      {/* <a href="https://discord.gg/e5FwK4rZQu" target="blank"><img src={DiscordIcon} alt="Discord Logoo" className="logosStyle" /></a> */}
      {/* <a href="https://medium.com/@Agartha.ch"><img src={MediumIcon} alt="Medium Logo" className="logosStyle" /></a> */}
      <a href="https://www.linkedin.com/company/agartha-ch/" target="blank">
        <img src={LinkedinIcon} alt="Linkedin Logo" className="logosStyle" />
      </a>
    </div>
  );
}

export default LogosArea;
