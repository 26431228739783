import React, { Fragment, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TeamLineMembers } from "../../../mainPage/team/teamMembers";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { useTranslation } from "react-i18next";
import TabPanel from "@mui/lab/TabPanel";
import "./styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Button from "@mui/material/Button";
import TwitterIcon from "../../svg/twitterIcon";
import InstagramIcon from "../../svg/instagramIcon";
import LinkedinIcon from "../../svg/linkedinLogo";
import MemberModalDetails from "../../../mainPage/team/memberModalDetails";

export default function LabsProjectAgarthaTab() {
  const [value, setValue] = React.useState("1");
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [largeur, setLargeur] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
    };
    if (largeur > 1400) {
      setValue("2");
    } else {
      setValue("1");
    }

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <Box sx={{ typography: "body1" }} className="labsProjectTabContainer">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="scrollable auto tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {largeur <= 1400 && (
              <Tab
                label={t("AgarthaTabDescriptionTitle")}
                value="1"
                className="labsProjectTab"
              />
            )}
            <Tab
              label={t("AgarthaTabTeamTitle")}
              value="2"
              className="labsProjectTab"
            />
            <Tab
              label={t("AgarthaTabRoadmapTitle")}
              value="3"
              className="labsProjectTab"
            />

            <Tab
              label={t("AgarthaTabUtilityTitle")}
              value="4"
              className="labsProjectTab"
            />
          </TabList>
        </Box>

        <TabPanel value="4" className="labsProjectTabUtility">
          <p className="labsProjectDescriptionText">
            <span className="monarchFont">Agartha Labs</span>
            {t("labsProjectAgarthaTabUtilityTxt1")}
          </p>
          <ul className="labsProjectRoadmapSteps">
            <li className="labsProjectDescriptionText">
              {t("labsProjectAgarthaTabUtilityTxt2")}
            </li>
            <li className="labsProjectDescriptionText">
              {t("labsProjectAgarthaTabUtilityTxt3")}
            </li>
            <li className="labsProjectDescriptionText">
              {t("labsProjectAgarthaTabUtilityTxt4")}
            </li>
            <li className="labsProjectDescriptionText">
              {t("labsProjectAgarthaTabUtilityTxt5")}
            </li>
          </ul>
          <p className="labsProjectDescriptionText">
            {t("labsProjectAgarthaTabUtilityTxt6")}
            <span className="monarchFont">Agartha Labs</span>
            {t("labsProjectAgarthaTabUtilityTxt7")}
          </p>
          <br />
          <Button
            variant="outlined"
            className="labsLinkButton"
            href={t("labsEnterLitePaperLink")}
            target="_blank"
          >
            {t("joinWhitelist")}
          </Button>
        </TabPanel>

        <TabPanel value="2" className="labsProjectTabUtility">
          <div className="labsProjectSwiperTeam" id="labsTeamPhotos">
            <div className="labsProjectTeamContainer" id="teamContainer">
              {largeur >= 550 && (
                <Swiper
                  slidesPerView={2}
                  modules={[Grid, Navigation, Pagination, Mousewheel, Keyboard]}
                  navigation={true}
                  grid={{
                    rows: 2,
                  }}
                  pagination={false}
                  mousewheel={false}
                  keyboard={true}
                  spaceBetween={30}
                  className="swiper"
                >
                  {TeamLineMembers.map((item) => (
                    <Fragment key={item.id}>
                      <SwiperSlide
                        className="profilOverview lightProfileOverview disable-select"
                        key={item.id}
                      >
                        <img
                          src={item.img}
                          alt="teamMembersPicture"
                          className="teamAvatar disable-select"
                          id={item.id}
                        />
                        <div className="labsProjectTeamDescriptionBlock">
                          <h3 className="profilName">{item.name}</h3>
                          <h4 className="profilName">{item.job}</h4>
                          {/* <p className="descriptionProfil">{item.skills}</p> */}
                        </div>
                        <MemberModalDetails item={item} />
                      </SwiperSlide>
                    </Fragment>
                  ))}
                </Swiper>
              )}

              {largeur < 550 && (
                <Swiper
                  slidesPerView={1}
                  modules={[Grid, Navigation, Pagination, Mousewheel, Keyboard]}
                  navigation={true}
                  grid={{
                    rows: 2,
                  }}
                  pagination={false}
                  mousewheel={false}
                  keyboard={true}
                  spaceBetween={30}
                  className="labsSwiper lightLabsSwiper"
                  allowScrollButtonsMobile
                >
                  {TeamLineMembers.map((item) => (
                    <Fragment key={item.id}>
                      <SwiperSlide
                        className="profilOverview lightProfileOverview disable-select"
                        key={item.id}
                      >
                        <img
                          src={item.img}
                          alt="teamMembersPicture"
                          className="teamAvatar disable-select"
                          id={item.id}
                        />
                        <div className="labsProjectTeamDescriptionBlock">
                          <h3 className="profilName">{item.name}</h3>
                          <h4 className="profilName">{item.job}</h4>
                          {/* <p className="descriptionProfil">{item.skills}</p> */}
                        </div>
                        <MemberModalDetails item={item} />
                      </SwiperSlide>
                    </Fragment>
                  ))}
                </Swiper>
              )}
            </div>
          </div>
        </TabPanel>

        <TabPanel value="3" className="labsProjectTabUtility">
          <ul className="labsProjectRoadmapSteps">
            <li>Q4 2020 - {t("labsProjectAgarthaTabRoadmapTxt1")}</li>
            <li>Q1 2021 - {t("labsProjectAgarthaTabRoadmapTxt2")}</li>
            <li>Q2/Q3 2021 - {t("labsProjectAgarthaTabRoadmapTxt3")}</li>
            <li>Q4 2021 - {t("labsProjectAgarthaTabRoadmapTxt4")}</li>
            <li>Q1 2022 - {t("labsProjectAgarthaTabRoadmapTxt5")}</li>
            <li>Q2 2022 - {t("labsProjectAgarthaTabRoadmapTxt6")}</li>
            <li>Q3 2022 - {t("labsProjectAgarthaTabRoadmapTxt7")}</li>
            <li>Q1 2023 - {t("labsProjectAgarthaTabRoadmapTxt8")}</li>
            <li>Q1 2023 - {t("labsProjectAgarthaTabRoadmapTxt9")}</li>
          </ul>
        </TabPanel>

        <TabPanel value="1" className="labsProjectTabUtility">
          <div className="labsProjectDescriptionLeftBlock">
            <div className="labsProjectDescriptionBlock">
              <div className="labsProjectDescriptionAgarthaSummary">
                <div className="labsProjectDescriptionAgarthaState">
                  <h2 className="labsProjectAgarthaLabsTitle monarchFont">
                    Agartha Labs
                  </h2>
                  <div className="roundGreenFlash"></div>
                </div>
                <div className="labsProjectDescriptionLinks">
                  <a
                    href="https://mobile.twitter.com/agartha_ch/"
                    target="blank"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/agartha.ch/"
                    target="blank"
                  >
                    <InstagramIcon />
                  </a>
                  <a href="https://www.linkedin.com/company/agartha-ch/">
                    <LinkedinIcon />
                  </a>
                </div>
              </div>

              <div className="labsProjectDescription">
                <h3 className="labsProjectDescriptionTitle">Description :</h3>
                <div>
                  <p className="labsProjectDescriptionText">
                    <span className="monarchFont">Agartha Labs</span>
                    {t("labsProjectExempleDescription1")}
                  </p>
                </div>
              </div>

              <div className="labsProjectOverviewButtons"></div>
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  );
}
