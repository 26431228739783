import photoArnaud from "../../../images/team/arnaud.png";
import photoBen from "../../../images/team/ben.png";
import photoGuillaume from "../../../images/team/guillaume.png";
import photoHendy from "../../../images/team/hendy.png";
import photoHugo from "../../../images/team/hugo.png";
import photoJoao from "../../../images/team/joao.png";
import photoLeila from "../../../images/team/leila.png";
import photoRita from "../../../images/team/rita.png";
import photoAmine from "../../../images/team/amine.png";
import photoAlexander from "../../../images/team/alexander.png";
import photoStan from "../../../images/team/stan.png";
import photoVahe from "../../../images/team/vahe.png";
import photoHannouch from "../../../images/team/hannouch.png";
import photoCharles from "../../../images/team/charles.png";
import photoDavid from "../../../images/team/david.png";
import photoDooley from "../../../images/team/dooley.png";
import photoDavidOuze from "../../../images/team/davidOuze.png";
import { useTranslation } from "react-i18next";
import photoAlix from "../../../images/team/alix.png";
import photoJanet from "../../../images/team/janet.png";
import photoIvan from "../../../images/team/ivan.png";

const DescriptionMultiLanguage = (props) => {
  const { t } = useTranslation();
  return t("teamMemberDescription" + props.name);
};

const TitleMultiLanguage = (props) => {
  const { t } = useTranslation();
  return t("teamMemberTitle" + props.name);
};

export const TeamLineMembers = [
  {
    id: 1,
    img: photoJoao,
    name: "Joao",
    // job: "Agartha founder - CEO",
    job: <TitleMultiLanguage name="Joao" />,
    skills: "Crypto expert | Marketing manager | Director",
    linkedin: "https://www.linkedin.com/in/joaoagartha/",
    description: <DescriptionMultiLanguage name="Joao" />,
  },
  {
    id: 2,
    img: photoArnaud,
    name: "Arnaud",
    // job: "Agartha founder - CIO",
    job: <TitleMultiLanguage name="Arnaud" />,
    skills: "Investor | Trader | Relationship | Strategy",
    linkedin: "https://www.linkedin.com/in/arnaud-depres-862975232/",
    description: <DescriptionMultiLanguage name="Arnaud" />,
  },
  {
    id: 3,
    img: photoHendy,
    name: "Hendy",
    // job: "IT director - CTO",
    job: <TitleMultiLanguage name="Hendy" />,
    skills: "Manager | Computer engineer | Electronic engineer",
    linkedin: "https://www.linkedin.com/in/e-liberty/",
    description: <DescriptionMultiLanguage name="Hendy" />,
  },
  {
    id: 4,
    img: photoGuillaume,
    name: "Guillaume",
    // job: "Web developer Front-End",
    job: <TitleMultiLanguage name="Guillaume" />,
    skills: "CSS | JS | React | Design",
    linkedin: "https://www.linkedin.com/in/guillaume-jolibois-430426119/",
    description: <DescriptionMultiLanguage name="Guillaume" />,
  },
  {
    id: 5,
    img: photoHugo,
    name: "Hugo",
    // job: "Artistic director",
    job: <TitleMultiLanguage name="Hugo" />,
    skills: "Artist | Videographer",
    linkedin: "https://www.linkedin.com/in/hugo-bel-713b8b169/",
    description: <DescriptionMultiLanguage name="Hugo" />,
  },
  {
    id: 6,
    img: photoLeila,
    name: "Leila",
    // job: "Developer JS Fullstack",
    job: <TitleMultiLanguage name="Leila" />,
    skills: "JS | MYSQL",
    linkedin: "https://www.linkedin.com/in/leila-bentahar-ba00b9173/",
    description: <DescriptionMultiLanguage name="Leila" />,
  },
  {
    id: 7,
    img: photoAmine,
    name: "Amine",
    // job: "Event director",
    job: <TitleMultiLanguage name="Amine" />,
    skills: "Manager | Digital marketing",
    linkedin: "https://www.linkedin.com/in/amine-merahi-57445925b/",
    description: <DescriptionMultiLanguage name="Amine" />,
  },
  {
    id: 8,
    img: photoRita,
    name: "Rita",
    // job: "Community manager",
    job: <TitleMultiLanguage name="Rita" />,
    skills: "Cordinatrice | Manager",
    description: <DescriptionMultiLanguage name="Rita" />,
  },
  {
    id: 9,
    img: photoBen,
    name: "Benjamin",
    // job: "Junior developer",
    job: <TitleMultiLanguage name="Benjamin" />,
    skills: "HTML | CSS",
    linkedin: "https://www.linkedin.com/in/benjaminterkuile/",
    description: <DescriptionMultiLanguage name="Benjamin" />,
  },
  {
    id: 10,
    img: photoIvan,
    name: "Ivan",
    linkedin: "https://www.linkedin.com/in/ivan-vetrov-25869b99/",
    // job: "Stratégie",
    skills: "Metavers",
    job: <TitleMultiLanguage name="Ivan" />,
    description: <DescriptionMultiLanguage name="Ivan" />,
  },
  {
    id: 11,
    img: photoVahe,
    name: "Vahe",
    // job: "Business provider",
    job: <TitleMultiLanguage name="Vahe" />,
    skills: "Problem solving",
    linkedin: "https://www.linkedin.com/in/karaman-vahe-4483661b2/",
    description: <DescriptionMultiLanguage name="Vahe" />,
  },
  {
    id: 12,
    img: photoDavid,
    name: "David",
    // job: "Accounting",
    job: <TitleMultiLanguage name="DavidJe" />,
    skills: "Management | RH | Commercial",
    description: <DescriptionMultiLanguage name="DavidJe" />,
  },
  {
    id: 13,
    img: photoStan,
    name: "Stan",
    // job: "Co-founder Direct African Market Solutions",
    job: <TitleMultiLanguage name="Stan" />,
    skills: "Project manager ATC comafrique",
    description: <DescriptionMultiLanguage name="Stan" />,
  },
  {
    id: 14,
    img: photoDooley,
    name: "Dooley",
    // job: "Board Advisor",
    job: <TitleMultiLanguage name="Dooley" />,
    skills: "Senior banker",
    linkedin: "https://www.linkedin.com/in/dooley-awadalla-3bb78382/",
    description: <DescriptionMultiLanguage name="Dooley" />,
  },
  {
    id: 15,
    img: photoHannouch,
    name: "Anouche",
    // job: "Board advisor",
    job: <TitleMultiLanguage name="Anouche" />,
    skills: "Business",
    linkedin: "https://www.linkedin.com/in/anouchekaraman/",
    description: <DescriptionMultiLanguage name="Anouche" />,
  },
  {
    id: 16,
    img: photoCharles,
    name: "Charles",
    // job: "DA - 3D Design - Illustrator",
    job: <TitleMultiLanguage name="Charles" />,
    skills: "Graphic designer 3d",
    description: <DescriptionMultiLanguage name="Charles" />,
  },
  {
    id: 17,
    img: photoDavidOuze,
    name: "David",
    linkedin: "https://www.linkedin.com/in/it-network-ch/",
    // job: "Dev fullstack",
    job: <TitleMultiLanguage name="DavidHo" />,
    description: <DescriptionMultiLanguage name="DavidHo" />,
  },
  {
    id: 18,
    img: photoAlix,
    name: "Alix",
    linkedin: "https://www.linkedin.com/in/alix-tonda/",
    // job: "Content creator",
    job: <TitleMultiLanguage name="Alix" />,
    description: <DescriptionMultiLanguage name="Alix" />,
  },
  {
    id: 19,
    img: photoJanet,
    name: "Janet",
    linkedin: "https://www.linkedin.com/in/janet-de-brabandere/",
    // job: "Stratégie",
    job: <TitleMultiLanguage name="Janet" />,
    description: <DescriptionMultiLanguage name="Janet" />,
  },
  {
    id: 20,
    img: photoAlexander,
    name: "Alexander",
    // job: "Mathematician",
    job: <TitleMultiLanguage name="Alexander" />,
    skills: "Programming | Mathematical",
    description: <DescriptionMultiLanguage name="Alexander" />,
  },
];
