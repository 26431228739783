import React from "react";
import "./styles.css";

function ConsultingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="consultingIcon gradient-border"
      fill="none"
      viewBox="0 0 139 139"
    >
      <path
        fill="#D9D9D9"
        d="M139 69.5c0 38.384-31.116 69.5-69.5 69.5S0 107.884 0 69.5 31.116 0 69.5 0 139 31.116 139 69.5z"
      ></path>
      <path
        fill="#0B0B0B"
        d="M91.354 62.5h-21.29v8.312c0 5.894-4.523 10.689-10.084 10.689-5.56 0-10.084-4.795-10.084-10.689V52.761l-9.09 5.79c-2.704 1.707-4.357 4.81-4.357 8.135v7.021l-11.205 6.859c-2.143 1.306-2.885 4.215-1.639 6.487l11.206 20.575c1.232 2.271 3.977 3.043 6.12 1.737l14.483-8.863h19.133c4.944 0 8.964-4.26 8.964-9.5h2.24c2.48 0 4.483-2.123 4.483-4.75V76.75h1.12c1.863 0 3.362-1.588 3.362-3.562v-7.126c0-1.974-1.499-3.563-3.362-3.563zm20.688-5.553l-11.205-20.575c-1.233-2.271-3.978-3.043-6.121-1.737l-14.483 8.863H65.92c-1.681 0-3.32.504-4.748 1.44l-4.693 3.102c-1.316.861-2.1 2.39-2.1 4.023v18.75c0 3.28 2.507 5.937 5.602 5.937 3.096 0 5.603-2.657 5.603-5.938V57.75h25.771c4.328 0 7.844 3.726 7.844 8.313v4.23l11.205-6.858c2.143-1.32 2.871-4.216 1.639-6.487z"
      ></path>
    </svg>
  );
}

export default ConsultingIcon;
