import React, { useEffect, useState } from "react";
import "./styles.css";
import { Link as LinkBrowser } from "react-router-dom";
import Ces2023Logo from "../../../images/Logos/ces2023Logo.png";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import "../../../../utils/translator";

function HeaderBarBanner() {
  const [largeur, setLargeur] = useState(window.innerWidth);
  const [toggleBanner, isToggleBanner] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div
      className="bannerContainer"
      style={{ display: toggleBanner ? "flex" : "none" }}
    >
      <img src={Ces2023Logo} alt="logo du CES" />
      {largeur >= 800 && (
        <h2 className="smallScreenInfoCes2023Title">
          {t("ces2023BannerTitle")}
        </h2>
      )}
      {largeur < 800 && largeur >= 370 && (
        <div className="smallScreenInfoCes2023">
          <h2 className="smallScreenInfoCes2023Title">CES LAS VEGAS</h2>
        </div>
      )}
      {largeur < 370 && (
        <div className="smallScreenInfoCes2023">
          <h2 className="smallScreenInfoCes2023Title">CES 2023</h2>
        </div>
      )}
      <LinkBrowser to="/labs/ces2023">
        <Button className="ces2023BannerButton">
          <h4>DETAILS</h4>
        </Button>
      </LinkBrowser>
      <CloseIcon
        onClick={() => isToggleBanner(!toggleBanner)}
        className="ces2023BannerCross"
      />
    </div>
  );
}

export default HeaderBarBanner;
