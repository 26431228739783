import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./styles.css";
import LinkedinIcon from "../../../labs/svg/linkedinLogo";
import CloseIcon from "@mui/icons-material/Close";

export default function MemberModalDetails({ item }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="memberModalContainer">
      <Button className="memberModalContainer" onClick={handleOpen}></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="memberModalDetails">
          <CloseIcon onClick={handleClose} className="memberModalCloseIcon" />
          <img
            src={item.img}
            alt="teamMembersPicture"
            className="profileTeamModalPicture disable-select"
            id={item.id}
          />
          <div className="descriptionBlock">
            <div className="profilNameContainer">
              <h3 className="profilName">{item.name}</h3>
              <a
                href={item.linkedin}
                target="blank"
                className="memberModalLinkContainer"
              >
                {item.linkedin ? <LinkedinIcon /> : ""}
              </a>
            </div>
            <h4 className="profilName">{item.job}</h4>
            {/* <p className="descriptionProfil">{item.skills}</p> */}
            <p className="longDescriptionProfil">{item.description}</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
