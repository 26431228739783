import React from "react";
import "./styles.css";
import LabsHeader from "./labsHeader";
import LabsHome from "./labsHome";
import LabsServices from "./labsService";
import LabsAbout from "./labsAbout";
// import LabsTrustedBy from "./labsTrustedBy";
import LabsResearch from "./labsResearch";
import LabsFooter from "./labsFooter";
// import LabsPartners from "./labsPartners";
import OurPartners from "../mainPage/ourPartners";
import { Helmet } from "react-helmet";

function Labs() {
  return (
    <div className="labs">
      <Helmet>
        <link rel="canonical" href="https://agartha.ch/labs" />
      </Helmet>

      <LabsHeader />
      <LabsHome />
      <LabsServices />
      <LabsAbout />
      {/* <LabsTrustedBy /> */}
      {/* <LabsPartners /> */}
      <OurPartners />
      <LabsResearch />
      <LabsFooter />
    </div>
  );
}

export default Labs;
