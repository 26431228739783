import React from "react";
import "./styles.css";

function FundingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 93 116"
      className="fundingIcon"
    >
      <path
        fill="#fff"
        d="M46.16 46.16c22.942 0 46.16-7.928 46.16-23.08C92.32 7.928 69.102 0 46.16 0 23.219 0 0 7.928 0 23.08c0 15.152 23.218 23.08 46.16 23.08z"
      ></path>
      <path
        fill="#fff"
        d="M0 46.16c0 15.152 23.218 23.08 46.16 23.08 22.942 0 46.16-7.928 46.16-23.08V34.62c0 15.152-23.218 23.08-46.16 23.08C23.219 57.7 0 49.772 0 34.62v11.54z"
      ></path>
      <path
        fill="#fff"
        d="M0 69.24c0 15.152 23.218 23.08 46.16 23.08 22.942 0 46.16-7.928 46.16-23.08V57.7c0 15.152-23.218 23.08-46.16 23.08C23.219 80.78 0 72.852 0 57.7v11.54z"
      ></path>
      <path
        fill="#fff"
        d="M0 92.32c0 15.152 23.218 23.08 46.16 23.08 22.942 0 46.16-7.928 46.16-23.08V80.78c0 15.152-23.218 23.08-46.16 23.08C23.219 103.86 0 95.932 0 80.78v11.54z"
      ></path>
    </svg>
  );
}

export default FundingIcon;
