import React, { useState, useEffect } from "react";
import "./styles.css";
import BitcoinIcon from "../svg/bitcoinIcon";
import ConsultingIcon from "../svg/consultingIcon";
import ContractIcon from "../svg/contractIcon";
import EventsIcon from "../svg/eventsIcon";
import MarketingIcon from "../svg/marketingIcon";
import LabsBackgroundRune3 from "../svg/labsBackgroundRune3";
import LabsBackgroundRune6 from "../svg/labsBackgroundRune6";
import { useTranslation } from "react-i18next";
import "../../../utils/translator";
import StudioMetaverseIcon from "../svg/studioMetaverseIcon";

function LabsServices() {
  const { t } = useTranslation();
  const [largeur, setLargeur] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div className="labsServicesArea" id="labsServices">
      {largeur <= 1024 && (
        <div className="svgContainer6">
          <LabsBackgroundRune6 />
        </div>
      )}
      <div className="svgContainer3">
        <LabsBackgroundRune3 />
      </div>

      <h2 className="labsScrollSectionsTitles servicesTitle">Services</h2>

      <div className="labsServices__line">
        <div className="servicesBlock">
          <div className="servicesBlockDescription">
            <ConsultingIcon />
            <h3>{t("labsServicesConsultingTitle")}</h3>
          </div>
          <p>{t("labsServicesConsultingText1")}</p>
        </div>

        <div className="servicesBlock">
          <div className="servicesBlockDescription">
            <ContractIcon />
            <h3>{t("labsServicesSmartContractTitle")}</h3>
          </div>
          <p>{t("labsServicesSmartContractText1")}</p>
        </div>

        <div className="servicesBlock">
          <div className="servicesBlockDescription">
            <BitcoinIcon />
            <h3>{t("labsServicesFinancialTitle")}</h3>
          </div>
          <p>
            {t("labsServicesFinancialText1")}
            <em className="monarchFont">Agartha Labs</em>
            {t("labsServicesFinancialText2")}
          </p>
        </div>
      </div>

      <div className="labsServices__line">
        <div className="servicesBlock">
          <div className="servicesBlockDescription">
            <MarketingIcon />
            <h3>Marketing</h3>
          </div>
          <p>{t("labsServicesMarketingText")}</p>
        </div>

        <div className="servicesBlock">
          <div className="servicesBlockDescription">
            <EventsIcon />
            <h3>{t("labsServicesEventsTitle")}</h3>
          </div>
          <p>{t("labsServicesEventsText1")}</p>
        </div>

        <div className="servicesBlock">
          <div className="servicesBlockDescription">
            <StudioMetaverseIcon />
            <h3>{t("labsServicesStudioTitle")}</h3>
          </div>
          <p>{t("labsServicesStudioText1")}</p>
        </div>
      </div>
    </div>
  );
}

export default LabsServices;
