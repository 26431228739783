import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./styles.css";
import RoadmapSvg from "../../../svg/roadmapDesign/roadmapSvg";
// import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  width: "80vw",
};

export default function RoadmapDetailAgarthaAcademy() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const {t} = useTranslation();

  return (
    <div className="agarthaButtonRoadmap">
      <Button onClick={handleOpen} className="agarthaButtonRoadmap">
        <h2 className="monarchFont">AGARTHA ACADEMY</h2>
        <div className="roundYellow"></div>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon onClick={handleClose} className="memberModalCloseIcon" />
          <div className="roadmapDetailedContainer">
            <div className="topContainerDetailAcademy">
              <h2>COMING SOON</h2>
            </div>

            <RoadmapSvg />

            <div className="botContainerDetails"></div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
