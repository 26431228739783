import React from "react";
import "./styles.css";
import WorldIcon from "../svg/worldIcon";
import SelectAutoWidth from "../labsHeader/languageSelector";
import { useTranslation } from "react-i18next";
import "../../../utils/translator";
import AgarthaLabsLogoImage from "../../images/agarthaLabsLogo.png";
import Button from "@mui/material/Button";
import GenericFooter from "../../mainPage/footer/genericFooter";
import { Link as LinkBrowser } from "react-router-dom";
import Ces2023Logo from "../../images/Logos/ces2023Logo.png";
import Ces2023 from "../../images/ces2023.jpg";
import LabsHeaderLaunchpadButton from "../labsHeader/labsHeaderLaunchpadButton";
import LabsCes2023ImagesList from "./labsCes2023ImagesList";
import { Helmet } from "react-helmet";

function LabsCes2023() {
  const { t } = useTranslation();

  return (
    <div className="labsCes2023Area">
      <Helmet>
        <link rel="canonical" href="https://agartha.ch/labs/ces2023" />
      </Helmet>

      <div className="labsCes2023Header">
        <img
          src={AgarthaLabsLogoImage}
          alt="logo agartha labs"
          className="labsCesLogo"
        />

        <div className="labsCes2023HeaderButtons">
          <LinkBrowser to="/labs" className="labsCes2023LinkApparence">
            <Button className="headerBar__button labsCes2023ReturnButton">
              <h4>Go to the Labs</h4>
            </Button>
          </LinkBrowser>
          <LabsHeaderLaunchpadButton />
          <div className="labsLanguageSelectorBlock">
            <WorldIcon />
            <SelectAutoWidth />
          </div>
        </div>
      </div>

      <div className="labsCes2023MainContainer">
        <div className="labsCes2023DescriptionAgarthaSummary">
          <h2 className="labsCes2023AgarthaLabsTitle">
            {t("ces2023BannerTitle")}
          </h2>
        </div>

        <div className="labsCes2023DescriptionBlock">
          <div className="labsCes2023Description">
            <h3 className="labsCes2023DescriptionTitle">
              {t("WhatIsAgartha")}
              <span className="monarchFont">Agartha</span>
              {t("WhatIsAgartha-2")}
            </h3>
            <p className="labsCes2023DescriptionProjectText">
              <span className="monarchFont">Agartha</span>
              {t("FOOTER_DESCRIPTION-1")}
              <span className="monarchFont">Agartha Labs, Agartha Academy</span>
              {t("FOOTER_DESCRIPTION-2")}
              <span className="monarchFont">Agartha Market.</span>
              <br />
              <br />
              <span className="monarchFont">Agartha </span>
              {t("FOOTER_DESCRIPTION-3")}
            </p>
            <LinkBrowser
              to="/labs/projectAgartha"
              variant="outlined"
              className="whiteButton ces2023Button"
            >
              <h4 className="ces2023LinkApparence monarchFont">
                {t("Collection")}
              </h4>
            </LinkBrowser>
          </div>

          <div className="labsCes2023OverviewPicture">
            <img
              src={Ces2023}
              alt="CES 2023 logo"
              className="labsCes2023OverviewImage"
            />
          </div>
        </div>

        <LabsCes2023ImagesList />

        <div className="labsCes2023LinksBlock">
          <div className="labsCes2023DescriptionProjects">
            <h3 className="labsCes2023DescriptionTitle">
              <span className="monarchFont">Agartha</span>
              {t("agarthaProjects")}
            </h3>
            <div>
              <p className="labsCes2023DescriptionProjectText">
                {t("labsCes2023DescriptionProjectText0-1")}
                <span className="monarchFont">Agartha</span>
                {t("labsCes2023DescriptionProjectText0-2")}
              </p>
              <p className="labsCes2023DescriptionProjectText">
                {t("labsCes2023DescriptionProjectText1")}
              </p>
              <p className="labsCes2023DescriptionProjectText">
                {t("labsCes2023DescriptionProjectText2")}
              </p>
              <p className="labsCes2023DescriptionProjectText">
                {t("labsCes2023DescriptionProjectText3-1")}
                <span className="monarchFont">Agartha Labs</span>
                {t("labsCes2023DescriptionProjectText3-2")}
                <span className="monarchFont">Agartha Labs</span>
                {t("labsCes2023DescriptionProjectText3-3")}
              </p>
            </div>
          </div>

          <div className="labsCes2023Summary">
            <p className="labsCes2023DescriptionProjectText">
              CES-2023, Las Vegas, Jan 05-08, 2023
            </p>
            <img
              src={Ces2023Logo}
              alt="logo du CES"
              className="labsCesSummaryLogo"
            />
            <p className="labsCes2023DescriptionProjectText">
              <span className="monarchFont">Agartha</span>
              {t("labsCes2023DescriptionProjectText4")}
            </p>
            <p className="labsCes2023DescriptionProjectText">
              {t("labsCes2023DescriptionProjectText5")}
            </p>
            <LinkBrowser
              to="/labs"
              variant="outlined"
              className="whiteButton ces2023Button"
            >
              <h4 className="ces2023LinkApparence monarchFont">AGARTHA LABS</h4>
            </LinkBrowser>
            <Button variant="outlined" className="whiteButton ces2023Button">
              <a
                href="https://calendly.com/agartha-ch/30min?primary_color=b1b3b5"
                target="blank"
              >
                <h4 className="ces2023LinkApparence">{t("rdvButton")}</h4>
              </a>
            </Button>
          </div>
        </div>
      </div>

      <GenericFooter />
    </div>
  );
}

export default LabsCes2023;
