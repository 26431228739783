import React from "react";
import "./styles.css";

function MarketingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 139 139"
      className="marketingIcon"
    >
      <circle cx="69.5" cy="69.5" r="69.5" fill="#D9D9D9"></circle>
      <path
        fill="#E6E6E6"
        d="M65.14 122.373c32.11 0 58.141-18.889 58.141-42.191 0-23.302-26.03-42.191-58.14-42.191S7 56.88 7 80.18c0 23.303 26.03 42.192 58.14 42.192z"
        opacity="0.24"
      ></path>
      <path
        fill="#0F0F0F"
        d="M54.784 53.437s13.312-1.954 34.303-30.057l7.361 58.41s-22.622-14.343-38.044-8.638c0 0-8.353-8.939-3.62-19.715z"
      ></path>
      <path
        fill="#fff"
        d="M101.138 82.055c9.166-.93 15.203-15.421 13.485-32.369-1.719-16.947-10.542-29.933-19.709-29.003-9.166.93-15.204 15.422-13.485 32.369 1.718 16.947 10.542 29.933 19.709 29.003z"
      ></path>
      <path
        fill="#0F0F0F"
        d="M42.338 74.774s-1.573 7.655 6.067 8.91c7.64 1.256 16.491 20.693 16.491 20.693l9.438-5.76S58.111 76.763 62.996 72.04l-20.658 2.735z"
      ></path>
      <path
        fill="#fff"
        d="M42.338 74.774s-1.573 7.655 6.067 8.91c7.64 1.256 16.491 20.693 16.491 20.693l9.438-5.76S58.111 76.763 62.996 72.04l-20.658 2.735z"
        opacity="0.46"
      ></path>
      <path
        fill="#0F0F0F"
        d="M84.3 66.558l14.894-5.007-1.774-17.444-16.296 1.055s-1.31 9.623 3.175 21.396z"
      ></path>
      <path
        fill="#000"
        d="M84.3 66.558l14.894-5.007-1.774-17.444-16.296 1.055s-1.31 9.623 3.175 21.396z"
        opacity="0.18"
      ></path>
      <path
        fill="#0F0F0F"
        d="M99.194 61.556c2.606-.264 4.323-4.384 3.834-9.202-.488-4.818-2.997-8.51-5.603-8.245-2.607.264-4.323 4.384-3.835 9.202.489 4.818 2.998 8.51 5.604 8.245z"
      ></path>
      <path
        fill="#fff"
        d="M56.362 53.041S34.38 58.258 33.15 62.89c-1.23 4.631 2.628 15.144 8.09 14.953 5.46-.19 16.765-5.144 16.765-5.144s-5.628-10.756-1.642-19.657zM14.166 124.088l34.248-27.57 10.63 8.465-18.04 27.605-26.838-8.5zM64.613 80.949a6.61 6.61 0 016.688 4.123c1.953 4.694 1.88 7.757-3.269 9.452-5.148 1.695-6.39-12.07-6.39-12.07l2.971-1.505z"
      ></path>
      <path
        fill="#fff"
        d="M59.782 72.469l4.191 9.032L68.399 79a2.442 2.442 0 001.07-3.087l-1.69-4.07s-4.783-.771-7.997.626zM47.565 98.564s1.597-15.11 7.044-16.917c5.446-1.807 12.09 1.954 8.905 6-3.185 4.044-7.24-.377-7.24-.377l6.282 12.554-3.512 5.159-11.48-6.419z"
      ></path>
      <path
        fill="#fff"
        d="M62.879 74.984a2.018 2.018 0 012.389 0c.83.601 1.743 1.632 1.343 3.083-.689 2.496-5.051 5.798-6.839 4.455-1.505-1.133.679-5.75 3.107-7.538z"
      ></path>
      <path
        fill="#000"
        d="M10.36 123.125l31.84 10.479 15.804-23.116-16.712-11.807-30.931 24.444z"
      ></path>
      <path
        fill="#000"
        d="M52.2 106.385l-22.656 23.052 12.657 4.167 15.803-23.116-5.804-4.103z"
        opacity="0.06"
      ></path>
    </svg>
  );
}

export default MarketingIcon;
