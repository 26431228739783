import React from "react";
import "./styles.css";

function LabsBackgroundRune2() {
  return (
    <div className="labsSvgContainer2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 1074 1136"
        className="labsSvgRune2"
      >
        <g clipPath="url(#clip0_248_22)">
          <path
            fill="url(#paint0_linear_248_22)"
            d="M387.117 1098.66H1181.4V111.287c0-18.422-7.3-36.09-20.3-49.117a69.177 69.177 0 00-48.99-20.345c-18.37 0-36 7.318-48.99 20.345a69.564 69.564 0 00-20.29 49.117v789.87a64.168 64.168 0 01-18.72 45.203 63.793 63.793 0 01-45.084 18.76H387.031a66.513 66.513 0 00-47.092 19.556 66.855 66.855 0 00-19.507 47.214c0 17.71 7.017 34.69 19.507 47.21a66.48 66.48 0 0047.092 19.56h.086zm713.313-2.86H388.146a63.37 63.37 0 01-24.634-4.98 63.605 63.605 0 01-34.453-35.31 63.775 63.775 0 01-4.427-24.79c.6-34.75 27.571-60.158 62.371-60.158h592.109c36.768 0 66.598-32.712 66.598-69.577v-788.58a66.938 66.938 0 014.85-25.551 66.645 66.645 0 0114.23-21.746 66.449 66.449 0 0121.45-14.63 66.301 66.301 0 0150.88-.428 66.356 66.356 0 0121.69 14.268 66.634 66.634 0 0114.6 21.504 66.718 66.718 0 015.27 25.465V1017.4c0 20.8-8.24 40.76-22.92 55.47a78.114 78.114 0 01-55.33 22.98v-.05z"
          ></path>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_248_22"
            x1="1301.85"
            x2="618.7"
            y1="889.67"
            y2="496.264"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1B1464"></stop>
            <stop offset="0.5" stopColor="#009974"></stop>
            <stop offset="1" stopColor="#CFE5AE"></stop>
          </linearGradient>
          <clipPath id="clip0_248_22">
            <path
              fill="#fff"
              d="M0 0H1074V1136H0z"
              transform="matrix(-1 0 0 1 1074 0)"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default LabsBackgroundRune2;
