import React from "react";
import "./styles.css";

function LabsBackgroundRune5() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1376 767"
      className="labsSvgRune5"
    >
      <path
        fill="url(#paint0_linear_248_27)"
        d="M388.169 766.233h987.701V183.747H1085.2V.2H388.169a383.208 383.208 0 00-147.991 27.856 383.128 383.128 0 00-125.821 82.726 383.03 383.03 0 00-84.214 124.814 382.968 382.968 0 000 295.241 383.03 383.03 0 0084.214 124.814 383.126 383.126 0 00125.821 82.726 383.208 383.208 0 00147.991 27.856zM1373.77 474.99a289.108 289.108 0 01-84.63 204.377 289.246 289.246 0 01-204.37 84.76H388.169a368.61 368.61 0 01-146.155-29.898 376.497 376.497 0 01-120.093-81.64A381.493 381.493 0 0117.444 456.215a381.435 381.435 0 0122.818-221.252A375.77 375.77 0 01242.014 32.256a368.622 368.622 0 01146.155-29.95h694.971v181.441H693.536L439.029 438.196a80.631 80.631 0 00-17.529 87.905 80.65 80.65 0 0029.717 36.206 80.675 80.675 0 0044.831 13.589H1085.2V185.852h288.57V474.99zm-290.68 98.8H501.155a80.717 80.717 0 01-74.455-49.793 80.677 80.677 0 0117.383-87.854l250.295-250.238h388.712V573.79z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_248_27"
          x1="914.927"
          x2="545.372"
          y1="933.988"
          y2="-81.653"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B1464"></stop>
          <stop offset="0.5" stopColor="#009974"></stop>
          <stop offset="1" stopColor="#CFE5AE"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LabsBackgroundRune5;
