import React from "react";
import "./styles.css";
import PieChartIcon from "../svg/pieChartIcon";
import FundingIcon from "../svg/fundingIcon";
import ResearchIcon from "../svg/researchIcon";
import { useTranslation } from "react-i18next";
import "../../../utils/translator";
import LabsBackgroundRune8 from "../svg/labsBackgroundRune8";

function LabsResearch() {
  const { t } = useTranslation();

  return (
    <div className="labsResearchArea">
      <div className="svgContainer9">
        <LabsBackgroundRune8 />
      </div>

      <h2 className="labsScrollSectionsTitles">{t("labsResearchTitle")}</h2>
      <div className="researchBlocks">
        <div className="researchBlock">
          <PieChartIcon />
          <div className="researchDescription">
            <h3>{t("labsResearchInducationTitle")}</h3>
            <p>{t("labsResearchIncubationText")}</p>
          </div>
        </div>

        <div className="researchBlock">
          <FundingIcon />
          <div className="researchDescription">
            <h3>{t("labsResearchFundingTitle")}</h3>
            <p>{t("labsResearchFundingText")}</p>
          </div>
        </div>

        <div className="researchBlock">
          <ResearchIcon />
          <div className="researchDescription">
            <h3>{t("labsResearchZoomTitle")}</h3>
            <p>{t("labsResearchZoomText")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabsResearch;
