import * as React from "react";
import { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./styles.css";
import { useTranslation } from "react-i18next";
import "../../../../utils/translator";

export default function SelectAutoWidth() {
  const [language, setLanguage] = React.useState("");

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    document.title = t("agarthaSeoTitle");
  }, [t, language]);

  return (
    <div className="languageSelectorContainer">
      <FormControl variant="standard" className="languageSelector">
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={language}
          onChange={handleChange}
          defaultValue="ok"
          label="Language"
          displayEmpty
          className="emptyValueSelector"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
              getContentAnchorEl: null,
            },
          }}
        >
          <MenuItem value="" disabled className="menuItemSelection">
            Language
          </MenuItem>
          <MenuItem
            value={1}
            className="menuItemSelection"
            onClick={() => changeLanguage("fr")}
          >
            French
          </MenuItem>
          <MenuItem
            value={2}
            className="menuItemSelection"
            onClick={() => changeLanguage("en")}
          >
            English
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
