import Modal from "react-modal";
import React, { useState, useEffect } from "react";
import "../../../utils/translator/index.js";
import { useTranslation } from "react-i18next";

// Pour rendre le modal accessible aux lecteurs d'écran
Modal.setAppElement("#root");

function PopupPrivacy() {
  const { t } = useTranslation();
  // L'état qui contrôle si le modal est ouvert ou non
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // La fonction qui ouvre le modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // La fonction qui ferme le modal
  const closeModal = () => {
    setModalIsOpen(false);
    // Stocker la confirmation de l'utilisateur dans le localStorage
    localStorage.setItem("termsAccepted", "true");
  };

  // Le hook d'effet qui ouvre le modal au chargement de la page
    useEffect(() => {
    // Vérifier si l'utilisateur a déjà accepté les conditions générales
    const termsAccepted = localStorage.getItem("termsAccepted");
    if (!termsAccepted) {
      // Ouvrir le modal si ce n'est pas le cas
      openModal();
    }
  }, []);
    
  return (
    <div className="App">
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={{
                overlay: {
                  position: 'fixed',
                  zIndex: 1020,
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  background: 'rgba(255, 255, 255, 0.75)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                content: {
                  background: 'black',
                  width: '45rem',
                  maxWidth: 'calc(100vw - 2rem)',
                  maxHeight: 'calc(100vh - 2rem)',
                  overflowY: 'auto',
                  position: 'relative',
                  border: '1px solid #ccc',
                  borderRadius: '0.3rem',
                }}}>
        <h2 className="CGTitre">{t("GENERAL_CONDITIONS_TITLE")}</h2>
        <p className="CGText">
            {t("GENERAL_CONDITIONS_TEXT")}
            { /*<a href="10">https://www.example.com/terms</a>*/ }
        </p>
        <button className="CGButton" onClick={closeModal}>{t("GENERAL_CONDITIONS_ACCEPT_BUTTON")}</button>
      </Modal>
    </div>  )}

export default PopupPrivacy;