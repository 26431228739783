import React from "react";
import "./styles.css";

function LabsBackgroundRune12() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 338 430"
      className="labsSvgRune12"
    >
      <g clipPath="url(#clip0_662_54)">
        <path
          fill="url(#paint0_linear_662_54)"
          d="M23.116 482.795h370.622V22.39a32.42 32.42 0 00-9.469-22.903A32.3 32.3 0 00361.408-10a32.299 32.299 0 00-22.86 9.487 32.42 32.42 0 00-9.469 22.903v368.311a29.894 29.894 0 01-8.731 21.078 29.78 29.78 0 01-21.039 8.747H23.076a31.05 31.05 0 00-21.974 9.119A31.165 31.165 0 00-8 451.661a31.164 31.164 0 009.102 22.015 31.046 31.046 0 0021.974 9.119h.04zm332.84-1.335H23.596a29.616 29.616 0 01-21.19-8.927 29.702 29.702 0 01-6.38-9.856 29.742 29.742 0 01-2.066-11.564c.28-16.202 12.865-28.049 29.103-28.049h276.286c17.158 0 31.076-15.253 31.076-32.443V22.911a31.145 31.145 0 012.262-11.914 31.09 31.09 0 016.641-10.14 31.023 31.023 0 0110.008-6.822A30.974 30.974 0 01383.201.489a31.122 31.122 0 019.27 21.901v422.512a36.619 36.619 0 01-10.695 25.869 36.48 36.48 0 01-25.82 10.715v-.026z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_662_54"
          x1="449.944"
          x2="131.285"
          y1="385.345"
          y2="201.713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B1464"></stop>
          <stop offset="0.5" stopColor="#009974"></stop>
          <stop offset="1" stopColor="#CFE5AE"></stop>
        </linearGradient>
        <clipPath id="clip0_662_54">
          <path
            fill="#fff"
            d="M0 0H338V430H0z"
            transform="matrix(-1 0 0 1 338 0)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LabsBackgroundRune12;
