import React, { useState, useEffect } from "react";
import "./styles.css";
import WorldIcon from "../svg/worldIcon";
import SelectAutoWidth from "../labsHeader/languageSelector";
import { useTranslation } from "react-i18next";
import "../../../utils/translator";
import AgarthaLabsLogoImage from "../../images/agarthaLabsLogo.png";
import Button from "@mui/material/Button";
import ProjectAgartha from "../../images/projects/projectAgartha.gif";
import TwitterIcon from "../svg/twitterIcon";
import InstagramIcon from "../svg/instagramIcon";
// import DiscordIcon from "../svg/discordIcon";
import LinkedinIcon from "../svg/linkedinLogo";
import GenericFooter from "../../mainPage/footer/genericFooter";
import { Link as LinkBrowser } from "react-router-dom";
import LabsSvgRune12 from "../svg/labsBackgroundRune12";
import LabsSvgRune11 from "../svg/labsBackgroundRune11";
import LabsSvgRune9 from "../svg/labsBackgroundRune9";
import LabsSvgRune10 from "../svg/labsBackgroundRune10";
import LabsProjectAgarthaTab from "./labsProjectAgarthaTab";
// import MintModal from "./labsProjectModalMint";
// import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LabsProjectCurrentyChoice from "./labsProjectCurrentyChoice";
import LabsProjectPriceCalculator from "./labsProjectPriceCalculator";
import { Helmet } from "react-helmet";

function LabsProject(props) {
  const { t } = useTranslation();
  const [largeur, setLargeur] = useState(window.innerWidth);
  const [currency, setCurrency] = useState("ETH");

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
    };

    props.toggleMenu
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div className="labsProjectArea">
      <Helmet>
        <link rel="canonical" href="https://agartha.ch/labs/projectAgartha" />
      </Helmet>
      <div className="labsProjectHeader">
        <img
          src={AgarthaLabsLogoImage}
          alt="logo agartha labs"
          className="labsLogo"
        />
        <div className="labsProjectHeaderButtons">
          {/* <LinkBrowser to="/labs" className="labsProjectLinkApparence"><Button className="headerBar__button labsProjectReturnButton"><h4>{t("StepbystepReturnButton")}</h4></Button></LinkBrowser> */}
          <LinkBrowser to="/labs" className="labsEnterLinkApparence">
            <Button className="headerBar__button backButton">
              <ArrowBackIcon />
              <p className="returnBackText">{t("return")}</p>
            </Button>
          </LinkBrowser>
          <LinkBrowser
            to="/labs/upcoming"
            offset={0}
            duration={500}
            className="labsHeaderBar__link"
          >
            <Button className="headerBar__button">
              <h4>{t("UPCOMING")}</h4>
            </Button>
          </LinkBrowser>
          {/* <Link to="/"> */}
          <Button disabled className="headerBar__button disabledButton">
            <h4>{t("PAST")}</h4>
          </Button>
          {/* </Link> */}
          <div className="labsLanguageSelectorBlock">
            <WorldIcon />
            <SelectAutoWidth />
          </div>
        </div>
      </div>

      <div className="labsProjectMainContainer">
        <div className="labsProjectOrganizer">
          {largeur >= 750 && (
            <div className="svgContainerLabsProjectLeft">
              {largeur > 1025 && <LabsSvgRune10 />};
              {largeur <= 1025 && <LabsSvgRune11 />}
            </div>
          )}
          {largeur >= 750 && (
            <div className="svgContainerLabsProjectRight">
              {largeur > 1025 && <LabsSvgRune9 />};
              {largeur <= 1025 && <LabsSvgRune12 />}
            </div>
          )}

          {(largeur < 750 || largeur > 1400) && (
            <div className="labsProjectDescriptionLeftBlock">
              {largeur > 750 && (
                <div className="labsProjectDescriptionBlock">
                  <div className="labsProjectDescriptionAgarthaSummary">
                    <div className="labsProjectDescriptionAgarthaState">
                      <h2 className="labsProjectAgarthaLabsTitle monarchFont">
                        Agartha Labs
                      </h2>
                      <div className="roundGreenFlash"></div>
                    </div>
                    <div className="labsProjectDescriptionLinks">
                      <a
                        href="https://mobile.twitter.com/agartha_ch/"
                        target="blank"
                      >
                        <TwitterIcon />
                      </a>
                      <a
                        href="https://www.instagram.com/agartha.ch/"
                        target="blank"
                      >
                        <InstagramIcon />
                      </a>
                      {/* <a href="https://discord.gg/e5FwK4rZQu"><DiscordIcon /></a> */}
                      <a
                        href="https://www.linkedin.com/company/agartha-ch/"
                        target="blank"
                      >
                        <LinkedinIcon />
                      </a>
                    </div>
                  </div>

                  <div className="labsProjectDescription">
                    <h3 className="labsProjectDescriptionTitle">
                      Description :
                    </h3>
                    <div>
                      <p className="labsProjectDescriptionText">
                        <span className="monarchFont">Agartha Labs</span>
                        {t("labsProjectExempleDescription1")}
                      </p>
                    </div>
                    {/* <LinkBrowser to="/labs/enter" className="labsHomeButton labsProjectStepsAgartha">
                        <h4>VIEW STEPS</h4>
                    </LinkBrowser> */}
                  </div>

                  <div className="labsProjectOverviewButtons"></div>
                </div>
              )}

              {(largeur < 750 || largeur > 1400) && <LabsProjectAgarthaTab />}
            </div>
          )}

          <div className="labsProjectOverview">
            <div className="labsProjectOverviewPicture">
              <img
                src={ProjectAgartha}
                alt="Representation of the project"
                className="labsProjectOverviewImage"
              />
              <h2 className="monarchFont">AGARTHA LABS</h2>
            </div>

            <div className="labsProjectBuyContainer">
              <div className="labsProjectBuyInformations">
                <p>
                  {t("prix")}
                  <LabsProjectPriceCalculator
                    currency={currency}
                    setCurrency={setCurrency}
                  />
                  {/* {currency} */}
                </p>
                <p>{t("items")} : 4444</p>
                <span id="solana" style={{ display: "none" }}></span>
                <span id="solana2" style={{ display: "none" }}></span>
              </div>

              <Button
                variant="outlined"
                className="whiteButton labsProjectButton"
                // href="https://mint.agartha.ch/"
                target="blank"
              >
                {/* <a href="https://endearing-pavlova-c1b1a1.netlify.app/" target="blank"> */}
                {/* {t("labsProjectBuyButton")} */}
                COMING SOON
                {/* </a> */}
              </Button>
              <LabsProjectCurrentyChoice
                setCurrency={setCurrency}
                currency={currency}
              />
            </div>
          </div>
        </div>

        {largeur >= 750 && largeur <= 1400 && <LabsProjectAgarthaTab />}
      </div>

      <GenericFooter />
    </div>
  );
}

export default LabsProject;
