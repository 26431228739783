import React, { useState } from "react";
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "./styles.css";
// import CloseIcon from "@mui/icons-material/Close";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import Accordion from "@mui/material/Accordion";
// import AccordionDetails from "@mui/material/AccordionDetails";
import Course1 from "./course1.js";
import Course2 from "./course2.js";
import Course3 from "./course3.js";
import Course4 from "./course4.js";

export default function ModalCourseExemple(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const [selectChoice, setSelectChoice] = useState(props.courseChoice);

  return (
    <div>
      <Button
        variant="outlined"
        className="labsHomeButton color-11"
        onClick={handleOpen}
      >
        <h4>ENTER</h4>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {props.courseChoice === 1 ? (
          <Course1
            handleChange={handleChange}
            expanded={expanded}
            handleClose={handleClose}
          />
        ) : props.courseChoice === 2 ? (
          <Course2
            handleChange={handleChange}
            expanded={expanded}
            handleClose={handleClose}
          />
        ) : props.courseChoice === 3 ? (
          <Course3
            handleChange={handleChange}
            expanded={expanded}
            handleClose={handleClose}
          />
        ) : (
          <Course4
            handleChange={handleChange}
            expanded={expanded}
            handleClose={handleClose}
          />
        )}
      </Modal>
    </div>
  );
}
