import React from "react";
import "./styles.css";

function PieChartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 119 121"
      className="pieChartIcon"
    >
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="4.178"
        d="M57.34 57.34v54.341C18.166 111.681 3 83.879 3 58.604 3 25.747 25.747 3 57.34 3c31.594 0 53.078 20.22 53.078 54.34H57.341z"
      ></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="4.178"
        d="M64.923 64.923V118c30.33 0 51.813-20.22 51.813-53.077H64.923z"
      ></path>
    </svg>
  );
}

export default PieChartIcon;
