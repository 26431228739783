import React, { Fragment } from "react";
import "./styles.css";
import {
  TrustedByList,
  PartnersList,
  TalkAboutUsList,
  MetaverseStudioList,
} from "../../api/partnership";

function OurPartners() {
  const sortedParnersList = PartnersList.sort(function (a, b) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });

  const sortedTrustedByList = TrustedByList.sort(function (a, b) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });

  const sortedTalkAboutUsList = TalkAboutUsList.sort(function (a, b) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="ourPartnersArea">
      <h2 className="ourPartnersTitle">OUR PARTNERS</h2>

      <div className="ourPartnersContainer">
        {sortedParnersList.map((item) => (
          <div className="ourPartnersSectionContainer" key={item.id}>
            {item.img ? (
              <a
                href={item.website}
                target="blank"
                className={item.website ? "cliquablePartner" : ""}
              >
                <img
                  alt={item.title}
                  src={item.img}
                  className="labsTrustedByImg"
                />
              </a>
            ) : (
              <a
                href={item.website}
                target="blank"
                className={item.website ? "cliquablePartner" : ""}
              >
                {item.svg}
              </a>
            )}
          </div>
        ))}
      </div>

      <h2 className="ourPartnersTitle">TRUSTED BY</h2>

      <div className="ourPartnersContainer">
        {sortedTrustedByList.map((item) => (
          <div className="ourPartnersSectionContainer" key={item.id}>
            {item.img ? (
              <a
                href={item.website}
                target="blank"
                className={item.website ? "cliquablePartner" : ""}
              >
                <img
                  alt={item.title}
                  src={item.img}
                  className="labsTrustedByImg"
                />
              </a>
            ) : (
              <a
                href={item.website}
                target="blank"
                className={item.website ? "cliquablePartner" : ""}
              >
                {item.svg}
              </a>
            )}
          </div>
        ))}
      </div>

      <h2 className="ourPartnersTitle">THEY TALK ABOUT US</h2>

      <div className="ourPartnersContainer">
        {sortedTalkAboutUsList.map((item) => (
          <div className="ourPartnersSectionContainer" key={item.id}>
            {item.img ? (
              <a
                href={item.website}
                target="blank"
                className={item.website ? "cliquablePartner" : ""}
              >
                <img
                  alt={item.title}
                  src={item.img}
                  className="labsTrustedByImg"
                />
              </a>
            ) : (
              <a
                href={item.website}
                target="blank"
                className={item.website ? "cliquablePartner" : ""}
              >
                {item.svg}
              </a>
            )}
          </div>
        ))}
      </div>

      <h2 className="ourPartnersTitle">METAVERSE STUDIO</h2>

      <div className="ourPartnersContainer">
        {MetaverseStudioList.map((item) => (
          <div className="ourPartnersSectionContainer" key={item.id}>
            {item.img ? (
              <a
                href={item.website}
                target="blank"
                className={item.website ? "cliquablePartner" : ""}
              >
                <img
                  alt={item.title}
                  src={item.img}
                  className="labsTrustedByImg"
                />
              </a>
            ) : (
              <a
                href={item.website}
                target="blank"
                className={item.website ? "cliquablePartner" : ""}
              >
                {item.svg}
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default OurPartners;
