import React from "react";
import "./styles.css";

function BackgroundRune5() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1171 776"
      className="svgRune5"
    >
      <path
        fill="url(#paint0_linear_809_72)"
        d="M199.556 776h231.971V485.393l231.301 148.124 1.67 1.067V379.702h381.782c68.77 0 124.72-55.86 124.72-124.52V0H787.055C718.28 0 662.331 55.858 662.331 124.521v249.767h-127.08c-57.784 0-104.904 49.694-105.876 107.156l-105.383-67.487c-37.206-23.825-80.234-36.42-124.436-36.42C89.519 377.537 0 466.911 0 576.768 0 686.624 89.52 776 199.556 776m1.706-2.167c-108.511 0-199.041-88.631-199.095-196.966-.05-108.71 88.514-197.165 197.389-197.165 43.784 0 86.409 12.476 123.262 36.076l106.542 68.228v62.105c0 125.766-102.122 227.722-228.098 227.722m230.265-291.009c.234-56.907 46.674-103.122 103.724-103.122h127.08v250.923L431.527 482.824zm614.753-108.536H664.498V127.766c0-67.467 54.977-122.355 122.557-122.355h381.775v246.523c0 67.466-54.97 122.354-122.55 122.354z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_809_72"
          x1="769.773"
          x2="473.717"
          y1="591.535"
          y2="151.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C1D58"></stop>
          <stop offset="0.25" stopColor="#ED1C24"></stop>
          <stop offset="0.6" stopColor="#FBC707"></stop>
          <stop offset="0.8" stopColor="#99DDE3"></stop>
          <stop offset="1" stopColor="#00A9A0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BackgroundRune5;
