import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ces2023_1 from "../../../images/projects/agartha/ces2023_1.jpg";
import ces2023_2 from "../../../images/projects/agartha/ces2023_2.jpg";
import ces2023_3 from "../../../images/projects/agartha/ces2023_3.jpg";
import ces2023_4 from "../../../images/projects/agartha/ces2023_4.jpg";
import ces2023_5 from "../../../images/projects/agartha/ces2023_5.jpg";
import ces2023_6 from "../../../images/projects/agartha/ces2023_6.jpg";
import ces2023_7 from "../../../images/projects/agartha/ces2023_7.jpg";
import ces2023_8 from "../../../images/projects/agartha/ces2023_8.jpg";

export default function LabsCes2023ImagesList() {
  const [largeur, setLargeur] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <Box>
      {largeur >= 550 && (
        <ImageList variant="masonry" cols={3} gap={30}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}

      {largeur < 550 && largeur >= 370 && (
        <ImageList variant="masonry" cols={2} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}

      {largeur < 370 && (
        <ImageList variant="masonry" cols={1} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </Box>
  );
}

const itemData = [
  {
    img: ces2023_1,
    title: "ces2023_1",
  },
  {
    img: ces2023_2,
    title: "ces2023_2",
  },
  {
    img: ces2023_3,
    title: "ces2023_3",
  },
  {
    img: ces2023_4,
    title: "ces2023_4",
  },
  {
    img: ces2023_5,
    title: "ces2023_5",
  },
  {
    img: ces2023_6,
    title: "ces2023_6",
  },
  {
    img: ces2023_7,
    title: "ces2023_7",
  },
  {
    img: ces2023_8,
    title: "ces2023_8",
  },
];
