import React from "react";
import "./styles.css";

function BitcoinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 139 139"
      className="bitcoinIcon"
    >
      <circle cx="69.5" cy="69.5" r="69.5" fill="#D9D9D9"></circle>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M69 119c-27.063 0-49-21.937-49-49s21.937-49 49-49 49 21.937 49 49-21.937 49-49 49zm16.035-65.259c-2.38-6.04-8.336-6.584-15.275-5.237l-2.472-8.614-5.243 1.503 2.404 8.391c-1.378.392-2.778.824-4.171 1.256l-2.42-8.446-5.242 1.5 2.468 8.615c-1.13.35-2.236.69-3.326 1.002l-.006-.025-7.234 2.07 1.608 5.602s3.85-1.186 3.807-1.094c2.122-.612 3.17.426 3.675 1.43l2.817 9.816c.144-.04.337-.092.564-.123l-.554.16 3.941 13.75c.098.695.01 1.874-1.473 2.303.083.04-3.813 1.09-3.813 1.09l.753 6.563 6.827-1.96c1.268-.361 2.523-.698 3.754-1.04l2.5 8.712 5.236-1.5-2.468-8.622c1.404-.372 2.803-.76 4.199-1.163l2.459 8.584 5.243-1.504-2.49-8.697c8.67-3.035 14.204-7.026 12.596-15.527-1.292-6.842-5.283-8.918-10.633-8.686 2.597-2.419 3.718-5.693 1.97-10.109zm-1.993 20.733c1.868 6.514-9.494 8.97-13.047 9.993l-3.307-11.545c3.552-1.02 14.406-5.237 16.354 1.555v-.003zM75.93 58.886c1.697 5.926-7.8 7.901-10.758 8.75l-3.001-10.47c2.958-.85 11.986-4.457 13.76 1.72z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default BitcoinIcon;
