import React from "react";
import "./styles.css";
import BackRune2 from "../../svg/backgroundRune2";
import { useTranslation } from "react-i18next";

function TextAbout() {
  const { t } = useTranslation();

  return (
    <div className="textAboutArea" id="about">
      <div className="svgContainer2">
        <BackRune2 />
      </div>

      <div className="storytellingArea">
        <div className="textAbout">
          <p>{t("STORYTELLING_1")}</p>
          <p>
            {t("STORYTELLING_2-1")}
            <span className="monarchFont">Agartha</span>
            {t("STORYTELLING_2-2")}
            <span className="monarchFont">Agartha</span>
            {t("STORYTELLING_2-3")}
          </p>
          <p>
            <span className="monarchFont">Agartha</span>
            {t("STORYTELLING_3")}
          </p>
          <p>
            {t("STORYTELLING_4-1")}
            <span className="monarchFont">Agartha</span>
            {t("STORYTELLING_4-2")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TextAbout;
